import React from "react";
import { Input } from "@fundrecs/ui-library";

/**
 * This is a reusable FormInput component which takes 7 params.
 *
 * The fieldKey represents the key in our connection object.
 * The type represents what kind of input we want to use either text/number.
 * The handleChange function is passed down as prop and is already defined above.
 * The formField param is used when removing errors for a form field from the errors array.
 * The resetFormField is a method which is called to remove error warning from input.
 * The warningMessage is what we want to display to the user when an error occurs
 * The warning param is a true/false value to show error message & icon.
 *
 * @param fieldKey
 * @param handleChange
 * @param type
 * @param formField
 * @param warningMessage
 * @param warning
 * @param resetFormField
 * @returns
 */
const FormInput = ({ fieldKey, handleChange, type, warningMessage, warning, formField, resetFormField, value }) => (
  <Input
    onChange={(event) => {
      const {
        target: { value },
      } = event;

      handleChange(fieldKey, value);
      resetFormField && resetFormField(formField);
    }}
    placeholderText=""
    size="md"
    type={type}
    warningMessage={warningMessage}
    warning={warning}
    value={value}
  />
);

FormInput.defaultProps = {
  type: "text",
};

export { FormInput };
