import {
  R,
  C,
  Input,
  MemberDropdown,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  Member,
  SelectedIcon,
  IconDownArrow,
  IconInformation,
  Checkbox,
  Text,
  Dropdown,
  DropdownButton,
  DropdownButtonText,
  ItemBody,
  Button,
  ToolTip,
} from "@fundrecs/ui-library";
import React, { useState } from "react";
import { observer } from "mobx-react-lite";

import { useStore } from "../../../../store/Store";
import { ROLES, ROLE_DESCRIPTIONS } from "../../../../utils/Enums";
import { DefaultRoleForDropdown } from "../../components/DefaultRoleForDropdown";
import { AddedUser } from "../../components/AddedUser";
import { disableRole, checkIfRoleLookupApprover, generateDisplayText } from "../AddMembersAndRoles";

const AddTeamsAndRoles = observer(({ teamsAndRoles, setTeamsAndRoles, numberAdded }) => {
  const { teamsStore, rolesStore } = useStore();
  const selectedRole = rolesStore.getRoles().length > 0 ? [rolesStore.findRoleByName(ROLES.VIEWER).id] : [];

  //selectedTeamId & selectedRoleIds store the selected but not-yet-added team and roles
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const [selectedRoleIds, setSelectedRoleIds] = useState(selectedRole);

  //memberDropdownOpen Boolean to display member dropdown
  const [memberDropdownOpen, setMemberDropdownOpen] = useState(false);

  //teamDropdownValue The text to be displayed in the team dropdown
  const [teamDropdownValue, setTeamDropdownValue] = useState("");

  //selectedTeamIndex Index of the selected team from the dropdown, used to indicate which team is selected
  const [selectedTeamIndex, setSelectedTeamIndex] = useState(null);

  //Text to be displayed in the role dropdown
  const [displayText, setDisplayText] = useState(generateDisplayText([], rolesStore));

  let dropdownTeams = [];
  for (const team of teamsStore.getTeams()) {
    if (team.name.toLowerCase().includes(teamDropdownValue.toLowerCase())) {
      dropdownTeams.push(team);
    }
  }

  /**
   * Called when a role checkbox is clicked, if selected, add to selectedRoleIds, otherwise remove
   * @param {Number} roleId
   */
  const toggleRole = (event, roleId) => {
    event.stopPropagation();

    let newRoleIds = selectedRoleIds;
    if (newRoleIds.includes(roleId)) {
      newRoleIds.splice(newRoleIds.indexOf(roleId), 1);
      //Check if role is Lookup Approver and add addional role
    } else {
      newRoleIds.push(roleId);
      //Check if role is Lookup Approver and add addional role
      checkIfRoleLookupApprover(roleId, rolesStore, selectedRoleIds);
    }
    setDisplayText(generateDisplayText(selectedRoleIds, rolesStore));
    setSelectedRoleIds(newRoleIds);
  };

  /**
   * Called when a team is selected from the team dropdown
   * @param {Number} teamId
   * @param {String} teamName
   */
  const changeTeam = (teamId, teamName, teamIndex) => {
    const defaultViewerRole = rolesStore.findRoleByName(ROLES.VIEWER).id;
    setSelectedTeamIndex(teamIndex);
    setTeamDropdownValue(teamName);
    setSelectedRoleIds([defaultViewerRole]);
    setSelectedTeamId(teamId);
    setDisplayText(generateDisplayText(teamId ? [defaultViewerRole] : [], rolesStore));
  };

  const removeTeam = (teamId) => {
    const { [teamId]: _, ...rest } = teamsAndRoles;
    setTeamsAndRoles(rest);
  };

  /**
   * Called when a team is added to the team
   */
  const addTeamAndRoles = () => {
    teamsAndRoles[selectedTeamId] = selectedRoleIds;
    setTeamsAndRoles({ ...teamsAndRoles });
    changeTeam(null, "", null);
  };

  return (
    <>
      <R props="d-flex pl-0 pr-0 mb-24">
        <Text variant="secondary pl-0 pr-0" weight="regular" size="sm">
          Every member will have “viewer” access which enables them to see all content belonging to their team in Fusion. You can assign additional roles to
          members for specific actions such as approving workflows/templates and creating workflows/templates:
        </Text>
      </R>
      <R>
        <C props="d-flex pl-0 pr-0">
          <Input
            placeholderText="Type to add a team"
            value={teamDropdownValue}
            spacers={["mr-12"]}
            onChange={(event) => {
              setTeamDropdownValue(event.target.value);
              setMemberDropdownOpen(true);
            }}
          />

          <MemberDropdown active={memberDropdownOpen && dropdownTeams.length}>
            {dropdownTeams.map((team, index) => {
              return (
                <DropdownListItem
                  key={team.id}
                  onClick={(event) => {
                    const teamIndex = selectedTeamIndex === index ? null : index;
                    setMemberDropdownOpen(false);
                    changeTeam(team.id, team.name, teamIndex);
                  }}
                >
                  <DropdownItem active={selectedTeamIndex} index={index}>
                    <Member
                      image={team.avatar}
                      primary={team.name}
                      secondary={`${teamsStore.getTeamMemberCount(team.id)} members`}
                      active={selectedTeamIndex}
                      index={index}
                    />
                    <SelectedIcon active={selectedTeamIndex} index={index} size={"md"} />
                  </DropdownItem>
                </DropdownListItem>
              );
            })}
          </MemberDropdown>

          <Dropdown spacers={["mr-12"]}>
            <DropdownButton warning={false} disabled={false} size={"md"}>
              <DropdownButtonText>
                <ItemBody>{displayText}</ItemBody>
                <IconDownArrow className={`btn-${"md"}-svg`} />
              </DropdownButtonText>
            </DropdownButton>

            <DropdownList>
              <DefaultRoleForDropdown />

              {rolesStore.getAssignableNonAdminRoles().map((role, index) => {
                return (
                  <DropdownListItem key={Math.random()} onClick={() => {}}>
                    <DropdownItem active={false} index={index}>
                      <Checkbox
                        spacers={["mr-12", "mt-0"]}
                        onClick={(event) => {
                          toggleRole(event, role.id);
                        }}
                        checked={selectedRoleIds.includes(role.id)}
                        disabled={disableRole(role.name, role.id, rolesStore, selectedRoleIds)}
                      />
                      <ItemBody>{role.name}</ItemBody>
                      <ToolTip text={ROLE_DESCRIPTIONS[role.name]} direction="top">
                        <IconInformation className="light-text-muted icon-size" />
                      </ToolTip>
                    </DropdownItem>
                  </DropdownListItem>
                );
              })}
            </DropdownList>
          </Dropdown>

          <Button
            size="md"
            color="primary"
            disabled={!selectedTeamId || !selectedRoleIds.length}
            onClick={() => {
              addTeamAndRoles();
            }}
          >
            <Text size="sm" weight="medium">
              Add
            </Text>
          </Button>
        </C>
      </R>

      {Object.keys(teamsAndRoles).length > 0 ? (
        <>
          <R props="pt-32">
            <C props="pl-0 pb-16">
              <Text variant="secondary" size="sm" weight="bold" element="div">
                {numberAdded.toString() + " teams added"}
              </Text>
            </C>
            {Object.keys(teamsAndRoles).map((teamId, index) => {
              const team = teamsStore.getTeamById(teamId);
              if (!team) {
                return "";
              }
              //Fix for possible backend bug, where avatar key is not included with the teams objects
              //Destructure and set a default string if avatar is undefined
              const { avatar = "" } = team;
              const rolesLength = Object.keys(teamsAndRoles).length;

              return (
                <>
                  <AddedUser
                    avatar={avatar}
                    id={teamId}
                    name={team.name}
                    email={`${teamsStore.getTeamMemberCount(teamId)} members`}
                    roleIds={teamsAndRoles[teamId]}
                    removeUser={removeTeam}
                  />
                  {index !== rolesLength - 1 ? <div className="addedUser"></div> : ""}
                </>
              );
            })}
          </R>
        </>
      ) : (
        ""
      )}
    </>
  );
});

export { AddTeamsAndRoles };
