import React from "react";
import { useHistory } from "react-router-dom";
import { EmptyUsers } from "@fundrecs/ui-library";
import { EmptyStateLayout } from "../components/Layout";
import { ROUTES } from "../../../utils/Enums";
import { PageTitleArea } from "../components/PageTitleArea";

/**
 * Default page when no users have yet been added
 */
const NoUsers = () => {
  const history = useHistory();

  return (
    <EmptyStateLayout
      pageTitleArea={<PageTitleArea title="Manage users" description="Manage your organisation's users" />}
      icon={<EmptyUsers className="emptyState-icon" />}
      title="No users added yet"
      description="Fortunately it’s super easy to add users and assign teams and roles if needed to manage access in Fusion"
      buttonText="Add a user"
      onClick={() => history.push(ROUTES.USERS_NEW)}
    />
  );
};

NoUsers.propTypes = {};

NoUsers.defaultProps = {};

export { NoUsers };
