import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";

import "@fundrecs/ui-library/dist/scss/styles.scss";
import "./scss/app.scss";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
