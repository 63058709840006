/**
 * This method creates a toast message for whichever form field is currently
 * being edited on the UI. It uses the key param in the switch statement to create
 * specific objects.
 *
 * @param key
 * The key param represents the form field currently being edited
 *
 * @returns toast message object
 */
const createToasts = (key) => {
  const toastObject = {};

  switch (key) {
    case "name":
      toastObject.success = "Name successfully changed";
      toastObject.error = "Unable to change name! Please try again";
      break;
    case "active":
      toastObject.success = "Status successfully changed";
      toastObject.error = "Unable to change status! Please try again";
      break;
    case "sourceFolder":
      toastObject.success = "Source folder successfully changed";
      toastObject.error = "Unable to change source folder! Please try again";
      break;
    case "endpoint":
      toastObject.success = "Endpoint successfully changed";
      toastObject.error = "Unable to change endpoint! Please try again";
      break;
    case "port":
      toastObject.success = "Port successfully changed";
      toastObject.error = "Unable to change port! Please try again";
      break;
    case "authentication":
      toastObject.success = "Authentication successfully changed";
      toastObject.error = "Unable to change authentication! Please try again";
      break;
    case "emailAddress":
      toastObject.success = "Email address successfully changed";
      toastObject.error = "Unable to change email address! Please try again";
      break;
    case "fileSource":
      toastObject.success = "File source successfully changed";
      toastObject.error = "Unable to change file source! Please try again";
      break;
    case "connection":
      toastObject.success = "SFTP connection successfully changed";
      toastObject.error = "Unable to change source SFTP connection! Please try again";
      break;
    default:
      toastObject.success = "";
      toastObject.error = "";
  }

  return toastObject;
};

export { createToasts };
