import React, { useContext } from "react";
import { R, C, C4, Text, C6, SectionDivider, LinkText, CreatedBy } from "@fundrecs/ui-library";
import { ImportSetupContext } from "../ImportSetup";
import { importSftpConnectionsStore } from "../../../../../store/Store";
import { EditableText } from "../../../components/editable/EditableText";
import { EditableSwitch } from "../../../components/editable/EditableSwitch";
import { ManageLayoutSection } from "../../../components/Layout";
import { EditableNumber } from "../../../components/editable/EditableNumber";
import { EditableAuthentication } from "../../AuthenticationInput/EditableAuthentication";
import { ConnectionStatus } from "../../../components/ConnectionStatus";
import { createToasts } from "../../../util/index";
import { VALIDATION_MESSAGES } from "../../../util/validation";

const SftpConnection = ({ setSftpTab }) => {
  const { selectedSftpConnection, setSelectedSftpConnection } = useContext(ImportSetupContext);

  const handleChange = (object, key, value) => {
    const updatedObject = { ...object, ...{ [key]: value } };
    return updatedObject;
  };

  const submitImportConnection = async (key, value) => {
    const sftpConnection = handleChange(selectedSftpConnection, key, value);

    const toasts = createToasts(key);

    const { success, data } = await importSftpConnectionsStore.update(sftpConnection, toasts);

    if (success) {
      importSftpConnectionsStore.replaceSftpConnection(data);
      setSelectedSftpConnection(data);
    }
  };

  return (
    <>
      <ManageLayoutSection title="Overview">
        <Overview submitImportConnection={submitImportConnection} setSftpTab={setSftpTab} />
      </ManageLayoutSection>
      <ManageLayoutSection title="Configuration">
        <Connection submitImportConnection={submitImportConnection} />
      </ManageLayoutSection>
    </>
  );
};

const Overview = ({ submitImportConnection, setSftpTab }) => {
  const { selectedSftpConnection } = useContext(ImportSetupContext);
  const { name, createdBy, createdOn, active, inputMappings = [] } = selectedSftpConnection || {};

  return (
    <>
      <R props={"pb-12"}>
        <EditableText
          name="connectionName"
          label="Connection name"
          value={name}
          onSave={(value) => {
            submitImportConnection("name", value);
          }}
          warningText={VALIDATION_MESSAGES.REQUIRED_FIELD}
        />
      </R>
      <SectionDivider />
      <R props={"pt-12 pb-12"}>
        <EditableSwitch
          name="status"
          label="Status"
          value={active}
          options={{
            on: <ConnectionStatus text="active" status={true} weight="medium" variant="primary" size="sm" />,
            off: <ConnectionStatus text="inactive" status={false} weight="medium" variant="primary" size="sm" />,
          }}
          onSave={(value) => {
            submitImportConnection("active", value);
          }}
          canEdit={active}
        />
      </R>
      <SectionDivider />
      <R props={"pt-12 pb-12"}>
        <C4>
          <Text variant="tertiary" size="sm" weight="regular" element="div">
            Directories
          </Text>
        </C4>
        <C6>
          <Text variant="primary" size="sm" weight="medium" element="div">
            {inputMappings ? inputMappings.length : 0} directories assigned
          </Text>
        </C6>
        <C>
          <span
            className="d-flex justify-content-end"
            onClick={() => {
              setSftpTab(1);
            }}
          >
            <LinkText>view</LinkText>
          </span>
        </C>
      </R>
      <SectionDivider />
      <R props={"pt-12"}>
        <C4>
          <Text size="sm" variant="tertiary" weight="regular">
            Created by
          </Text>
        </C4>
        <C props="pl-0">
          <CreatedBy user={createdBy} date={createdOn} />
        </C>
      </R>
    </>
  );
};

const Connection = ({ submitImportConnection }) => {
  const { selectedSftpConnection } = useContext(ImportSetupContext);
  const { sourceFolder, endpoint, port, privateKeyFile, userName, password } = selectedSftpConnection || {};

  return (
    <>
      <R props={"pb-12"}>
        <EditableText
          name="sourceFolder"
          label="Source Folder"
          value={sourceFolder}
          onSave={(value) => {
            submitImportConnection("sourceFolder", value);
          }}
          warningText={VALIDATION_MESSAGES.REQUIRED_FIELD}
        />
      </R>
      <SectionDivider />
      <R props={"pt-12 pb-12"}>
        <EditableText
          name="endpoint"
          label="Endpoint"
          value={endpoint}
          onSave={(value) => {
            submitImportConnection("endpoint", value);
          }}
          warningText={VALIDATION_MESSAGES.REQUIRED_FIELD}
        />
      </R>
      <SectionDivider />
      <R props={"pt-12 pb-12"}>
        <EditableNumber
          name="port"
          label="Port"
          value={port}
          onSave={(value) => {
            submitImportConnection("port", value);
          }}
          warningText={VALIDATION_MESSAGES.REQUIRED_FIELD}
        />
      </R>
      <SectionDivider />
      <R props={"pt-12 "}>
        <EditableAuthentication
          existingAuthentication={{ userName: userName, privateKeyFile: privateKeyFile, password: password }}
          onSave={(authenticationValues) => {
            submitImportConnection("authentication", authenticationValues);
          }}
        />
      </R>
    </>
  );
};

export { SftpConnection };
