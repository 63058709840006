"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stringifyDateTimeISO8601 = exports.stringifyDate = exports.previousDate = exports.nextDate = exports.getFirstDayOfMonth = exports.getDayString = exports.getDayAndTimeString = void 0;

/* eslint-disable no-unsafe-negation */
const stringifyDate = function (dateObject) {
  let separator = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  if (!(dateObject instanceof Date)) {
    return "";
  }

  const date = prefixZero(dateObject.getDate());
  const month = prefixZero(dateObject.getMonth() + 1);

  if (separator === null) {
    separator = "-";
  }

  return "".concat(dateObject.getFullYear()).concat(separator).concat(month).concat(separator).concat(date);
};

exports.stringifyDate = stringifyDate;

const stringifyDateTimeISO8601 = dateObject => {
  if (!(dateObject instanceof Date)) {
    return "";
  }

  const date = prefixZero(dateObject.getDate());
  const month = prefixZero(dateObject.getMonth() + 1);
  const hours = prefixZero(dateObject.getHours());
  const minutes = prefixZero(dateObject.getMinutes());
  const seconds = prefixZero(dateObject.getSeconds());
  const miliSeconds = prefixZeroMiliseconds(dateObject.getMilliseconds());
  const separator = "-";
  return "".concat(dateObject.getFullYear()).concat(separator).concat(month).concat(separator).concat(date, "T").concat(hours, ":").concat(minutes, ":").concat(seconds, ".").concat(miliSeconds, "Z");
};

exports.stringifyDateTimeISO8601 = stringifyDateTimeISO8601;

const getDayString = dateObject => {
  if (!dateObject instanceof Date) {
    return "";
  }

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric"
  };
  return dateObject.toLocaleDateString("en-GB", options);
};

exports.getDayString = getDayString;

const prefixZero = number => {
  if (number < 10) {
    number = "0".concat(number);
  }

  return "".concat(number);
};

const prefixZeroMiliseconds = number => {
  let numberString = "".concat(number);

  if (number < 100) {
    numberString = "0".concat(number);
  }

  if (number < 10) {
    numberString = "0".concat(numberString);
  }

  return "".concat(numberString);
};

const nextDate = date => {
  if (!date instanceof Date) {
    return date;
  }

  let next = new Date(date);
  next.setDate(date.getDate() + 1);
  return next;
};

exports.nextDate = nextDate;

const previousDate = date => {
  if (!date instanceof Date) {
    return date;
  }

  let previous = new Date(date);
  previous.setDate(date.getDate() - 1);
  return previous;
};

exports.previousDate = previousDate;

const getFirstDayOfMonth = date => {
  const dateObj = new Date(date);
  return new Date(dateObj.getFullYear(), dateObj.getMonth(), 1);
};

exports.getFirstDayOfMonth = getFirstDayOfMonth;

const getDayAndTimeString = dateObj => {
  const date = getDayString(dateObj);
  return "".concat(date, " ").concat(dateObj.getHours(), ":").concat(dateObj.getMinutes(), ":").concat(dateObj.getSeconds());
};

exports.getDayAndTimeString = getDayAndTimeString;