import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Button, Text, modalInstance, Modal, ModalBody, ModalSection, Error, ModalHeader, ModalText, ModalFooter } from "@fundrecs/ui-library";
import { MODALS, CONNECTIONS, ROUTES } from "../../../../utils/Enums";

const PreventDeleteSftpConnectionModal = ({ connectionType }) => {
  const history = useHistory();

  const viewSftpMappings = async () => {
    modalInstance(MODALS.PREVENT_DELETE_SFTP_CONNECTION).toggle();

    if (connectionType === CONNECTIONS.IMPORT_SFTP_CONNECTION) {
      history.replace(`${ROUTES.IMPORT_SFTP_MAPPINGS}`);
    } else {
      history.replace(`${ROUTES.EXPORT_SFTP_DIRECTORY}`);
    }
  };

  return (
    <Modal modalId={MODALS.PREVENT_DELETE_SFTP_CONNECTION}>
      <ModalBody>
        <ModalSection>
          <Error />
        </ModalSection>
        <ModalSection>
          <ModalHeader heading="This connection cannot be deleted as it has directories assigned to it" />
          <ModalText text="Assigning the directories to another SFTP connection will enable you to delete this connection." />
        </ModalSection>
      </ModalBody>
      <ModalFooter>
        <Button
          size="md"
          color="tertiary"
          disabled={false}
          onClick={() => {
            viewSftpMappings();
          }}
        >
          <Text size="sm" weight="medium">
            View directories
          </Text>
        </Button>
        <Button
          size="md"
          color="primary"
          disabled={false}
          onClick={() => {
            modalInstance(MODALS.PREVENT_DELETE_SFTP_CONNECTION).toggle();
          }}
        >
          <Text size="sm" weight="medium">
            Ok
          </Text>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

// Specifies the types for props:
PreventDeleteSftpConnectionModal.propTypes = {
  connectionType: PropTypes.string,
};

// Specifies the default values for props:
PreventDeleteSftpConnectionModal.defaultProps = {
  connectionType: "",
};

export { PreventDeleteSftpConnectionModal };
