import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { teamsStore, usersStore } from "../../../store/Store";
import { R, C, C4, Text, SectionDivider, CreatedBy } from "@fundrecs/ui-library";
import { TEAM_SETTINGS_TYPES } from "../../../utils/Enums";
import { EditableText } from "../components/editable/EditableText";
import { EditableSwitch } from "../components/editable/EditableSwitch";
import { TeamsContext } from "../manageTeams/ManageTeams";
import { ManageLayoutSection } from "../components/Layout";
import { VALIDATION_MESSAGES } from "../util/validation";

/**
 * This method creates a toast message for whichever form field is currently
 * being edited on the UI. It uses the key param in the switch statement to create
 * specific objects.
 *
 * @param key
 * The key param represents the form field currently being edited
 *
 * @param newWorkflow4Eyes
 * The newWorkflow4Eyes is the boolean value for workflow4Eyes
 *
 * @param newTemplate4Eyes
 * The newTemplate4Eyes is the boolean value for template4Eyes
 *
 *
 * @returns toast message object
 */
const createToasts = (key, newWorkflow4Eyes, newTemplate4Eyes) => {
  const toastObject = {};

  switch (key) {
    case "name":
      toastObject.success = "Team name successfully changed";
      toastObject.error = "Unable to change team name! Please try again";
      break;
    case "workflow4Eyes":
      toastObject.success = `4-eyes approval for workflows successfully ${newWorkflow4Eyes === true ? " enabled" : " disabled"}`;
      toastObject.error = `Unable to ${newWorkflow4Eyes === true ? " enable" : " disable"} 4-eyes approval for workflows! Please try again`;
      break;
    case "template4Eyes":
      toastObject.success = `4-eyes approval for templates successfully ${newTemplate4Eyes === true ? " enabled" : " disabled"}`;
      toastObject.error = `Unable to ${newTemplate4Eyes === true ? " enable" : " disable"} 4-eyes approval for templates! Please try again`;
      break;
    default:
      toastObject.success = "";
      toastObject.error = "";
  }

  return toastObject;
};

const TeamOverview = observer(() => {
  const { selectedTeam, setSelectedTeam } = useContext(TeamsContext);
  const { name, createdBy, createdOn } = selectedTeam;

  const workflow4Eyes = teamsStore.getWorkflow4EyesSetting(selectedTeam).value;
  const template4Eyes = teamsStore.getTemplate4EyesSetting(selectedTeam).value;

  const isTeamNameAvailable = async (value) => {
    const { data } = await teamsStore.verifyNameIsAvailable(value);
    return data;
  };

  const submitTeam = async (props, key) => {
    const { name: newName = name, workflow4Eyes: newWorkflow4Eyes = workflow4Eyes, template4Eyes: newTemplate4Eyes = template4Eyes } = { ...props };

    let requestObject = JSON.parse(JSON.stringify(selectedTeam));

    requestObject.name = newName;
    let setting = requestObject.settings.find((setting) => setting.settingFor === TEAM_SETTINGS_TYPES.WORKFLOW_4_EYES);
    setting.value = newWorkflow4Eyes;
    setting = requestObject.settings.find((setting) => setting.settingFor === TEAM_SETTINGS_TYPES.TEMPLATE_4_EYES);
    setting.value = newTemplate4Eyes;

    const toasts = createToasts(key, newWorkflow4Eyes, newTemplate4Eyes);

    const { data, success } = await teamsStore.update(requestObject, toasts);
    if (success) {
      setSelectedTeam(data);
      teamsStore.replaceTeam(data);
    }
  };

  return (
    <>
      <ManageLayoutSection title="Details">
        <R props={"pb-12"}>
          <EditableText
            name="teamName"
            label="Team Name"
            value={name}
            onSave={(value) => {
              submitTeam({ name: value }, "name");
            }}
            customValidation={async (value) => {
              return (await isTeamNameAvailable(value)) ? { showWarning: false } : { showWarning: true, message: VALIDATION_MESSAGES.TEAM_NAME_ALREDY_IN_USE };
            }}
            warningText={VALIDATION_MESSAGES.REQUIRED_FIELD}
          />
        </R>
        <SectionDivider />
        <R props={"pt-12"}>
          <C4>
            <Text size="sm" variant="tertiary" weight="regular">
              Created by
            </Text>
          </C4>
          <C props="pl-0">
            <CreatedBy user={usersStore.getUserById(createdBy)} date={createdOn} />
          </C>
        </R>
      </ManageLayoutSection>

      <ManageLayoutSection title="Permissions">
        <R props={"pb-12"}>
          <EditableSwitch
            name="workflow4Eyes"
            label="Four-eyes approval for workflows"
            value={workflow4Eyes}
            description="If the team requires 4-eyes approval, members can submit completed workflows for approval by a second individual that is independent to ensure they are reviewed and double checked."
            options={{ on: "Enabled", off: "Not enabled" }}
            onSave={(value) => {
              submitTeam({ workflow4Eyes: value }, "workflow4Eyes");
            }}
            canEdit={true}
          />
        </R>
        <SectionDivider />
        <R props={"pt-12"}>
          <EditableSwitch
            name="template4Eyes"
            label="Four-eyes approval for templates"
            value={template4Eyes}
            description="If the team requires four-eyes approval, members can submit completed templates for approval by a second individual, with approver permissions, that is independent to ensure they are reviewed and double checked."
            options={{ on: "Enabled", off: "Not enabled" }}
            onSave={(value) => {
              submitTeam({ template4Eyes: value }, "template4Eyes");
            }}
            canEdit={true}
          />
        </R>
      </ManageLayoutSection>
    </>
  );
});

export { TeamOverview };
