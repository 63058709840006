import { CONNECTIONS } from "../../../../utils/Enums";

/**
 * This method returns the content for create SFTP connection, Email Connection or Input Mapping form.
 * @param connetionType
 * @returns content object for the form
 */
const getCreateFormContent = (connectionType) => {
  switch (connectionType) {
    case CONNECTIONS.IMPORT_SFTP_CONNECTION:
      return CREATE_SFTP_CONNECTION_IMPORT;
    case CONNECTIONS.IMPORT_SFTP_MAPPING:
      return CREATE_SFTP_MAPPING_IMPORT;
    case CONNECTIONS.IMPORT_EMAIL_MAPPING:
      return CREATE_EMAIL_MAPPING_IMPORT;
    case CONNECTIONS.EXPORT_SFTP_CONNECTION:
      return CREATE_SFTP_CONNECTION_EXPORT;
    case CONNECTIONS.EXPORT_SFTP_DIRECTORY:
      return CREATE_SFTP_DIRECTORY_EXPORT;
    case CONNECTIONS.EXPORT_EMAIL_CONNECTION:
      return CREATE_EMAIL_CONNECTION_EXPORT;
    default:
      return BLANK_FORM;
  }
};

const CREATE_SFTP_CONNECTION_IMPORT = Object.freeze({
  HEADER_LABEL: "Add a new SFTP connection",
  HEADER_DESCRIPTION: "This will be used as a location for importing files to Fusion.",
  NAME_LABEL: "Connection name",
  NAME_DESCRIPTION: "Make sure to use a name that's easy for you to identify",
  SOURCE_FOLDER_LABEL: "Source folder",
  SOURCE_FOLDER_DESCRIPTION: "This is where all input files will come in to",
  ENDPOINT_LABEL: "Endpoint",
  ENDPOINT_DESCRIPTION: "Provide the URL where the SFTP service is running",
  PORT_LABEL: "Port",
  PORT_DESCRIPTION: "The port on which the service is running. SFTP ports are typically 22",
  AUTHENTICATION_LABEL: "Authentication",
  AUTHENTICATION_DESCRIPTION:
    "The SFTP server authenticates the connection using either key or password authentication. Choose the authentication you want to use for this connection.",
});

const CREATE_SFTP_CONNECTION_EXPORT = Object.freeze({
  HEADER_LABEL: "Add a new SFTP connection",
  HEADER_DESCRIPTION: "Connect an SFTP data destination to Fusion to enable export of output data.",
  NAME_LABEL: "Connection name",
  NAME_DESCRIPTION: "Make sure to use a name that's easy for you to identify",
  SOURCE_FOLDER_LABEL: "Source folder",
  SOURCE_FOLDER_DESCRIPTION: "This is where all output files will be stored",
  ENDPOINT_LABEL: "Endpoint",
  ENDPOINT_DESCRIPTION: "Provide the URL where the SFTP service is running",
  PORT_LABEL: "Port",
  PORT_DESCRIPTION: "The port on which the service is running. SFTP ports are typically 22",
  AUTHENTICATION_LABEL: "Authentication method",
  AUTHENTICATION_DESCRIPTION:
    "The SFTP server authenticates the connection using either key or password authentication. Choose the authentication you want to use for this connection.",
});

const CREATE_SFTP_MAPPING_IMPORT = Object.freeze({
  HEADER_LABEL: "Add new SFTP directory",
  HEADER_DESCRIPTION: "An SFTP directory is a directory in your connected SFTP data source that input data will be imported from for transformation.",
  NAME_LABEL: "Directory name",
  NAME_DESCRIPTION: "Make sure to use a name that's easy for you to identify",
  FILESOURCE_LABEL: "Directory",
  FILESOURCE_LABEL_DESCRIPTION: "Enter the file path within this SFTP folder that you want to use",
  CONNECTION_LABEL: "SFTP connection",
  CONNECTION_DESCRIPTION: "Select an existing SFTP connection that this SFTP folder belongs to",
  SUBMIT_BUTTON_LABEL: "Add directory",
});

const CREATE_SFTP_DIRECTORY_EXPORT = Object.freeze({
  HEADER_LABEL: "Add new SFTP directory",
  HEADER_DESCRIPTION:
    "An SFTP directory is a folder path in your connected SFTP connection that acts as a destination to send generated output data to from Fusion.",
  NAME_LABEL: "Directory name",
  NAME_DESCRIPTION: "Make sure to use a name that's easy for you to identify",
  FILESOURCE_LABEL: "Directory path",
  FILESOURCE_LABEL_DESCRIPTION: "Enter the file path within this SFTP folder that you want to use",
  CONNECTION_LABEL: "SFTP connection",
  CONNECTION_DESCRIPTION: "Select an existing SFTP connection that this SFTP folder belongs to",
  SUBMIT_BUTTON_LABEL: "Add directory",
});

const CREATE_EMAIL_MAPPING_IMPORT = Object.freeze({
  HEADER_LABEL: "Add new email address",
  HEADER_DESCRIPTION: "Connect an email data source to Fusion to enable import of input data for transformation.",
  NAME_LABEL: "Email name",
  NAME_DESCRIPTION: "Make sure to use a name that's easy for you to identify",
  EMAIL_LABEL: "Email address",
  EMAIL_DESCRIPTION: "This is the email address that all output files will be sent to",
});

const CREATE_EMAIL_CONNECTION_EXPORT = Object.freeze({
  HEADER_LABEL: "Add new email address",
  HEADER_DESCRIPTION: "Connect an email address to act as a destination to send generated output data to from Fusion.",
  NAME_LABEL: "Email name",
  NAME_DESCRIPTION: "Make sure to use a name that's easy for you to identify",
  EMAIL_LABEL: "Email address",
  EMAIL_DESCRIPTION: "This is the email address that all output files will be sent to",
});

const BLANK_FORM = Object.freeze({
  HEADER_LABEL: "",
  HEADER_DESCRIPTION: "",
  NAME_LABEL: "",
  NAME_DESCRIPTION: "",
  EMAIL_LABEL: "",
  EMAIL_DESCRIPTION: "",
  FILESOURCE_LABEL: "",
  FILESOURCE_LABEL_DESCRIPTION: "",
  CONNECTION_LABEL: "",
  CONNECTION_DESCRIPTION: "",
  SUBMIT_BUTTON_LABEL: "",
  SOURCE_FOLDER_LABEL: "",
  SOURCE_FOLDER_DESCRIPTION: "",
  ENDPOINT_LABEL: "",
  ENDPOINT_DESCRIPTION: "",
  PORT_LABEL: "",
  PORT_DESCRIPTION: "",
  AUTHENTICATION_LABEL: "",
  AUTHENTICATION_DESCRIPTION: "",
});

export { getCreateFormContent };
