import React, { useContext } from "react";
import { R, C, C4, C6, Text, CreatedBy, SectionDivider } from "@fundrecs/ui-library";
import { ImportSetupContext } from "../ImportSetup";
import { importEmailConnectionsStore } from "../../../../../store/Store";
import { EditableText } from "../../../components/editable/EditableText";
import { ManageLayoutSection } from "../../../components/Layout";
import { createToasts } from "../../../util/index";
import { VALIDATION_MESSAGES } from "../../../util/validation";

const EmailMapping = () => {
  const { selectedEmailConnection, setSelectedEmailConnection } = useContext(ImportSetupContext);
  const { name, emailAddress, createdBy, createdOn } = selectedEmailConnection || {};

  const handleChange = (object, key, value) => {
    const updatedObject = { ...object, ...{ [key]: value } };
    return updatedObject;
  };

  const submitExportConnection = async (key, value) => {
    const emailConnection = handleChange(selectedEmailConnection, key, value);

    // Remove 'emailAddress' as it cannot be changed and APi does not expect it
    delete emailConnection.emailAddress;

    const toasts = createToasts(key);

    const { success, data } = await importEmailConnectionsStore.update(emailConnection, toasts);

    if (success) {
      importEmailConnectionsStore.replaceEmailConnection(data);
      setSelectedEmailConnection(data);
    }
  };

  return (
    <ManageLayoutSection title="Overview">
      <R props={"pb-12"}>
        <EditableText
          name="name"
          label="Mapping name"
          value={name}
          onSave={(value) => {
            submitExportConnection("name", value);
          }}
          warningText={VALIDATION_MESSAGES.REQUIRED_FIELD}
        />
      </R>
      <SectionDivider />
      <R props={"pt-12 pb-12"}>
        <C4>
          <Text variant="tertiary" size="sm" weight="regular" element="div">
            Email Address
          </Text>
        </C4>
        <C6>
          <Text variant="primary" size="sm" weight="medium" element="div">
            {emailAddress}
          </Text>
        </C6>
      </R>
      <SectionDivider />
      <R props={"pt-12"}>
        <C4>
          <Text size="sm" variant="tertiary" weight="regular">
            Created by
          </Text>
        </C4>
        <C props="pl-0">
          <CreatedBy user={createdBy} date={createdOn} />
        </C>
      </R>
    </ManageLayoutSection>
  );
};

export { EmailMapping };
