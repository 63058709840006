import { EmptyImportMappings, modalInstance } from "@fundrecs/ui-library";
import { EmptyStateLayout } from "../../../components/Layout";
import { MODALS } from "../../../../../utils/Enums";

/**
 * Default page when no import mappings have yet been added
 */
const NoSftpMappings = () => {
  return (
    <EmptyStateLayout
      icon={<EmptyImportMappings className="emptyState-icon" />}
      title="No SFTP directories added yet"
      description="You can add and use SFTP directories as destinations for your teams workflow custom output files in Fusion"
      buttonText="Add an SFTP directory"
      onClick={() => {
        modalInstance(MODALS.NEW_INPUT_MAPPING).toggle();
      }}
    />
  );
};

NoSftpMappings.propTypes = {};
NoSftpMappings.defaultProps = {};

export { NoSftpMappings };
