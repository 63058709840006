import React, { useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { R, C, ListItems, List, Text } from "@fundrecs/ui-library";
import { exportMappingsStore } from "../../../../../store/Store";
import { ExportSetupContext } from "../ExportSetup";
import { ROUTES } from "../../../../../utils/Enums";
import { ListItemsHeader, ListItemsItem } from "../../../components/Layout";
import { SftpDirectoryHeader } from "./SftpDirectoryHeader";
import { SftpDirectory } from "./SftpDirectory";
import { NoSftpMappings } from "./NoSftpMappings";

const SftpDirectoriesList = () => {
  const { id } = useParams();
  const history = useHistory();
  const { selectedSftpDirectory, setSelectedSftpDirectory, setSelectedTabIndex } = useContext(ExportSetupContext);

  useEffect(() => {
    setSelectedTabIndex(1);

    
    const fetchData = async () => {
      return await exportMappingsStore.requestExportMappings();
    };

    fetchData().then((sftpDirectories) => {
      exportMappingsStore.setExportMappings(sftpDirectories);

      if (!selectedSftpDirectory) {
        selectDefaultItem(id);
      }
    });
  }, []);

  useEffect(() => {
    selectDefaultItem(id);
  }, [id]);

  const selectDefaultItem = (id) => {
    const itemById = exportMappingsStore.getExportMappingById(id);
    if (id && itemById) {
      setSelectedSftpDirectory(itemById);
    } else {
      const newestItem = exportMappingsStore.getNewestSftpMapping();
      if (newestItem) {
        history.replace(`${ROUTES.EXPORT_SFTP_DIRECTORY}/${newestItem.id}`);
      } else {
        setSelectedSftpDirectory();
      }
    }
  };

  const handleSelectConnection = (connection) => {
    setSelectedSftpDirectory(connection);
    history.push(`${ROUTES.EXPORT_SFTP_DIRECTORY}/${connection.id}`);
  };

  return (
    <>
      {selectedSftpDirectory ? (
        <R>
          <List>
            <ListItemsHeader text="All directories (newest-oldest)" />
            <R>
              <ListItems>
                {exportMappingsStore
                  .getExportMappings()
                  .slice()
                  .sort((a, b) => a.createdOn.localeCompare(b.createdOn))
                  .reverse()
                  .map(function (row, index) {
                    return (
                      <ListItemsItem
                        name={row.name}
                        description={
                          <Text element="div" size="xs" variant="tertiary">
                            {row.fileSource}
                          </Text>
                        }
                        isActive={row.id.toString() === id ? 0 : 1}
                        onClick={() => handleSelectConnection(row)}
                        key={index}
                      />
                    );
                  })}
              </ListItems>
            </R>
          </List>
          <C props="section-border-left pr-0 pl-0">
            <R props="pl-32 pt-24">{<SftpDirectoryHeader />}</R>
            <R props="pl-32 section-border-bottom">
              <C props="pl-0 pr-0">
                <C props="pb-24" />
              </C>
            </R>
            <div className="light-grey-bg section-border-left " style={{ height: "100%" }}>
              <R props={"pl-32 pr-32 pb-32"}>
                <SftpDirectory />
              </R>
            </div>
          </C>
        </R>
      ) : (
        <NoSftpMappings />
      )}
    </>
  );
};
export { SftpDirectoriesList };
