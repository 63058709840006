import React, { useState } from "react";
import { C, C6, C4, SaveEditable } from "@fundrecs/ui-library";
import { Text, UserAvatar } from "@fundrecs/ui-library";

const EditableAvatar = ({ name, value, label, onSave, warningText }) => {
  const [editing, setEditing] = useState(false);

  return (
    <>
      <C4>
        <Text variant="tertiary" size="sm" weight="regular" element="div">
          {label}
        </Text>
      </C4>

      {editing ? (
        <>
          <C6>{/* Insert upload file component */}</C6>
          <C props="pt-4 pb-4">
            <SaveEditable onClickSave={() => {}} onClickCancel={() => {}} />
          </C>
        </>
      ) : (
        <>
          <C6>
            <UserAvatar size="md" />
          </C6>
          {/*
            Reintroduce the 'change' button once the avatar can be edited
            <C>
              <span
                className="d-flex justify-content-end"
                onClick={() => {
                  setEditing(true);
                }}
              >
                <LinkText>change</LinkText>
              </span>
            </C>
            */}
        </>
      )}
    </>
  );
};

export { EditableAvatar };
