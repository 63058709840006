import { makeAutoObservable } from "mobx";
import { fusionApi } from "../api";
import { uiStore } from "./Store";

const { importConnections } = fusionApi;

class ImportSftpConnectionsStore {
  sftpConnections = [];

  constructor() {
    makeAutoObservable(this);
  }

  setSftpConnections(value) {
    this.sftpConnections = value;
  }

  getSftpConnections() {
    return this.sftpConnections;
  }

  getSftpConnectionsCount() {
    return this.sftpConnections.length;
  }

  getNewestSftpConnection = () => {
    return this.getSftpConnections()
      .slice()
      .sort((a, b) => a.createdOn.localeCompare(b.createdOn))
      .slice(-1)[0];
  };

  getSftpConnectionById(connectionId) {
    return this.getSftpConnections().find((connection) => connection?.id == connectionId);
  }

  requestSftpConnections = async () => {
    const response = await importConnections.api.listSftp();
    const { data, status } = response;
    if (status === 200) {
      return data;
    } else {
      if (status !== 401 && status !== 403) {
        uiStore.addNotification("error", "Error loading Connections.");
      }
      return [];
    }
  };

  replaceSftpConnection = (importConnection) => {
    let sftpConnections = this.getSftpConnections();
    const index = sftpConnections.findIndex((index) => index.id === importConnection.id);
    sftpConnections[index] = importConnection;
    this.setSftpConnections(sftpConnections);
  };

  /**
   * This method request all connections and replace the existing list of connections.
   */
  reloadStore = async () => {
    const sftpConnections = await this.requestSftpConnections();
    this.setSftpConnections(sftpConnections);
  };

  /**
   * This method adds a newly created connection object to the existing list of connections.
   * It has one param which is a connection object.
   * @param importConnection
   */
  addNewSftpConnection = (importConnection) => {
    let sftpConnections = this.getSftpConnections();
    sftpConnections.push(importConnection);
  };

  /**
   * This method is used to remove an sftp connection that has a connectionUuid which
   * matches the connectionUuid passed as a param. We filter the current array and returning
   * connection objects which don't match.
   *
   * These are then set as the connections.
   *
   * @param connectionUuid
   */
  removeSftpConnection = (connectionUuid) => {
    const filteredConnections = this.sftpConnections.filter((connection) => connection.connectionUuid !== connectionUuid);
    this.setSftpConnections(filteredConnections);
  };

  /**
   * This method sends a request to update a connections details
   *
   * If the request is successful a toast message will appear and a true/false will
   * be returned.
   *
   * We return a true/false so we can continue operations in the app or stall them.
   *
   * Request object contains updated connection details
   * @param  request
   *
   * toasts param are the for the success/error messages to show for the form field being updated.
   * You can only edit/updated one form field at a time so we need to track this and display relevant
   * toast messages.
   * @param  toasts
   *
   * @returns true/false if request succeeded/failed
   */
  update = async (request, toasts) => {
    const { success, error } = toasts;
    return importConnections.api
      .update(request)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          uiStore.addNotification("success", `${success}`);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `${error}`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        uiStore.addNotification("error", `${error}`);
      });
  };

  /**
   * This method sends a request to the create a new connection
   *
   * If the request is successful a toast message will appear and a true/false will
   * be returned.
   *
   * We return a true/false so we can continue operations in the app or stall them.
   *
   * The request param contains a new connection object
   * @param  request
   *
   * @returns true/false if request succeeded/failed
   */
  create = async (request) => {
    const { name } = request;
    request.type = "ImportSftpConnection";

    return importConnections.api
      .create(request)
      .then((response) => {
        const { status } = response;

        if (status === 200) {
          uiStore.addNotification("success", `"${name} connection name " successfully added`);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to add "${name} connection name!" Please try again `);
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        uiStore.addNotification("error", `Unable to add "${name} connection name!" Please try again `);
      });
  };

  /**
   * This method send a request to the delete endpoint for Import Connections
   * to delete a connection that has the uuid param supplied.
   *
   * If the request is successful a toast message will appear and a true/false will
   * be returned.
   *
   * We return a true/false so we can continue operations in the app or stall them.
   *
   * The param connectionUuid is the unique identifier for the connection.
   * @param  connectionUuid
   *
   * The param name is the name of the connection which is needed for toast messages
   * @param name
   *
   * @returns true/false if request succeeded/failed
   */
  delete = async (request) => {
    const { connectionUuid, name } = request;
    return importConnections.api
      .delete(connectionUuid)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          uiStore.addNotification("success", `"${name} connection name " successfully deleted`);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to delete "${name} connection name!" Please try again `);
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        uiStore.addNotification("error", `Unable to delete "${name} connection name!" Please try again `);
      });
  };

  getPublicKeys = async () => {
    let returnValue = null;
    const response = await importConnections.api.getPublicKeys();
    const { status, errors } = response;
    if (status === 200) {
      if (errors) {
        uiStore.addNotification("error", "Unable to get public keys. Please try again");
      } else {
        returnValue = response.data;
      }
    } else {
      if (status !== 401 && status !== 403) {
        uiStore.addNotification("error", "Unable to get public keys. Please try again");
      }
    }
    return returnValue;
  };

  getPublicKey = async (key) => {
    let returnValue = null;
    const response = await importConnections.api.getPublicKey(key);
    const { status, errors } = response;
    if (status === 200) {
      if (errors) {
        uiStore.addNotification("error", `Unable to download key. Please try again`);
        console.log(`ImportConnectionStore - Unable to download key, status: ${status}`, errors);
      } else {
        returnValue = response.data;
      }
    } else {
      uiStore.addNotification("error", `Unable to download key. Please try again`);
      console.log(`ImportConnectionStore - Unable to download key, status: ${status}`, response.data);
    }
    return returnValue;
  };

  generateKey = async () => {
    let returnValue = null;
    const response = await importConnections.api.generateKey();
    const { status, errors } = response;
    if (status === 200) {
      if (errors) {
        uiStore.addNotification("error", `Unable to generate keys. Please try again`);
        console.log(`ImportConnectionStore - Unable to generate public keys, status: ${status}`, errors);
      } else {
        uiStore.addNotification("success", `New key generated`);
        returnValue = response.data;
      }
    } else {
      uiStore.addNotification("error", `Unable to generate keys: ${response.data}`);
      console.log(`ImportConnectionStore - Unable to generate keys, status: ${status}`, response.data);
    }
    return returnValue;
  };
}

export { ImportSftpConnectionsStore };
