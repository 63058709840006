import React, { useContext } from "react";
import { usersStore } from "../../../store/Store";
import { UsersContext } from "../manageUsers/ManageUsers";
import { VerticalMenuDropdown, VerticalMenuListItem, modalInstance, WarningConfirmationModal } from "@fundrecs/ui-library";
import styles from "./VerticalMenu.module.scss";

const VerticalMenu = () => {
  const { selectedUser } = useContext(UsersContext);
  const { email } = selectedUser;

  const unblockUser = async () => {
    await usersStore.unblockUser(selectedUser);
  };

  const resetMFAForUser = async () => {
    await usersStore.resetMFAForUser(selectedUser);
  };

  return (
    <>
      <div className={styles.circle}>
        <VerticalMenuDropdown>
          <VerticalMenuListItem
            text="Unblock user"
            onClick={() => {
              modalInstance("unblockUserModal").show();
            }}
          />
          <VerticalMenuListItem
            text="Reset MFA"
            onClick={() => {
              modalInstance("resetMFAModal").show();
            }}
          />
        </VerticalMenuDropdown>
      </div>
      <WarningConfirmationModal
        modalId="unblockUserModal"
        heading={`Are you sure you want to unblock user '${email}'?`}
        text="Users are typically blocked due to the user providing an excessive number of incorrect credentials."
        cancel="Cancel"
        confirm="Unblock user"
        onConfirm={unblockUser}
      />
      <WarningConfirmationModal
        modalId="resetMFAModal"
        heading={`Are you sure you want to reset MFA for user '${email}'?`}
        text="The multi-factor authentication (MFA) settings associated with the user will be removed, which allows them to set up MFA as if they were a new user on their next login attempt."
        cancel="Cancel"
        confirm="Reset MFA"
        onConfirm={resetMFAForUser}
      />
    </>
  );
};

export { VerticalMenu };
