"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.spacing = exports.spaces = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Spacing Classes either margin/padding. Please look at spacing story for examples
 */
const spaces = ["pl-0", "pr-0", "pt-0", "pb-0", "ml-0", "mr-0", "mt-0", "mb-0", "pl-4", "pr-4", "pt-4", "pb-4", "ml-4", "mr-4", "mt-4", "mb-4", "pl-8", "pr-8", "pt-8", "pb-8", "ml-8", "mr-8", "mt-8", "mb-8", "pl-12", "pr-12", "pt-12", "pb-12", "ml-12", "mr-12", "mt-12", "mb-12", "pl-16", "pr-16", "pt-16", "pb-16", "ml-16", "mr-16", "mt-16", "mb-16", "pl-20", "pr-20", "pt-20", "pb-20", "ml-20", "mr-20", "mt-20", "mb-20", "pl-24", "pr-24", "pt-24", "pb-24", "ml-24", "mr-24", "mt-24", "mb-24", "pl-32", "pr-32", "pt-32", "pb-32", "ml-32", "mr-32", "mt-32", "mb-32", "pl-48", "pr-48", "pt-48", "pb-48", "ml-48", "mr-48", "mt-48", "mb-48", "pl-72", "pr-72", "pt-72", "pb-72", "ml-72", "mr-72", "mt-72", "mb-72", ""];
exports.spaces = spaces;
const spacing = {
  /**
   * Margin & Padding spacing classes
   */
  spacers: _propTypes.default.arrayOf(_propTypes.default.oneOf(spaces).isRequired).isRequired
};
exports.spacing = spacing;