import React, { useContext } from "react";
import { Heading, Button, IconDelete, R, C, modalInstance } from "@fundrecs/ui-library";
import { ExportSetupContext } from "../ExportSetup";
import { useStore } from "../../../../../store/Store";
import { AuthWrapper } from "../../../../AuthorizationWrapper";
import { AUTHORITIES, MODALS, CONNECTIONS } from "../../../../../utils/Enums";
import { DeleteEmailModal } from "../../Modals/DeleteEmailModal";

const EmailConnectionHeader = () => {
  const { rolesStore } = useStore();
  const { selectedEmailConnection } = useContext(ExportSetupContext);
  const { name } = selectedEmailConnection || {};

  return (
    <>
      <C props="pl-0 pr-0">
        <R>
          <C props={"pl-0"}>
            <Heading variant="h5">{name}</Heading>
          </C>
        </R>
      </C>
      <C props={"d-flex justify-content-end pr-32 pl-0"}>
        <AuthWrapper allRequired={rolesStore.getActions([AUTHORITIES.CONNECTION_DELETE])}>
          <DeleteEmailModal connectionType={CONNECTIONS.EXPORT_EMAIL_CONNECTION} item={selectedEmailConnection} />
          <Button
            size="md"
            color="tertiary"
            disabled={false}
            onClick={() => {
              modalInstance(MODALS.DELETE_EMAIL).toggle();
            }}
          >
            <IconDelete className={`btn-md-svg`} />
          </Button>
        </AuthWrapper>
      </C>
    </>
  );
};

export { EmailConnectionHeader };
