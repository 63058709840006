import { EmptyImportMappings, modalInstance } from "@fundrecs/ui-library";
import { EmptyStateLayout } from "../../../components/Layout";
import { MODALS } from "../../../../../utils/Enums";
/**
 * Default page when no import mappings have yet been assgined to a connection
 */
const NoSftpMappingsForConnection = () => {
  return (
    <EmptyStateLayout
      icon={<EmptyImportMappings />}
      title="No directories assigned yet"
      description="You can assign directories to this SFTP location so they can be used as locations for your teams workflows to import their input files from in Fusion."
      buttonText="Add a directory"
      customButtonIcon=""
      onClick={() => {
        modalInstance(MODALS.NEW_INPUT_MAPPING).toggle();
      }}
    />
  );
};

NoSftpMappingsForConnection.propTypes = {};
NoSftpMappingsForConnection.defaultProps = {};

export { NoSftpMappingsForConnection };
