import React, { useContext } from "react";
import { Heading, Button, IconDelete, R, C, modalInstance, Text, IconAdd } from "@fundrecs/ui-library";
import { ImportSetupContext } from "../ImportSetup";
import { useStore } from "../../../../../store/Store";
import { AuthWrapper } from "../../../../AuthorizationWrapper";
import { AUTHORITIES, MODALS, CONNECTIONS } from "../../../../../utils/Enums";
import { DeleteSftpConnectionModal } from "../../Modals/DeleteSftpConnectionModal";
import { PreventDeleteSftpConnectionModal } from "../../Modals/PreventDeleteSftpConnectionModal";

const SftpConnectionHeader = () => {
  const { rolesStore } = useStore();
  const { selectedSftpConnection } = useContext(ImportSetupContext);
  const { name, inputMappings = [] } = selectedSftpConnection || {};

  return (
    <>
      <C props="pl-0 pr-0">
        <R>
          <C props={"pl-0"}>
            <Heading variant="h5">{name}</Heading>
          </C>
        </R>
      </C>
      <C props={"d-flex justify-content-end pr-32 pl-0"}>
        <Button
          size="md"
          onClick={() => {
            modalInstance(MODALS.NEW_INPUT_MAPPING).toggle();
          }}
          color="primary-secondary"
        >
          <IconAdd className="btn-md-svg" />
          <Text size="sm" weight="medium">
            New SFTP mapping
          </Text>
        </Button>
        <span className="pl-12" />
        <AuthWrapper allRequired={rolesStore.getActions([AUTHORITIES.CONNECTION_DELETE])}>
          <DeleteSftpConnectionModal connectionType={CONNECTIONS.IMPORT_SFTP_CONNECTION} item={selectedSftpConnection} />
          <PreventDeleteSftpConnectionModal connectionType={CONNECTIONS.IMPORT_SFTP_CONNECTION} />
          <Button
            size="md"
            color="tertiary"
            disabled={false}
            onClick={() => {
              if (inputMappings.length === 0) {
                modalInstance(MODALS.DELETE_SFTP_CONNECTION).toggle();
              } else {
                modalInstance(MODALS.PREVENT_DELETE_SFTP_CONNECTION).toggle();
              }
            }}
          >
            <IconDelete className={`btn-md-svg`} />
          </Button>
        </AuthWrapper>
      </C>
    </>
  );
};

export { SftpConnectionHeader };
