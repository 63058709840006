import { EmptySftpConnections, modalInstance } from "@fundrecs/ui-library";
import { EmptyStateLayout } from "../../../components/Layout";
import { MODALS } from "../../../../../utils/Enums";

/**
 * Default page when no sftp connections have yet been added
 */
const NoSftpConnections = () => {
  return (
    <EmptyStateLayout
      icon={<EmptySftpConnections className="emptyState-icon" />}
      title="No SFTP connections added yet"
      description="You can add and use SFTP connections as destinations for your teams workflow custom output files in Fusion"
      buttonText="Add an SFTP connection"
      onClick={() => {
        modalInstance(MODALS.NEW_SFTP_CONNECTION).toggle();
      }}
    />
  );
};

NoSftpConnections.propTypes = {};
NoSftpConnections.defaultProps = {};

export { NoSftpConnections };
