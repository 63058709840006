import React, { useContext } from "react";
import { R, C, C4, Text, SectionDivider, CreatedBy } from "@fundrecs/ui-library";
import { ExportSetupContext } from "../ExportSetup";
import { exportSftpConnectionsStore, exportMappingsStore } from "../../../../../store/Store";
import { EditableText } from "../../../components/editable/EditableText";
import { EditableSelect } from "../../../components/editable/EditableSelect";
import { ManageLayoutSection } from "../../../components/Layout";
import { createToasts } from "../../../util/index";
import { VALIDATION_MESSAGES } from "../../../util/validation";

const SftpDirectory = () => {
  const { selectedSftpDirectory } = useContext(ExportSetupContext);
  const { name, fileSource, createdBy, createdOn, connection } = selectedSftpDirectory || {};
  const { name: sftpConnectionName, connectionUuid } = connection || {};

  /**
   * This method is called before submit a request to the API to update an object.
   * The object param represents the object to be updated before sending the request.
   * The key param represents the key the backend expects in the object.
   * The value is whatever the user types within the input.
   *
   * We take a copy of the current object and then combine it with
   * the newly updated values.
   *
   * We then return this object.
   * @param object
   * @param key
   * @param value
   */
  const handleChange = (object, key, value) => {
    const updatedObject = { ...object, ...{ [key]: value } };
    return updatedObject;
  };

  const submitSftpDirectory = async (key, value) => {
    const inputMapping = handleChange(selectedSftpDirectory, key, value);

    const toasts = createToasts(key);

    const { success, data } = await exportMappingsStore.update(inputMapping, toasts);

    if (success) {
      exportMappingsStore.replaceExportMapping(data);

      // Fetch Sftp Connections again if assigned Sftp connection changed
      if (key === "connection" || key === "name") {
        exportSftpConnectionsStore.reloadStore();
      }
    }
  };
  //

  return (
    <ManageLayoutSection title="Overview">
      <R props={"pb-12"}>
        <EditableText
          name="mappingName"
          label="Directory name"
          value={name}
          onSave={(value) => {
            submitSftpDirectory("name", value);
          }}
          warningText={VALIDATION_MESSAGES.REQUIRED_FIELD}
        />
      </R>
      <SectionDivider />

      <R props={"pt-12 pb-12"}>
        <EditableText
          name="fileSource"
          label="Directory path"
          value={fileSource}
          onSave={(value) => {
            submitSftpDirectory("fileSource", value);
          }}
          warningText={VALIDATION_MESSAGES.REQUIRED_FIELD}
        />
      </R>
      <SectionDivider />

      <R props={"pt-12 pb-12"}>
        <EditableSelect
          name="sftpConnection"
          label="SFTP connection"
          value={{ label: sftpConnectionName, value: connectionUuid }}
          options={exportSftpConnectionsStore.getSftpConnections().map(({ name, connectionUuid }) => ({ label: name, value: connectionUuid }))}
          onSave={(value) => {
            submitSftpDirectory("connection", { connectionUuid: value });
          }}
        />
      </R>
      <SectionDivider />
      <R props={"pt-12"}>
        <C4>
          <Text size="sm" variant="tertiary" weight="regular">
            Created by
          </Text>
        </C4>
        <C props="pl-0">
          <CreatedBy user={createdBy} date={createdOn} />
        </C>
      </R>
    </ManageLayoutSection>
  );
};

export { SftpDirectory };
