import React, { useState, createContext, useEffect } from "react";
import { useHistory, Redirect, Switch, Route, useLocation } from "react-router-dom";
import { ApmRoute } from "@elastic/apm-rum-react";
import { observer } from "mobx-react-lite";
import { C, modalInstance, CO, R } from "@fundrecs/ui-library";
import { ROUTES, MODALS } from "../../../../utils/Enums";
import { TabsLayout } from "../../components/Layout";
import { PageTitleArea } from "../../components/PageTitleArea";
import { AddNewButton } from "./AddNewButton/AddNewButton";
import { SftpConnectionsList } from "./SftpConnection/SftpConnectionsList";
import { SftpMappingsList } from "./SftpMapping/SftpMappingsList";
import { EmailMappingsList } from "./EmailMapping/EmailMappingsList";

const ImportSetupContext = createContext();

const ImportSetup = observer(() => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedEmailConnection, setSelectedEmailConnection] = useState(false);
  const [selectedSftpConnection, setSelectedSftpConnection] = useState(false);
  const [selectedSftpMapping, setSelectedSftpMapping] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    switch (pathname) {
      case ROUTES.IMPORT_SFTP_NEW:
        history.push(ROUTES.IMPORT);
        setTimeout(() => {
          modalInstance(MODALS.NEW_SFTP_CONNECTION).toggle();
        }, 2000);
        break;
      case ROUTES.IMPORT_SFTP_MAPPINGS_NEW:
        history.push(ROUTES.IMPORT);
        setTimeout(() => {
          modalInstance(MODALS.NEW_INPUT_MAPPING).toggle();
        }, 2000);
        break;
      default:
    }
  });

  const importConnectionsContext = {
    selectedSftpConnection,
    setSelectedSftpConnection,
    selectedSftpMapping,
    setSelectedSftpMapping,
    selectedEmailConnection,
    setSelectedEmailConnection,
    setSelectedTabIndex,
  };

  return (
    <ImportSetupContext.Provider value={importConnectionsContext}>
      <PageTitleArea
        title="File import setup"
        description="Map locations for importing data to Fusion"
        borderBottom={false}
        props={<AddNewButton selectedTabIndex={selectedTabIndex} />}
        classes="mb-20"
      />
      <CO props="pr-0 pl-0 fill">
        <R props="pl-32 section-border-bottom">
          <C props="pl-0 pr-0">
            <TabsLayout
              tabs={[
                {
                  text: "SFTP Connections",
                  onClick: () => {
                    history.push(ROUTES.IMPORT_SFTP);
                    setSelectedTabIndex(0);
                  },
                },
                {
                  text: "SFTP Directories",
                  onClick: () => {
                    history.push(ROUTES.IMPORT_SFTP_MAPPINGS);
                    setSelectedTabIndex(1);
                  },
                },
                {
                  text: "Email Addresses",
                  onClick: () => {
                    history.push(ROUTES.IMPORT_EMAIL_MAPPINGS);
                    setSelectedTabIndex(2);
                  },
                },
              ]}
              activeTab={selectedTabIndex}
            />
          </C>
        </R>

        <Switch>
          <ApmRoute path={`${ROUTES.IMPORT_SFTP}/:id?`}>
            <SftpConnectionsList />
          </ApmRoute>
          <ApmRoute path={`${ROUTES.IMPORT_SFTP_MAPPINGS}/:id?`}>
            <SftpMappingsList />
          </ApmRoute>
          <ApmRoute path={`${ROUTES.IMPORT_EMAIL_MAPPINGS}/:id?`}>
            <EmailMappingsList />
          </ApmRoute>

          <ApmRoute path={ROUTES.IMPORT}>
            <Redirect to={ROUTES.IMPORT_SFTP} />
          </ApmRoute>
        </Switch>
      </CO>
    </ImportSetupContext.Provider>
  );
});

export { ImportSetup, ImportSetupContext };
