
const HTTP_RESPONSE_GET_STRING = (response, component, defaultString) => {
  if (response.status == 200) {
    defaultString = response.data;
  } else {
    console.log(`${component}: Error getting String data from ${response.config.url}: `, response);
  }
  return defaultString;
};

export { HTTP_RESPONSE_GET_STRING };
