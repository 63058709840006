"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "TeamAvatar", {
  enumerable: true,
  get: function () {
    return _index.TeamAvatar;
  }
});
Object.defineProperty(exports, "TeamItem", {
  enumerable: true,
  get: function () {
    return _index.TeamItem;
  }
});
Object.defineProperty(exports, "UserAvatar", {
  enumerable: true,
  get: function () {
    return _index2.UserAvatar;
  }
});
Object.defineProperty(exports, "UserItem", {
  enumerable: true,
  get: function () {
    return _index2.UserItem;
  }
});

var _index = require("./team/index");

var _index2 = require("./user/index");