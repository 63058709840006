import React, { useState, createContext, useContext, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { IconDelete, R, C, C10, Button, Text, IconAdd, ListItem, UserAvatar, UserItem, modalInstance, WarningConfirmationModal } from "@fundrecs/ui-library";
import { teamsStore, rolesStore, usersStore, meStore } from "../../../store/Store";
import { ROUTES } from "../../../utils/Enums";
import { ManageLayout, ManageLayoutSection } from "../components/Layout";
import { NoUsers } from "../manageUsers/NoUsers";
import { PersonalDetails, AdminAccess, UserTeams } from "../manageUsers/UserOverview";
import { AuthWrapper } from "../../AuthorizationWrapper";
import { AUTHORITIES } from "../../../utils/Enums";
import { PageTitleArea } from "../components/PageTitleArea";
import { VerticalMenu } from "./VerticalMenu";

const UsersContext = createContext();

const ManageUsers = observer(() => {
  const [selectedUser, setSelectedUser] = useState(getFirstUser());

  const [editing, setEditing] = useState();
  const history = useHistory();
  let { id: userId } = useParams();
  const _isMounted = useRef(true); // Initial value _isMounted = true

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const teams = await teamsStore.requestTeams();
        teamsStore.setTeams(teams);

        const users = await usersStore.requestUsers();
        usersStore.setUsers(users);

        const roles = await rolesStore.requestRoles();
        rolesStore.setRoles(roles);
      } catch (error) {}
    };

    fetchData().then(() => {
      if (_isMounted.current) {
        if (userId && usersStore.getUserById(userId)) {
          setSelectedUser(usersStore.getUserById(userId));
        } else {
          const firstUser = getFirstUser();
          if (!selectedUser && firstUser) {
            setSelectedUser(firstUser);
            history.replace(`${ROUTES.USERS}/${firstUser.id}`);
          }
        }
      }
    });
  }, []);

  const usersContext = {
    selectedUser,
    setSelectedUser,
    editing,
    setEditing,
  };

  const downloadUserReport = async () => {
    const { success, data } = await usersStore.downloadUserReport();
    if (success) {
      const url = window.URL.createObjectURL(new Blob([data], { type: "data:text/csv;charset=utf-8," }));
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", "user_report.csv");

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const AddNewUser = () => {
    return (
      <Button
        size="md"
        onClick={() => {
          history.push(ROUTES.USERS_NEW);
        }}
      >
        <IconAdd className={`btn-md-svg`} />
        <Text size="sm" weight="medium">
          Add New
        </Text>
      </Button>
    );
  };

  const DownloadReport = () => {
    return (
      <AuthWrapper allRequired={rolesStore.getActions([AUTHORITIES.USER_EXPORT])}>
        <Button
          size="md"
          color="tertiary"
          onClick={() => {
            downloadUserReport();
          }}
        >
          <Text size="sm" weight="medium">
            Download Report
          </Text>
        </Button>
        <span className="mr-12" />
      </AuthWrapper>
    );
  };

  const PageTitleAreaButtons = () => {
    return (
      <>
        <div className="d-flex">
          <DownloadReport />
          <AddNewUser />
        </div>
      </>
    );
  };

  return (
    <UsersContext.Provider value={usersContext}>
      {selectedUser ? (
        <ManageLayout
          listHeader={<ListHeader />}
          panelHeader={<PanelHeader />}
          pageTitleArea={<PageTitleArea title="Manage users" description="Manage your organisation's users" props={<PageTitleAreaButtons />} />}
          listItems={usersStore
            .getUsers()
            .slice()
            .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
            .map(function (row, index) {
              return <ListItemUser key={index} user={row} />;
            })}
        >
          <ManageLayoutSection title="Personal details">
            <PersonalDetails />
          </ManageLayoutSection>
          <ManageLayoutSection title="Admin access" spacers={["pl-16", "pr-16", "pt-20", "pb-20"]}>
            <AdminAccess />
          </ManageLayoutSection>
          <ManageLayoutSection title="Teams (A-Z)">
            <UserTeams />
          </ManageLayoutSection>
        </ManageLayout>
      ) : (
        <NoUsers />
      )}
    </UsersContext.Provider>
  );
});

const getFirstUser = () => {
  return usersStore.getUsers().length ? usersStore.getNewest() : null;
};

const PanelHeader = () => {
  const history = useHistory();
  const { selectedUser, setSelectedUser } = useContext(UsersContext);
  const { name, email } = selectedUser;

  const canDelete = meStore.isSuperAdmin() ? true : !(selectedUser.admin || selectedUser.superAdmin) ? true : false;

  const deleteUser = async () => {
    const { success } = await usersStore.deleteUser(selectedUser);

    if (success) {
      usersStore.removeUser(selectedUser);
      newestUser();
    }
  };

  const newestUser = () => {
    const newestUser = usersStore.getNewest();
    if (newestUser !== undefined) {
      setSelectedUser(newestUser);
      history.push(`${ROUTES.USERS}/${newestUser.id}`);
    } else {
      history.push(ROUTES.USERS);
      setSelectedUser(false);
    }
  };

  return (
    <R>
      <C10 props={"pl-0"}>
        <div className="d-flex">
          <UserAvatar size="md" />
          <div className="pl-12 pt-8 pb-8">
            <Text size="lg" variant="primary" element="span" weight="bold">
              {name}
            </Text>
          </div>
        </div>
      </C10>

      {canDelete && (
        <C props={"d-flex justify-content-end pr-32 pl-0"}>
          <Button
            size="md"
            color="tertiary"
            disabled={false}
            onClick={() => {
              modalInstance("deleteUserModal").show();
            }}
          >
            <IconDelete className={`btn-md-svg`} />
          </Button>

          <WarningConfirmationModal
            modalId="deleteUserModal"
            heading="Are you sure you want to delete this user?"
            body={
              <ListItem>
                <UserItem header={name} body={email} />
              </ListItem>
            }
            text="This action will affect this user’s access and cannot be undone."
            cancel="Cancel"
            confirm="Delete user"
            onConfirm={deleteUser}
          />
          <span className="ml-8">
            <AuthWrapper allRequired={rolesStore.getActions([AUTHORITIES.USER_UNBLOCK, AUTHORITIES.USER_RESET_MFA])}>
              <VerticalMenu />
            </AuthWrapper>
          </span>
        </C>
      )}
    </R>
  );
};

const ListHeader = () => {
  return (
    <R>
      <C props={"pl-0 pb-20"}>
        <Text size="sm" variant="primary" weight="bold">
          All users (A-Z)
        </Text>
      </C>
    </R>
  );
};

const ListItemUser = ({ user }) => {
  const history = useHistory();
  const { selectedUser, setSelectedUser, setEditing } = useContext(UsersContext);
  const { id, name, email } = user;
  const selected = selectedUser.id === id ? 0 : 1;

  return (
    <ListItem
      active={selected}
      onClick={() => {
        setSelectedUser(user);
        setEditing(false);
        history.push(`${ROUTES.USERS}/${id}`);
      }}
    >
      <UserItem header={name} body={email} />
    </ListItem>
  );
};
export { ManageUsers, UsersContext };
