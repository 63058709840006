import React from "react";
import { useHistory } from "react-router-dom";

import {
  CO,
  C,
  C2,
  C4,
  C6,
  C8,
  R,
  Card,
  Tab,
  TabsSection,
  Button,
  List,
  ListItems,
  ListItem,
  SectionHeader,
  SiblingSection,
  Heading,
  Text,
  Circle,
  IconAdd,
  IconBackArrow,
} from "@fundrecs/ui-library";

const SectionHeaderWithBackButton = ({ heading }) => {
  const history = useHistory();
  return (
    <SectionHeader>
      <SiblingSection
        onClick={() => {
          history.goBack();
        }}
      >
        <div className="d-flex" style={{ alignItems: "center" }}>
          <IconBackArrow width="18px" height="18px" color="#1e5eff" className="mr-4" />
          <Text size="sm" weight="regular" element="div" variant="link">
            Back
          </Text>
        </div>
      </SiblingSection>
      <SiblingSection>
        <Heading variant="h4">{heading}</Heading>
      </SiblingSection>
    </SectionHeader>
  );
};

/**
 * This is used on the create new Team page & the add new User page.
 */
const AddNewLayout = ({ title, description, number, children, hidden }) => {
  const leftCol = "col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-3 pl-0";
  const rightCol = "col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-9";

  return (
    <div hidden={hidden}>
      <R props={["pb-32"]}>
        <C4 props={leftCol}>
          <R>
            <div className="d-flex pl-0 pr-0 addNewLayout-title">
              <C2 props="col-xs-1 col-sm-1 col-md-1 col-lg-2 col-xl-2">
                <Circle number={number.toString()} />
              </C2>
              <div className="addNewLayout-description">
                <Text variant="secondary" size="sm" weight="bold" element="div">
                  {title}
                </Text>
                <Text variant="tertiary" size="xs" weight="regular" element="div">
                  {description}
                </Text>
              </div>
            </div>
          </R>
        </C4>
        <C8 props={rightCol}>{children}</C8>
      </R>
    </div>
  );
};

/**
 * This is used on the manage Teams page, manage Users page
 */
const ManageLayout = ({ headerTabs, listHeader, listItems, panelHeader, pageTitleArea, panelTabs, children }) => {
  return (
    <>
      {pageTitleArea}
      <CO props="pr-0 pl-0 fill">
        {headerTabs && (
          <R props="pl-32 section-border-bottom">
            <C props="pl-0 pr-0">{headerTabs}</C>
          </R>
        )}
        <R>
          <List>
            {listHeader}
            <R>
              <ListItems>{listItems}</ListItems>
            </R>
          </List>

          <C props="section-border-left pr-0 pl-0">
            <R props="pl-32 pt-24">{panelHeader}</R>
            <R props="pl-32 section-border-bottom">
              <C props="pl-0 pr-0">{panelTabs ? panelTabs : <C props="pb-24" />}</C>
            </R>
            <div className="light-grey-bg section-border-left ">
              <R props={"pl-32 pr-32 pb-32"}>{children}</R>
            </div>
          </C>
        </R>
      </CO>
    </>
  );
};

const TabsLayout = ({ tabs, activeTab }) => {
  return (
    <TabsSection>
      <ul className="nav">
        {tabs.map(function (tab, index) {
          const { text, onClick } = tab;
          return (
            <Tab
              key={index}
              text={text}
              active={activeTab === index}
              onClick={() => {
                onClick();
              }}
            />
          );
        })}
      </ul>
    </TabsSection>
  );
};

const TitleSection = ({ ...props }) => {
  const { children } = { ...props };

  return (
    <div className="pt-24 pb-16 pr-0 pl-0">
      {React.Children.toArray(children).map((child, index) => {
        return child;
      })}
    </div>
  );
};

const ManageLayoutSection = ({ title, description, children, spacers }) => {
  return (
    <>
      <TitleSection>
        <Text size="sm" variant="secondary" weight="bold">
          {title}
        </Text>
        {description && (
          <Text variant="tertiary" size="xs" weight="regular" element="div">
            {description}
          </Text>
        )}
      </TitleSection>
      <C props="pl-0 pr-0">
        <Card spacers={[...spacers]}>{children}</Card>
      </C>
    </>
  );
};

ManageLayoutSection.defaultProps = {
  spacers: ["pl-16", "pr-16", "pt-32", "pb-32"],
};

/**
 * This is used on empty state pages, NoTeams, NoUsers and NoSftpConnections.
 */
const EmptyStateLayout = ({ headerTabs, icon, title, description, buttonText, pageTitleArea, customButtonIcon, onClick }) => {
  return (
    <>
      {pageTitleArea}
      <CO props="pr-0 pl-0 fill">
        {headerTabs && (
          <R props="pl-32 section-border-bottom">
            <C props="pl-0 pr-0">{headerTabs}</C>
          </R>
        )}
        <R props="pt-48 justify-content-center">
          <C6 props="text-center pt-32 pb-32 pl-48 pr-48">
            <span className="emptyState-icon">{icon}</span>

            <div className="pt-16">
              <Heading variant="h4" align="center">
                {title}
              </Heading>
            </div>
            <div className="pt-16 pb-32">
              <Text variant="tertiary" size="sm" align="center" weight="regular" element="p">
                {description}
              </Text>
            </div>
            <Button
              onClick={() => {
                onClick();
              }}
            >
              {customButtonIcon ? customButtonIcon : <IconAdd className="btn-md-svg" />}
              <Text size="sm" weight="medium">
                {buttonText}
              </Text>
            </Button>
          </C6>
        </R>
      </CO>
    </>
  );
};

const ListItemsHeader = ({ text }) => {
  return (
    <R>
      <C props={"pl-0 pb-20"}>
        <Text size="sm" variant="primary" weight="bold">
          {text}
        </Text>
      </C>
    </R>
  );
};

const ListItemsItem = ({ name, description, isActive, onClick }) => {
  return (
    <ListItem active={isActive} onClick={() => onClick()}>
      <span className="item-text">
        <Text element="div" size="sm" variant="primary">
          {name}
        </Text>
        {description}
      </span>
    </ListItem>
  );
};

export { SectionHeaderWithBackButton, AddNewLayout, ManageLayout, ManageLayoutSection, TabsLayout, EmptyStateLayout, ListItemsHeader, ListItemsItem };
