import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { R, C, C4, C5, C10, C6, LinkText, Toggle, Text, SaveEditable } from "@fundrecs/ui-library";

const EditableSwitch = observer(({ name, label, description, value, options, onSave, onChange, canEdit = false }) => {
  const [editing, setEditing] = useState(false);
  const [fieldValue, setFieldValue] = useState(value);

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  return (
    <>
      {editing ? (
        <>
          {description ? (
            <TogglePanelWithDescription
              title={label}
              description={description}
              name={name}
              value={fieldValue}
              onChange={() => {
                setFieldValue(!fieldValue);
              }}
            />
          ) : (
            <TogglePanel
              title={label}
              options={options}
              name={name}
              value={fieldValue}
              onChange={() => {
                setFieldValue(!fieldValue);
              }}
            />
          )}

          <C>
            <SaveEditable
              onClickSave={() => {
                onSave(fieldValue);
                setEditing(false);
              }}
              onClickCancel={() => {
                setFieldValue(value);
                setEditing(false);
              }}
            />
          </C>
        </>
      ) : (
        <>
          <C4>
            <Text variant="tertiary" size="sm" weight="regular" element="div">
              {label}
            </Text>
          </C4>
          <C6>
            <Text variant="primary" size="sm" weight="medium" element="div">
              {value ? <> {options.on}</> : <>{options.off}</>}
            </Text>
          </C6>
          <C>
            {canEdit && (
              <span
                className="d-flex justify-content-end"
                onClick={() => {
                  setEditing(true);
                  setFieldValue(value);
                }}
              >
                <LinkText>change</LinkText>
              </span>
            )}
          </C>
        </>
      )}
    </>
  );
});

const TogglePanelWithDescription = ({ name, title, description, value, onChange }) => {
  return (
    <C10>
      <R>
        <C10 props={"pl-0"}>
          <Text variant="primary" size="sm" weight="medium" element="div">
            {title}
          </Text>
        </C10>
        <C props="d-flex flex-row-reverse">
          <Toggle
            name={name}
            checked={value}
            onChange={(event) => {
              const {
                target: { checked },
              } = event;

              if (onChange) {
                onChange(checked);
              }
            }}
          />
        </C>
      </R>
      <R>
        <C10 props={"pl-0"}>
          <Text variant="tertiary" size="xs" weight="regular" element="div">
            {description}
          </Text>
        </C10>
        <C></C>
      </R>
    </C10>
  );
};

const TogglePanel = ({ name, title, options, value, onChange }) => {
  return (
    <>
      <C4>
        <Text variant="tertiary" size="sm" weight="regular" element="div">
          {title}
        </Text>
      </C4>
      <C5 props="d-flex">
        <Text variant="primary" size="sm" weight="medium" element="div">
          {value ? <> {options.on}</> : <>{options.off}</>}
        </Text>
      </C5>
      <C>
        <Toggle
          name={name}
          checked={value}
          onChange={(event) => {
            const {
              target: { checked },
            } = event;

            if (onChange) {
              onChange(checked);
            }
          }}
        />
      </C>
    </>
  );
};

export { EditableSwitch };
