/* eslint-disable no-unused-expressions */
import React from "react";
import { ROLES, ROLE_DESCRIPTIONS } from "../../../utils/Enums";

import { DropdownListItem, DropdownItem, IconInformation, Text, Checkbox, ItemBody, ToolTip } from "@fundrecs/ui-library";

export const DefaultRoleForDropdown = () => {
  return (
    <>
      <DropdownListItem className="pt-4 pb-8 pl-16 pr-16">
        <Text variant="primary" size="sm" weight="medium" element="div">
          Default role
        </Text>
        <Text variant="tertiary" size="xs" weight="regular" element="div">
          All members are assigned as viewers by default, so you don't have to do anything here!
        </Text>
      </DropdownListItem>
      <DropdownListItem className="pb-12">
        <DropdownItem active={-1}>
          <Checkbox checked={true} spacers={["mr-12", "mt-0"]} disabled={true} />
          <ItemBody>{ROLES.VIEWER}</ItemBody>
          <ToolTip text={ROLE_DESCRIPTIONS.VIEWER}>
            <IconInformation className="light-text-muted icon-size" />
          </ToolTip>
        </DropdownItem>
      </DropdownListItem>
      <div style={{ height: "1px", borderBottom: "1px solid #f1f1f1" }}></div>
      <DropdownListItem className="pt-12 pb-8 pl-16 pr-16">
        <Text variant="primary" size="sm" weight="medium" element="div">
          Additional roles
        </Text>
        <Text variant="tertiary" size="xs" weight="regular" element="div">
          You can assign any or all of the following roles to this member
        </Text>
      </DropdownListItem>
    </>
  );
};
