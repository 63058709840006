import { modalInstance, Button, IconAdd, Text } from "@fundrecs/ui-library";
import { CONNECTIONS, MODALS } from "../../../../../utils/Enums";
import { CreateSftpConnectionModal } from "../../Modals/CreateSftpConnectionModal";
import { CreateSftpInputMappingModal } from "../../Modals/CreateSftpInputMappingModal";
import { CreateEmailModal } from "../../Modals/CreateEmailModal";

const AddNewButton = ({ selectedTabIndex }) => {
  return (
    <>
      <CreateSftpConnectionModal connectionType={CONNECTIONS.IMPORT_SFTP_CONNECTION} />
      <CreateSftpInputMappingModal connectionType={CONNECTIONS.IMPORT_SFTP_MAPPING} />
      <CreateEmailModal connectionType={CONNECTIONS.IMPORT_EMAIL_MAPPING} />

      {selectedTabIndex === 0 && (
        <Button
          size="md"
          onClick={() => {
            modalInstance(MODALS.NEW_SFTP_CONNECTION).toggle();
          }}
        >
          <IconAdd className="btn-md-svg" />
          <Text size="sm" weight="medium">
            New SFTP connection
          </Text>
        </Button>
      )}

      {selectedTabIndex === 1 && (
        <Button
          size="md"
          onClick={() => {
            modalInstance(MODALS.NEW_INPUT_MAPPING).toggle();
          }}
        >
          <IconAdd className="btn-md-svg" />
          <Text size="sm" weight="medium">
            New SFTP mapping
          </Text>
        </Button>
      )}

      {selectedTabIndex === 2 && (
        <Button
          size="md"
          onClick={() => {
            modalInstance(MODALS.NEW_EMAIL).toggle();
          }}
        >
          <IconAdd className="btn-md-svg" />
          <Text size="sm" weight="medium">
            New email mapping
          </Text>
        </Button>
      )}
    </>
  );
};

export { AddNewButton };
