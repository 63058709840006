import PropTypes from "prop-types";
import { Text, Status } from "@fundrecs/ui-library";

const ConnectionStatus = ({ status, variant, weight, size }) => {
  return (
    <div className="d-flex">
      <Status status={status} />
      <Text element="div" size={size} variant={variant} weight={weight}>
        {status ? "active" : "inactive"}
      </Text>
    </div>
  );
};

ConnectionStatus.propTypes = {
  /**
   * Status is a prop of type Boolean
   */
  status: PropTypes.bool,
  /**
   * Text variant for the status text
   */
  variant: PropTypes.string,
  /**
   * Text weight for the status text
   */
  weight: PropTypes.string,
  /**
   * Text size for status text
   */
  size: PropTypes.string,
};

ConnectionStatus.defaultProps = {
  /**
   * Default value for status prop
   */
  status: false,
  /**
   * Default Text variant for the status text
   */
  variant: "secondary",
  /**
   * Default Text weight for the status text
   */
  weight: "regular",
  /**
   * Default Text size for the status text
   */
  size: "xs",
};

export { ConnectionStatus };
