import React from "react";

/**
 * ErrorFallback
 * 
 * This is a stub example of a fallback to display when an
 * Error Boundary catches an error from one of it's child components.
 * This component is provided to the Error Boundary as the FallbackComponent prop
 * and receives the error instance and a reset function (not used here).
 * @see https://github.com/bvaughn/react-error-boundary#fallbackcomponent
 * 
 * @param {Error} error - the Error instance caught by the boundary
 */
const ErrorFallback = ({ error }) => {
  /**
   * Based on the error type we can decide whether to show a recovery
   * button(s), giving the user some action that could fix the problem
   * e.g. reloading the view, logging in etc.
   * TODO: add constants for Fusion Error types
   */
  const showRecoveryButton = error.type && error.type === "FSAuthException";
  const recoveryButtonLabel = "Login";

  const handleRecoveryButtonClick = () => window.location = "/login";

  return (
    <div role="alert">
      <h2>Error Boundary: An error occurred in this part of the application.</h2>

      <p>{error.message}</p>

      {showRecoveryButton && (
        <button type="button" onClick={handleRecoveryButtonClick}>
          {recoveryButtonLabel}
        </button>
      )}
    </div>
  );
};

export { ErrorFallback };
