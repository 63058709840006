import { makeAutoObservable } from "mobx";
import { v4 as uuidv4 } from "uuid";

class Notification {
  id = null;
  type = null;
  message = null;

  constructor(type, message) {
    this.id = uuidv4();
    this.type = type;
    this.message = message;
  }
}

class UIStore {
  notifications = [];
  sideMenuOpen = true;

  constructor() {
    makeAutoObservable(this);
  }

  addNotification(type, message) {
    const notification = new Notification(type, message);

    this.notifications.unshift(notification);
  }

  removeNotification(id) {
    const index = this.notifications.findIndex((n) => n.id === id);

    if (index !== -1) {
      this.notifications.splice(index, 1);
    }
  }

  removeAllNotifications() {
    this.notifications.clear();
  }

  toggleSideMenu() {
    this.sideMenuOpen = !this.sideMenuOpen;
  }
}

export { UIStore };
