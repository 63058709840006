import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { R, C, Text, SectionDivider, IconRightArrow } from "@fundrecs/ui-library";
import { ImportSetupContext } from "../ImportSetup";
import { ManageLayoutSection } from "../../../components/Layout";
import { ROUTES } from "../../../../../utils/Enums";
import { NoSftpMappingsForConnection } from "./NoSftpMappingsForConnection";

const SftpMappings = () => {
  const history = useHistory();

  const { selectedSftpConnection } = useContext(ImportSetupContext);
  const { inputMappings } = selectedSftpConnection || {};

  return (
    <>
      {inputMappings.length === 0 && <NoSftpMappingsForConnection />}
      {inputMappings && inputMappings.length > 0 && (
        <ManageLayoutSection
          title={`SFTP directories assigned: ${inputMappings.length}`}
          description="The following SFTP directories belong to this SFTP connection and are used as locations for importing files from."
        >
          {inputMappings
            .sort((a, b) => a.createdOn.localeCompare(b.createdOn))
            .reverse()
            .map((row, index) => {
              const { id, name, fileSource } = row;

              return (
                <R key={index}>
                  <C>
                    <Text variant="primary" size="sm" weight="bold" element="div">
                      {name}
                    </Text>
                    <Text variant="tertiary" size="xs" weight="bold" element="div">
                      {fileSource}
                    </Text>
                  </C>

                  <C props="text-end">
                    <span
                      className="d-flex justify-content-end hand-pointer light-subtitle1"
                      onClick={() => {
                        history.push(`${ROUTES.IMPORT_SFTP_MAPPINGS}/${id}`);
                      }}
                    >
                      <IconRightArrow className={`btn-lg-svg`} />
                    </span>
                  </C>
                  {index < inputMappings.length - 1 ? <SectionDivider props="pl-0 pr-0 pt-12 pb-12" /> : ""}
                </R>
              );
            })}
        </ManageLayoutSection>
      )}
    </>
  );
};

export { SftpMappings };
