import {
  R,
  C,
  C5,
  Input,
  MemberDropdown,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  Member,
  SelectedIcon,
  IconDownArrow,
  IconInformation,
  Checkbox,
  Text,
  Dropdown,
  DropdownButton,
  DropdownButtonText,
  ItemBody,
  Button,
  ToolTip,
} from "@fundrecs/ui-library";
import React, { useState } from "react";
import { useStore } from "../../../store/Store";
import { ROLES, ROLE_DESCRIPTIONS } from "../../../utils/Enums";
import { observer } from "mobx-react-lite";
import { DefaultRoleForDropdown } from "../components/DefaultRoleForDropdown";
import { AddedUser } from "../components/AddedUser";

const AddMembersAndRoles = observer(({ usersAndRoles, setUsersAndRoles }) => {
  const { usersStore, rolesStore } = useStore();
  const selectedRole = rolesStore.getRoles().length > 0 ? [rolesStore.findRoleByName(ROLES.VIEWER).id] : [];

  //selectedUserId & selectedRoleIds store the selected but not-yet-added user and roles
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedRoleIds, setSelectedRoleIds] = useState(selectedRole);
  //memberDropdownOpen Boolean to display member dropdown
  const [memberDropdownOpen, setMemberDropdownOpen] = useState(false);
  //userDropdownValue The text to be displayed in the user dropdown
  const [userDropdownValue, setUserDropdownValue] = useState("");
  //selectedUserIndex Index of the selected user from the dropdown, used to indicate which user is selected
  const [selectedUserIndex, setSelectedUserIndex] = useState(null);

  //Text to be displayed in the role dropdown
  const [displayText, setDisplayText] = useState(generateDisplayText([], rolesStore));
  const dropdownUsers = usersStore
    .getUsers()
    .filter(
      (user) =>
        !user.superAdmin &&
        (user.name.toLowerCase().includes(userDropdownValue.toLowerCase()) || user.email.toLowerCase().includes(userDropdownValue.toLowerCase()))
    );

  /**
   * Called when a role checkbox is clicked, if selected, add to selectedRoleIds, otherwise remove
   * @param {} roleId
   */
  const toggleRole = (event, roleId) => {
    event.stopPropagation();

    if (selectedRoleIds.includes(roleId)) {
      selectedRoleIds.splice(selectedRoleIds.indexOf(roleId), 1);
    } else {
      selectedRoleIds.push(roleId);

      //Check if role is Lookup Approver and add addional role
      checkIfRoleLookupApprover(roleId, rolesStore, selectedRoleIds);
    }

    setDisplayText(generateDisplayText(selectedRoleIds, rolesStore));
    setSelectedRoleIds(selectedRoleIds);
  };

  /**
   * Called when a user is selected from the user dropdown
   * @param {*} userId
   * @param {*} userName
   */
  const changeUser = (userId, userName, userIndex) => {
    const defaultViewerRole = rolesStore.findRoleByName(ROLES.VIEWER).id;
    setSelectedUserIndex(userIndex);
    setUserDropdownValue(userName);
    setSelectedRoleIds([defaultViewerRole]);
    setSelectedUserId(userId);
    setDisplayText(generateDisplayText(userId ? [defaultViewerRole] : [], rolesStore));
  };

  const removeUser = (userId) => {
    const remainingUsers = usersAndRoles;
    delete remainingUsers[userId];
    //Spread, we add the existing array in with spread so it picks it up as new data to cause re-render
    setUsersAndRoles({ ...remainingUsers });
  };

  /**
   * Called when a user is added to the team
   */
  const addUserAndRoles = () => {
    let newUsers = usersAndRoles;
    newUsers[selectedUserId] = selectedRoleIds;

    setUsersAndRoles({ ...newUsers });
    changeUser(null, "", null);
  };

  return (
    <>
      <div className="pl-0 pr-0 mb-24">
        <Text variant="secondary" weight="regular" size="sm">
          Every member will have “viewer” access which enables them to see all content belonging to their team in Fusion. You can assign additional roles to
          each member for specific actions such as approving workflows/templates and creating workflows/templates:
        </Text>
      </div>
      <R>
        <C props="d-flex pl-0 pr-0">
          <Input
            placeholderText="Type to add a member"
            value={userDropdownValue}
            spacers={["mr-12"]}
            onChange={(event) => {
              setUserDropdownValue(event.target.value);
              setMemberDropdownOpen(true);
            }}
          />

          <MemberDropdown active={memberDropdownOpen && dropdownUsers.length > 0}>
            {dropdownUsers.map((user, index) => {
              const { name } = user;
              return (
                <DropdownListItem
                  key={index}
                  onClick={(event) => {
                    const userIndex = selectedUserIndex === index ? null : index;
                    setMemberDropdownOpen(false);
                    changeUser(user.id, name, userIndex);
                  }}
                >
                  <DropdownItem active={selectedUserIndex} index={index}>
                    <Member primary={user.name} secondary={user.email} image={user.avatar} active={selectedUserIndex} index={index} />
                    <SelectedIcon active={selectedUserIndex} index={index} size={"md"} />
                  </DropdownItem>
                </DropdownListItem>
              );
            })}
          </MemberDropdown>
          <C5 props={["pl-0 pr-12"]}>
            <Dropdown spacers={["mr-12"]}>
              <DropdownButton warning={false} disabled={false} size={"md"}>
                <DropdownButtonText>
                  <ItemBody>{displayText}</ItemBody>
                  <IconDownArrow className={`btn-${"md"}-svg`} />
                </DropdownButtonText>
              </DropdownButton>

              <DropdownList>
                <DefaultRoleForDropdown />

                {rolesStore.getAssignableNonAdminRoles().map((role, index) => {
                  return (
                    <DropdownListItem key={Math.random()} onClick={() => {}}>
                      <DropdownItem active={false} index={index}>
                        <Checkbox
                          spacers={["mr-12", "mt-0"]}
                          onClick={(event) => {
                            toggleRole(event, role.id);
                          }}
                          checked={selectedRoleIds.includes(role.id)}
                          disabled={disableRole(role.name, role.id, rolesStore, selectedRoleIds)}
                        />
                        <ItemBody>{role.name}</ItemBody>
                        <ToolTip text={ROLE_DESCRIPTIONS[role.name]} direction="top">
                          <IconInformation className="light-text-muted icon-size" />
                        </ToolTip>
                      </DropdownItem>
                    </DropdownListItem>
                  );
                })}
              </DropdownList>
            </Dropdown>
          </C5>
          <Button
            size="md"
            color="primary"
            disabled={!selectedUserId || !selectedRoleIds.length}
            onClick={() => {
              addUserAndRoles();
            }}
          >
            <Text size="sm" weight="medium">
              Add
            </Text>
          </Button>
        </C>
      </R>

      {Object.keys(usersAndRoles).length > 0 ? (
        <>
          <R props="pt-32">
            <C props="pl-0 pb-16">
              <Text variant="secondary" size="sm" weight="bold" element="div">
                {Object.keys(usersAndRoles).length.toString() + " members added"}
              </Text>
            </C>
            {Object.keys(usersAndRoles).map((userId, index) => {
              const user = usersStore.getUserById(userId);
              //Fix for possible backend bug, where avatar key is not included with the users objects
              //Destructure and set a default string if avatar is undefined
              const { avatar = "" } = user;
              const rolesLength = Object.keys(usersAndRoles).length;

              return (
                <div key={index}>
                  <AddedUser avatar={avatar} id={userId} name={user.name} email={user.email} roleIds={usersAndRoles[userId]} removeUser={removeUser} />
                  {index !== rolesLength - 1 ? <div className="addedUser"></div> : ""}
                </div>
              );
            })}
          </R>
        </>
      ) : (
        ""
      )}
    </>
  );
});

/**
 * Check if the role which was selected is an Approver.
 * If so we also want to select the Lookup Doer role.
 *
 * roleId Role which has been selected
 * @param {*} roleId
 */
const checkIfRoleLookupApprover = (roleId, rolesStore, selectedRoleIds) => {
  const { LOOKUP_APPROVER, LOOKUP_DOER } = ROLES;
  const roleApprover = rolesStore.getRoleById(roleId);
  const { name } = roleApprover;

  //Check if role name is Lookup Approver.
  if (name === LOOKUP_APPROVER) {
    //Find other role for Lookup Doer because we need it's id.
    const roleDoer = rolesStore.findRoleByName(LOOKUP_DOER);
    const { id } = roleDoer;

    //Add Lookup Doer role to selected roles if it's doesn't already exist
    !selectedRoleIds.includes(id) && selectedRoleIds.push(id);
  }
};

/**
 * Disable de-selecting a role if it is needed for another role.
 * @param {*} name
 * @param {*} roleId
 * @param {*} rolesStore
 * @param {*} selectedRoleIds
 * @returns
 */
const disableRole = (name, roleId, rolesStore, selectedRoleIds) => {
  const { LOOKUP_APPROVER, LOOKUP_DOER } = ROLES;

  const approver = rolesStore.findRoleByName(LOOKUP_APPROVER);
  const { id } = approver;
  if (name === LOOKUP_DOER && selectedRoleIds.includes(id)) {
    !selectedRoleIds.includes(roleId) && selectedRoleIds.push(roleId);
    return true;
  } else {
    return false;
  }
};

/**
 * Used to generate a list of the selected roles from an array of role ids
 * @param {*} selectedRoles Array of Role IDs
 * @returns
 */
const generateDisplayText = (selectedRoles, rolesStore) => {
  let text = "";
  //If no user has yet been selected, display default text
  if (!selectedRoles.length) {
    text = "Select one or more roles";
  } else {
    selectedRoles.forEach((roleId) => {
      text += `${rolesStore.findRole(rolesStore.getRoles(), roleId)}, `;
    });
    //Remove final comma and space from the string
    text = text.slice(0, text.length - 2);
  }
  return text;
};

export { AddMembersAndRoles, checkIfRoleLookupApprover, disableRole, generateDisplayText };
