import PropTypes from "prop-types";
import { Text, Button } from "@fundrecs/ui-library";
import { importSftpConnectionsStore } from "../../../../store/Store";

const DownloadKeyButton = ({ privateKeyFile }) => {
  const downloadKey = async (key) => {
    if (!key) return;
    const keyUrl = await importSftpConnectionsStore.getPublicKey(key);
    if (keyUrl !== null) {
      const link = document.createElement("a");

      link.href = keyUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Button
      size="sm"
      color="tertiary"
      onClick={() => {
        downloadKey(privateKeyFile);
      }}
    >
      <Text size="xs">Download Key</Text>
    </Button>
  );
};

// Specifies the types for props:
DownloadKeyButton.propTypes = {
  privateKeyFile: PropTypes.string,
};

// Specifies the default values for props:
DownloadKeyButton.defaultProps = {
  privateKeyFile: "",
};

export { DownloadKeyButton };
