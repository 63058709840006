import { useState } from "react";
import { observer } from "mobx-react-lite";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Button, Text, Toast } from "@fundrecs/ui-library";
import { useStore } from "../../store/Store";

// delay before automatically dismissing Success type toasts
const AUTO_DISMISS_TIME_MS = 6000;

// number of notifications required to show the 'close all' control
const MULTIPLE_CLOSE_THRESHOLD = 3;

const Toaster = observer(() => {
  const { uiStore } = useStore();
  const [autoDismissIds, setAutoDismissIds] = useState([]);
  const showMultipleCloseControl = uiStore.notifications.length >= MULTIPLE_CLOSE_THRESHOLD;

  const handleClose = (id) => {
    uiStore.removeNotification(id);

    if (autoDismissIds.includes(id)) {
      setAutoDismissIds((ids) => ids.filter((dismissId) => dismissId !== id));
    }
  };

  const handleCloseAll = () => {
    uiStore.removeAllNotifications();
  };

  const queueForAutoDismissal = (id) => {
    if (!autoDismissIds.includes(id)) {
      setTimeout(() => handleClose(id), AUTO_DISMISS_TIME_MS);
      setAutoDismissIds((ids) => [...ids, id]);
    }
  };

  // auto dismiss any Success notifications after a delay
  uiStore.notifications.filter((notification) => notification.type === "success").map(({ id }) => queueForAutoDismissal(id));

  return (
    <div className="toast-message-container">
      <TransitionGroup component={null}>
        {uiStore.notifications.map(({ id, type, message }) => (
          <CSSTransition key={id} timeout={350} classNames="toast-message">
            <Toast key={id} type={type} text={message} onCloseClick={() => handleClose(id)} />
          </CSSTransition>
        ))}
      </TransitionGroup>

      {showMultipleCloseControl && (
        <Button color="tertiary" onClick={handleCloseAll}>
          <Text size="xs">Dismiss all</Text>
        </Button>
      )}
    </div>
  );
});

export { Toaster };
