import React, { useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { R, C, ListItems, List, Text } from "@fundrecs/ui-library";
import { ImportSetupContext } from "../ImportSetup";
import { ROUTES } from "../../../../../utils/Enums";
import { inputMappingsStore } from "../../../../../store/Store";
import { ListItemsHeader, ListItemsItem } from "../../../components/Layout";
import { SftpMapping } from "./SftpMapping";
import { SftpMappingHeader } from "./SftpMappingHeader";
import { NoSftpMappings } from "./NoSftpMappings";

const SftpMappingsList = () => {
  const { id } = useParams();
  const history = useHistory();
  const { selectedSftpMapping, setSelectedSftpMapping, setSelectedTabIndex } = useContext(ImportSetupContext);

  useEffect(() => {
    setSelectedTabIndex(1);

    const fetchData = async () => {
      return await inputMappingsStore.requestInputMappings();
    };

    fetchData().then((sftpDirectories) => {
      inputMappingsStore.setInputMappings(sftpDirectories);

      if (!selectedSftpMapping) {
        selectDefaultItem(id);
      }
    });
  }, []);

  useEffect(() => {
    selectDefaultItem(id);
  }, [id]);

  const selectDefaultItem = (id) => {
    const itemById = inputMappingsStore.getInputMappingById(id);
    if (id && itemById) {
      setSelectedSftpMapping(itemById);
    } else {
      const newestItem = inputMappingsStore.getNewestSftpMapping();
      if (newestItem) {
        history.replace(`${ROUTES.IMPORT_SFTP_MAPPINGS}/${newestItem.id}`);
      } else {
        setSelectedSftpMapping();
      }
    }
  };

  const handleSelectItem = (item) => {
    setSelectedSftpMapping(item);
    history.push(`${ROUTES.IMPORT_SFTP_MAPPINGS}/${item.id}`);
  };

  return (
    <>
      {selectedSftpMapping ? (
        <R>
          <List>
            <ListItemsHeader text="All directories (newest-oldest)" />
            <R>
              <ListItems>
                {inputMappingsStore
                  .getSftpMappings()
                  .slice()
                  .sort((a, b) => a.createdOn.localeCompare(b.createdOn))
                  .reverse()
                  .map((row, index) => {
                    return (
                      <ListItemsItem
                        name={row.name}
                        description={
                          <Text element="div" size="xs" variant="tertiary">
                            {row.fileSource}
                          </Text>
                        }
                        isActive={row.id.toString() === id ? 0 : 1}
                        onClick={() => handleSelectItem(row)}
                        key={index}
                      />
                    );
                  })}
              </ListItems>
            </R>
          </List>
          <C props="section-border-left pr-0 pl-0">
            <R props="pl-32 pt-24">{<SftpMappingHeader />}</R>
            <R props="pl-32 section-border-bottom">
              <C props="pl-0 pr-0">
                <C props="pb-24" />
              </C>
            </R>
            <div className="light-grey-bg section-border-left " style={{ height: "100%" }}>
              <R props={"pl-32 pr-32 pb-32"}>
                <SftpMapping />
              </R>
            </div>
          </C>
        </R>
      ) : (
        <NoSftpMappings />
      )}
    </>
  );
};

export { SftpMappingsList };
