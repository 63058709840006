import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import { StoreProvider } from "./store/Store";

import { ErrorFallback } from "./components/adminConsole/components/ErrorFallback";
import { Fusion } from "./components/Fusion";
import "./scrollbars.css";

const App = () => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <Router>
      <StoreProvider>
        <Fusion />
      </StoreProvider>
    </Router>
  </ErrorBoundary>
);

export { App };
