import { useState, useEffect } from "react";
import { Text, Input } from "@fundrecs/ui-library";

const TextInput = ({ label, update, name, value, onChange, classes, warningText }) => {
  const [fieldValue, setFieldValue] = useState(value);

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  return (
    <div className={classes}>
      <div className="pb-4">
        <Text size="sm" weight="medium" variant="secondary" element="div">
          {label}
        </Text>
      </div>
      <Input
        name={name}
        value={fieldValue}
        onChange={(event) => {
          setFieldValue(event.target.value);
          update(event.target.value);

          if (onChange) {
            onChange(event.target.value);
          }
        }}
        warning={Boolean(warningText)}
        warningMessage={warningText}
      />
    </div>
  );
};

export default TextInput;
