/* eslint-disable no-unused-expressions */
import React from "react";
import { useStore } from "../../../store/Store";

import { Badge, IconCloseRemove, R, C, C5, C6, ListItem, UserItem } from "@fundrecs/ui-library";

export const AddedUser = ({ avatar, id, name, email, roleIds, removeUser }) => {
  const { rolesStore } = useStore();
  const roles = rolesStore.getRoles();
  return (
    <R props="pl-0">
      <C6 props="pl-0 pr-0 mt-0 mb-0">
        <div className="d-flex pr-24 pt-12 pb-12 pl-0">
          <ListItem>
            <UserItem avatar={avatar} body={email} header={name} size="md" />
          </ListItem>
        </div>
      </C6>
      <C5 props="mt-0 mb-0 pl-0 pr-0">
        <div className="d-flex pt-12 pb-12">
          <span>
            {roleIds.map((roleId, index) => {
              const name = rolesStore.findRole(roles, roleId);
              return <Badge text={name} key={index} />;
            })}
          </span>
        </div>
      </C5>
      <C props="text-end mt-0 mb-0 pl-0 pr-0 pt-12">
        <IconCloseRemove
          className="btn-lg-svg"
          style={{ cursor: "pointer", color: "#6A7388" }}
          onClick={() => {
            removeUser(id);
          }}
        />
      </C>
    </R>
  );
};
