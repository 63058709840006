import React from "react";
import PropTypes from "prop-types";
import { Heading, VerticalDivider, Text, BorderBottom } from "@fundrecs/ui-library";

const PageTitleArea = ({ title, description, classes, borderBottom, props }) => {
  return (
    <>
      <div className={["page-header d-flex align-items-center", `${classes}`].join(" ")}>
        <Heading variant="h4">{title}</Heading>
        <VerticalDivider />
        <Text variant="tertiary" weight="medium" size="sm" element="span">
          {description}
        </Text>
        <div className="d-flex ms-auto">{props}</div>
      </div>
      {borderBottom && <BorderBottom />}
    </>
  );
};

PageTitleArea.propTypes = {
  borderBottom: PropTypes.bool,
  classes: PropTypes.string,
};

PageTitleArea.defaultProps = {
  borderBottom: true,
  classes: "",
};

export { PageTitleArea };
