import { makeAutoObservable } from "mobx";
import { fusionApi } from "../api";
import { uiStore } from "../store/Store";

const { users } = fusionApi;

class UsersStore {
  users = [];

  constructor() {
    makeAutoObservable(this);
  }

  setUsers(users) {
    this.users = users;
  }

  getUsers() {
    return this.users;
  }

  getUserById(userId) {
    // ensure lookup ID is an integer
    const id = parseInt(userId, 10);

    return this.users.find((user) => user.id === id);
  }

  getNewest = () => {
    return this.getUsers()
      .slice()
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
      .slice(-1)[0];
  };

  replaceUser = (user) => {
    let users = this.getUsers();
    const index = users.findIndex((index) => index.id === user.id);
    users[index] = user;
    this.setUsers(users);
  };

  removeUser = (user) => {
    const filteredUsers = this.users.filter((usr) => usr.id !== user.id);
    this.setUsers(filteredUsers);
  };

  requestUsers = async () => {
    const response = await users.api.list();

    const { data, status } = response;
    if (status === 200) {
      return data;
    } else {
      if (status !== 401 && status !== 403) {
        uiStore.addNotification("error", "Error loading users.");
      }
      return [];
    }
  };

  requestUser = async (userId) => {
    const { data } = await users.api.read(userId);

    return data;
  };

  /**
   * This method sends a request to edit/update a users roles in a team
   *
   * If the request is successful a toast message will appear and a true/false will
   * be returned to the code which called this method.
   *
   * Request object contains teamId, userId & newRoles
   * @param  request
   *
   */
  editUserRoles = async (request) => {
    return users.api
      .resetRolesInATeam(request)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          uiStore.addNotification("success", `Roles successfully changed`);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to change roles! Please try again`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        return uiStore.addNotification("error", `Unable to change roles! Please try again`);
      });
  };

  /**
   * This method sends a request to create a new user.
   *
   * If the request is successful a toast message will appear and a true/false will
   * be returned to the code which called this method.
   *
   * Request object contains user: name,email,settings, team & roles.
   * @param  request
   *
   */
  create = async (request) => {
    const { name } = request;
    return users.api
      .create(request)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          uiStore.addNotification("success", `User "${name}" successfully added`);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to add user "${name}" ! Please try again`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        uiStore.addNotification("error", `Unable to add user "${name}" ! Please try again`);
      });
  };

  /**
   * This method sends a request to delete a user.
   *
   * If the request is successful a toast message will appear and a true/false will
   * be returned to the code which called this method.
   *
   * Request object is selected user object
   * @param  request
   *
   */
  deleteUser = async (request) => {
    const { name } = request;
    return users.api
      .delete(request)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          uiStore.addNotification("success", `"${name}" successfully deleted`);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to delete "${name}" ! Please try again`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        uiStore.addNotification("error", `Unable to delete "${name}" ! Please try again`);
      });
  };

  /**
   * This method sends a request to download the users report.
   *
   * If the request is successful a toast message will appear and a true/false will
   * be returned to the code which called this method.
   *
   */
  downloadUserReport = async () => {
    return users.api
      .report()
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          uiStore.addNotification("success", `User report successfully downloaded`);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to download user report! Please try again`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        uiStore.addNotification("error", `Unable to download user report! Please try again`);
      });
  };

  /**
   * This method sends a request to update a users name.
   *
   * If the request is successful a toast message will appear and a true/false will
   * be returned to the code which called this method.
   *
   * Request is user object with updated details
   * @param  request
   *
   */
  update = async (request, toasts) => {
    const { success, error } = toasts;

    const response = await fusionApi.users.api.update(request).catch((error) => {
      uiStore.addNotification("error", `${error}`);
      return { data: "", response: false };
    });

    if (response.status && response.status === 200) {
      uiStore.addNotification("success", `${success}`);
      return { ...response, ...{ success: true } };
    } else {
      uiStore.addNotification("error", `${error}`);
      return { ...response, ...{ success: false } };
    }
  };

  /**
   * This method sends a request to update a users admin roles.
   *
   * If the request is successful a toast message will appear and a true/false will
   * be returned to the code which called this method.
   *
   * Request object contains userId,teamId and new roles.
   * @param  request
   *
   */
  resetRolesInATeam = async (request) => {
    return users.api
      .resetRolesInATeam(request)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          uiStore.addNotification("success", `User's admin access successfully changed`);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to change user's admin access! Please try again`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        uiStore.addNotification("error", `Unable to change user's admin access! Please try again`);
      });
  };

  /**
   * This method sends a request to unblock a user in auth0.
   *
   * If the request is successful a toast message will appear and a true/false will
   * be returned to the code which called this method.
   *
   */
  unblockUser = async (user) => {
    return users.api
      .unblock(user)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          uiStore.addNotification("success", `User '${user.email}' has been successfully unblocked`);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to unblock user '${user.email}'! Please try again`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        uiStore.addNotification("error", `Unable to unblock user '${user.email}'! Please try again`);
      });
  };

  /**
   * This method sends a request to reset MFA for a user in auth0.
   *
   * If the request is successful a toast message will appear and a true/false will
   * be returned to the code which called this method.
   *
   */
  resetMFAForUser = async (user) => {
    return users.api
      .resetMFA(user)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          uiStore.addNotification("success", `MFA successfully reset for user '${user.email}'`);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to reset MFA for user '${user.email}'! Please try again`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch(() => {
        uiStore.addNotification("error", `Unable to reset MFA for user '${user.email}'! Please try again`);
      });
  };
}

export { UsersStore };
