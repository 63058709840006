import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { R, CO, C, C2, C6, Heading, Text, Card, LinkText, GeoManageUsers, GeoManageTeams, GeoFileImportSetup, GeoFileExportSetup } from "@fundrecs/ui-library";
import { useStore } from "../../store/Store";
import { fusionApi } from "../../api";
import { HTTP_RESPONSE_GET_STRING } from "../../utils/HttpUtils";
import { AUTHORITIES, ROLES, ROUTES } from "../../utils/Enums";
import { AuthWrapper } from "../AuthorizationWrapper";
import { checkAllRequired } from "../../utils/authorization";

const Dashboard = observer(() => {
  const dashboardComponent = "DASHBOARD_COMPONENT";
  const defaultCountString = "-";
  const history = useHistory();
  const { meStore, usersStore, teamsStore, rolesStore } = useStore();
  const teamViewAction = rolesStore.findActionByAuthority(AUTHORITIES.TEAM_VIEW);
  const teamCreateAction = rolesStore.findActionByAuthority(AUTHORITIES.TEAM_CREATE);
  const userViewAction = rolesStore.findActionByAuthority(AUTHORITIES.USER_VIEW);
  const userCreateAction = rolesStore.findActionByAuthority(AUTHORITIES.USER_CREATE);
  const inputMappingViewAction = rolesStore.findActionByAuthority(AUTHORITIES.INPUT_MAPPING_VIEW);
  const inputMappingCreateAction = rolesStore.findActionByAuthority(AUTHORITIES.INPUT_MAPPING_CREATE);
  const outputMappingViewAction = rolesStore.findActionByAuthority(AUTHORITIES.CONNECTION_VIEW);
  const outputMappingCreateAction = rolesStore.findActionByAuthority(AUTHORITIES.CONNECTION_CREATE);
  const connectionCreateAction = rolesStore.findActionByAuthority(AUTHORITIES.CONNECTION_CREATE);

  const [counts, setCounts] = useState({
    importSftpCount: defaultCountString,
    inputMappingsCount: defaultCountString,
    exportSftpCount: defaultCountString,
    exportEmailCount: defaultCountString,
    initialised: false,
  });

  const [usersCount, setUsersCount] = useState(0);
  const [teamsCount, setTeamsCount] = useState(0);

  const colClasses = "col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6";

  const { importConnections, inputMappings, exportConnections } = fusionApi;
  const loggedInUser = meStore.getMe();
  const userActions = meStore.getAllActions();
  useEffect(() => {
    if (!counts.initialised && checkAllRequired(loggedInUser, userActions, rolesStore.getActionsByRoleName([ROLES.CONNECTION_ADMIN]))) {
      getCounts().then(function (val) {
        setCounts({
          importSftpCount: HTTP_RESPONSE_GET_STRING(val.importSftpCountResponse, dashboardComponent, defaultCountString),
          inputMappingsCount: HTTP_RESPONSE_GET_STRING(val.inputMappingsCountResponse, dashboardComponent, defaultCountString),
          exportSftpCount: HTTP_RESPONSE_GET_STRING(val.exportSftpCountResponse, dashboardComponent, defaultCountString),
          exportEmailCount: HTTP_RESPONSE_GET_STRING(val.exportEmailCountResponse, dashboardComponent, defaultCountString),
          initialised: true,
        });
      });
    }
  }, [counts.initialised]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const users = await usersStore.requestUsers();
        usersStore.setUsers(users);
        setUsersCount(users.length);

        const teams = await teamsStore.requestTeams();
        teamsStore.setTeams(teams);
        setTeamsCount(teamsStore.getTeams().length);
      } catch (error) {}
    };
    if (checkAllRequired(loggedInUser, userActions, rolesStore.getActionsByRoleName([ROLES.TEAM_USER_ADMIN]))) {
      fetchData();
    }
  }, []);

  const getCounts = async () => {
    const importSftpCountResponse = importConnections.api.countSftp();
    const inputMappingsCountResponse = inputMappings.api.countAll();
    const exportSftpCountResponse = exportConnections.api.countSftp();
    const exportEmailCountResponse = exportConnections.api.countEmail();
    try {
      return {
        importSftpCountResponse: await importSftpCountResponse,
        inputMappingsCountResponse: await inputMappingsCountResponse,
        exportSftpCountResponse: await exportSftpCountResponse,
        exportEmailCountResponse: await exportEmailCountResponse,
      };
    } catch (error) {}
  };

  const generateTeamsLinks = () => {
    return {
      firstCol: [
        {
          text: "View teams",
          onClick: () => {
            history.push(ROUTES.TEAMS);
          },
          actions: [teamViewAction],
        },
        {
          text: "Add a team",
          onClick: () => {
            history.push(ROUTES.TEAMS_NEW);
          },
          actions: [teamCreateAction],
        },
      ],
      secondCol: [],
    };
  };

  const generateUsersLinks = () => {
    return {
      firstCol: [
        {
          text: "View users",
          onClick: () => {
            history.push(ROUTES.USERS);
          },
          actions: [userViewAction],
        },
        {
          text: "Add a user",
          onClick: () => {
            history.push(ROUTES.USERS_NEW);
          },
          actions: [userCreateAction],
        },
      ],
      secondCol: [],
    };
  };

  const generateImportLinks = () => {
    return {
      firstCol: [
        {
          text: "View connections",
          onClick: () => {
            history.push(ROUTES.IMPORT);
          },
          actions: [inputMappingViewAction],
        },
        {
          text: "Add a connection",
          onClick: () => {
            history.push(ROUTES.IMPORT_SFTP_NEW);
          },
          actions: [connectionCreateAction],
        },
      ],
      secondCol: [
        {
          text: "View mappings",
          onClick: () => {
            history.push(ROUTES.IMPORT_SFTP_MAPPINGS);
          },
          actions: [inputMappingViewAction],
        },
        {
          text: "Add a mapping",
          onClick: () => {
            history.push(ROUTES.IMPORT_SFTP_MAPPINGS_NEW);
          },
          actions: [inputMappingCreateAction],
        },
      ],
    };
  };

  const generateExportLinks = () => {
    return {
      firstCol: [
        {
          text: "View connections",
          onClick: () => {
            history.push(ROUTES.EXPORT_SFTP);
          },
          actions: [outputMappingViewAction],
        },
        {
          text: "Add a connection",
          onClick: () => {
            history.push(ROUTES.EXPORT_SFTP_NEW);
          },
          actions: [connectionCreateAction],
        },
      ],
      secondCol: [
        {
          text: "View email addresses",
          onClick: () => {
            history.push(ROUTES.EXPORT_EMAIL);
          },
          actions: [outputMappingViewAction],
        },
        {
          text: "Add an email address",
          onClick: () => {
            history.push(ROUTES.EXPORT_EMAIL_NEW);
          },
          actions: [outputMappingCreateAction],
        },
      ],
    };
  };

  return (
    <>
      <CO props="light-grey-bg container-full-height">
        <R props="pb-12 pt-48">
          <C>
            <Text variant="tertiary" size="md" weight="regular">{`Welcome back, ${meStore.getName()}`}</Text>
          </C>
        </R>
        <R props="pb-48">
          <C>
            <Heading variant="h1" element="span">
              What would you like to do today?
            </Heading>
          </C>
        </R>
        <AuthWrapper allRequired={rolesStore.getActionsByRoleName([ROLES.TEAM_USER_ADMIN])}>
          <R>
            <C props={colClasses}>
              <DashboardLinksPanel
                title={"Manage teams"}
                description={"Add, update and remove teams and assigned members"}
                leftStat={{ stat: teamsCount, title: "Active teams" }}
                links={generateTeamsLinks()}
                icon={<GeoManageTeams />}
              />
            </C>
            <C props={colClasses}>
              <DashboardLinksPanel
                title={"Manage users"}
                description={"Add, update and remove users and assigned roles"}
                leftStat={{ stat: usersCount, title: "Active users" }}
                links={generateUsersLinks()}
                icon={<GeoManageUsers />}
              />
            </C>
          </R>
        </AuthWrapper>
        <AuthWrapper allRequired={rolesStore.getActionsByRoleName([ROLES.CONNECTION_ADMIN])}>
          <R>
            <C props={colClasses}>
              <DashboardLinksPanel
                title={"Data sources"}
                description={"Add, update and remove connections and mappings"}
                leftStat={{ stat: counts.importSftpCount, title: "SFTP connections" }}
                rightStat={{ stat: counts.inputMappingsCount, title: "SFTP directories" }}
                links={generateImportLinks()}
                icon={<GeoFileImportSetup />}
              />
            </C>
            <C props={colClasses}>
              <DashboardLinksPanel
                title={"Data destinations"}
                description={"Add, update and remove connections and email addresses"}
                leftStat={{ stat: counts.exportSftpCount, title: "SFTP connections" }}
                rightStat={{ stat: counts.exportEmailCount, title: "Email addresses" }}
                links={generateExportLinks()}
                icon={<GeoFileExportSetup />}
              />
            </C>
          </R>
        </AuthWrapper>
      </CO>
    </>
  );
});

/**
 * Component to define an info panel comprising of
 * - Title & description
 * - 2 statistics
 * - Double columned list of links
 */
const DashboardLinksPanel = observer(({ title, description, leftStat, rightStat, links, icon }) => {
  return (
    <Card spacers={["pt-32", "pb-32", "pl-32", "pr-32", "mb-32"]}>
      <R>
        <C2 props="pl-0 pr-0 pr-24">{icon}</C2>
        <C props="pl-0 pr-0">
          <R>
            <C props="pl-0">
              <Heading element="p" variant="h5">
                {title}
              </Heading>
              <Text element="div" size="sm" weight="regular" variant="secondary">
                {description}
              </Text>
            </C>
          </R>
          <R props="pt-24 pb-24">
            <C6 props="pl-0">
              <StatPanel stat={leftStat.stat} title={leftStat.title} />
            </C6>
            <C6 props="pl-0">{!rightStat ? null : <StatPanel stat={rightStat.stat} title={rightStat.title} />}</C6>
          </R>
          <R>
            <C6 props="pl-0">
              <LinksList links={links.firstCol} />
            </C6>
            <C6 props="pl-0">
              <LinksList links={links.secondCol} />
            </C6>
          </R>
        </C>
      </R>
    </Card>
  );
});

/**
 * Component to define a stat panel comprising of a statistic & its title
 */
const StatPanel = observer(({ stat, title }) => {
  return (
    <>
      <Heading variant="h2" element="p">
        {stat}
      </Heading>
      <Text element="span" size="sm" weight="regular" variant="tertiary">
        {title}
      </Text>
    </>
  );
});

/**
 * Component to define a list of links
 */
const LinksList = observer(({ links }) => {
  return links.map((link, index) => {
    return (
      <AuthWrapper allRequired={link.actions} key={index}>
        <LinkText onClick={link.onClick} size="sm">
          <Text weight="regular" size="sm" element="div">
            {link.text}
          </Text>
        </LinkText>
      </AuthWrapper>
    );
  });
});

export { Dashboard };
