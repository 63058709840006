import React, { useState, useContext } from "react";
import { observer } from "mobx-react-lite";
import { C, C10, Text, LinkText, ListItems, Radio, Checkbox, SaveEditable } from "@fundrecs/ui-library";
import { UsersContext } from "../manageUsers/ManageUsers";
import { ROLES, ROLE_DESCRIPTIONS } from "../../../utils/Enums";
import { rolesStore } from "../../../store/Store";

const EditAdminAccess = observer(({ canEdit, userRoles, onSave }) => {
  const { editing, setEditing } = useContext(UsersContext);

  const EditPanel = () => {
    const customRoles = [ROLES.CONNECTION_ADMIN, ROLES.TEAM_USER_ADMIN, ROLES.REPORTING_ADMIN];

    let selectedRoles = [];
    userRoles.map((role) => {
      selectedRoles.push(role.name);
    });
    const [showCustomAdminRoles, setShowCustomAdminRoles] = useState(selectedRoles.some((role) => customRoles.includes(role)));
    const [selectedRolesName, setSelectedRolesName] = useState(selectedRoles);
    const [unselectCustomAdminCheckbox, setUnselectCustomAdminCheckbox] = useState(false);

    const selectRole = (roleName) => {
      switch (roleName) {
        case "NO_ADMIN":
          setSelectedRolesName([]);
          setShowCustomAdminRoles(false);
          setUnselectCustomAdminCheckbox(true);
          break;
        case ROLES.SUPER_ADMIN:
          setSelectedRolesName([ROLES.SUPER_ADMIN]);
          setShowCustomAdminRoles(false);
          setUnselectCustomAdminCheckbox(true);
          break;
        case "CUSTOM_ADMIN":
          setSelectedRolesName([]);
          setShowCustomAdminRoles(true);
          break;
        case ROLES.TEAM_USER_ADMIN:
        case ROLES.REPORTING_ADMIN:
        case ROLES.CONNECTION_ADMIN:
          const index = selectedRolesName.findIndex((selected) => selected === roleName);
          index === -1 ? setSelectedRolesName([...selectedRolesName, ...[roleName]]) : setSelectedRolesName(selectedRolesName.filter((role, i) => i !== index));
          break;
      }
    };

    return (
      <>
        <C10 props="pl-8">
          <div className="segment">
            {adminAccessEditContent.map((row, index) => {
              const { name, displayName, description, subItems } = row;
              let checked;
              switch (name) {
                case "NO_ADMIN":
                  checked = selectedRolesName.length === 0 ? true : false;
                  break;
                case ROLES.SUPER_ADMIN:
                  checked = selectedRolesName.length > 0 && selectedRolesName.some((role) => role === name) ? true : false;
                  break;
                default:
                  checked = selectedRolesName.length > 0 && !selectedRolesName.some((role) => role === ROLES.SUPER_ADMIN) ? true : false;
                  break;
              }
              return (
                <div key={index} className="d-flex pl-8">
                  <Radio
                    checked={checked}
                    onClick={() => {
                      selectRole(name);
                    }}
                  />

                  <div className="pl-12">
                    <div className="pb-4">
                      <Text variant="secondary" size="sm" weight="medium" element="div">
                        {displayName}
                      </Text>
                    </div>
                    <Text variant="tertiary" size="xs" weight="regular" element="div">
                      {description}
                    </Text>
                    {subItems.length > 0 && showCustomAdminRoles && (
                      <ListItems>
                        <div className="custom-admin-children">
                          {subItems.map((subItem, index) => {
                            const { displayName, description, name } = subItem;

                            return (
                              <div key={index} className="d-flex">
                                <Checkbox
                                  spacers={["mr-12", "mt-0", "ml-4"]}
                                  checked={!unselectCustomAdminCheckbox && showCustomAdminRoles && selectedRolesName.some((role) => role === name)}
                                  onChange={() => {
                                    selectRole(name);
                                  }}
                                />
                                <div>
                                  <Text variant="secondary" size="sm" weight="medium" element="div">
                                    {displayName}
                                  </Text>
                                  <Text variant="tertiary" size="xs" weight="regular" element="div">
                                    {description}
                                  </Text>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </ListItems>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </C10>
        <C props="pl-0 pr-16">
          <SaveEditable
            onClickSave={() => {
              let rolesToSave = [];
              selectedRolesName.map((role) => {
                rolesToSave.push(rolesStore.findRoleByName(role)?.id);
              });

              onSave(rolesToSave);
              setEditing(false);
            }}
            onClickCancel={() => {
              setEditing(false);
            }}
          />
        </C>
      </>
    );
  };

  const ViewPanel = () => {
    return (
      <>
        <C10>
          <div className="segment">
            {userRoles.length > 0 ? (
              userRoles.map((row, index) => {
                const { name } = row || {};
                return <RoleItem roleName={name} key={index} />;
              })
            ) : (
              <RoleItem roleName="NO_ADMIN" key={0} />
            )}
          </div>
        </C10>
        {canEdit && (
          <C>
            <span
              className="d-flex justify-content-end"
              onClick={() => {
                setEditing(true);
              }}
            >
              <LinkText>change</LinkText>
            </span>
          </C>
        )}
      </>
    );
  };

  const RoleItem = ({ roleName }) => {
    const { displayName, description } = adminAccessViewContent.find((role) => role.name === roleName) || {};

    return (
      <>
        <div>
          <Text variant="secondary" size="sm" weight="medium" element="span">
            {displayName}
          </Text>
          <Text variant="tertiary" size="xs" weight="regular" element="div">
            {description}
          </Text>
        </div>
      </>
    );
  };

  return editing ? <EditPanel /> : <ViewPanel />;
});

// Content for Edit Panel with all roles
// Description for Connection and Team & User admin are different from ROLE_DESCRIPTIONS
const adminAccessEditContent = [
  {
    name: "NO_ADMIN",
    displayName: "No administration access",
    description: "This person is a standard user who cannot access the admin console but has been assigned one or more roles within teams in the organisation.",
    subItems: [],
  },
  {
    name: ROLES.SUPER_ADMIN,
    displayName: "Super admin",
    description: ROLE_DESCRIPTIONS[ROLES.SUPER_ADMIN],
    subItems: [],
  },
  {
    name: "CUSTOM_ADMIN",
    displayName: "Custom admin",
    description:
      "This person has full authority over any areas they have been granted access to within the admin console. You can assign one or more of these roles.",
    subItems: [
      {
        name: ROLES.CONNECTION_ADMIN,
        displayName: "Connection admin",
        description: "Can create, view, edit and delete import and export connections",
      },
      {
        name: ROLES.TEAM_USER_ADMIN,
        displayName: "Team & user admin",
        description: "Can create, view, edit and delete teams and users, and assign roles to members",
      },
      {
        name: ROLES.REPORTING_ADMIN,
        displayName: "Reporting admin",
        description: "Can create, view, edit and delete reporting dashboards and charts.",
      },
    ],
  },
];

// Content for View Panel with only current user roles
const adminAccessViewContent = [
  {
    name: "NO_ADMIN",
    displayName: "No administration access",
    description: "This person is a standard user who cannot access the admin console but has been assigned one or more roles within teams in the organisation.",
  },
  {
    name: ROLES.SUPER_ADMIN,
    displayName: "Super admin",
    description: ROLE_DESCRIPTIONS[ROLES.SUPER_ADMIN],
  },
  {
    name: ROLES.CONNECTION_ADMIN,
    displayName: "Connection admin",
    description: ROLE_DESCRIPTIONS[ROLES.CONNECTION_ADMIN],
  },
  {
    name: ROLES.TEAM_USER_ADMIN,
    displayName: "Team & user admin",
    description: ROLE_DESCRIPTIONS[ROLES.TEAM_USER_ADMIN],
  },
  {
    name: ROLES.REPORTING_ADMIN,
    displayName: "Reporting admin",
    description: ROLE_DESCRIPTIONS[ROLES.REPORTING_ADMIN],
  },
];

export { EditAdminAccess };
