import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { C, C4, C6, C8, Text, LinkText } from "@fundrecs/ui-library";
import { AuthenticationInput } from "./AuthenticationInput";
import { DownloadKeyButton } from "./DownloadKeyButton";

/**
 * This component is used to view and edit Connection authentication details.
 *
 * @param {Object} existingAuthentication - object containing userName, privateKeyFile and password
 * @param onSave
 * @returns
 */
const EditableAuthentication = ({ existingAuthentication, onSave }) => {
  const [editingOpen, setEditingOpen] = useState(false);
  const [editedValues, setEditedValues] = useState(existingAuthentication);
  const { userName, password, privateKeyFile } = editedValues;

  useEffect(() => {
    setEditedValues(existingAuthentication);
  }, [existingAuthentication]);
  /**
   * This method is called everytime a changes a value in an input.
   * The key param represents the key the backend expects in the connection object.
   * The value is whatever the user types within the input.
   *
   * We take a copy of the current connection object and then combine it with
   * the newly updated values.
   *
   * We then set this as the current connection object that we are creating.
   * @param key
   * @param value
   */
  const handleChange = (key, value) => {
    const updatedValues = { ...editedValues, ...{ [key]: value } };
    setEditedValues(updatedValues);
  };

  /**
   * This method is called when a value changes in AuthenticationInput component.
   * If boolean edit is true, save the updated values, otherwise revert to the original
   * @param {Boolean} edit
   */
  const handleEdit = (edit) => {
    if (edit) {
      onSave(editedValues);
    } else {
      setEditedValues(existingAuthentication);
    }
    setEditingOpen(false);
  };

  return (
    <>
      <C4>
        <Text variant="tertiary" size="sm" weight="regular" element="div">
          Authentication method
        </Text>
      </C4>
      {editingOpen ? (
        <C8>
          <AuthenticationInput
            userName={userName}
            password={password}
            privateKeyFile={privateKeyFile}
            onChange={(key, value) => {
              handleChange(key, value);
            }}
            edit={(boolean) => {
              handleEdit(boolean);
            }}
          />
        </C8>
      ) : (
        <>
          <C6>
            <Text variant="primary" size="sm" weight="medium" element="div">
              Username: {existingAuthentication.userName}
            </Text>
            <div className="pt-4">
              {existingAuthentication.privateKeyFile && (
                <>
                  <Text variant="primary" size="sm" weight="medium" element="div">
                    Key: {existingAuthentication.privateKeyFile}
                  </Text>
                  <div className="pt-12">
                    <DownloadKeyButton privateKeyFile={existingAuthentication.privateKeyFile} />
                  </div>
                </>
              )}
              {existingAuthentication.privateKeyFile === null && (
                <Text variant="primary" size="sm" weight="medium" element="div">
                  <div className="d-flex ">
                    <div className="align-self-center">Password:</div>
                    <input type="password" value="123456789012" className="password-input" readOnly />
                  </div>
                </Text>
              )}
            </div>
          </C6>
          <C>
            <span
              className="d-flex justify-content-end"
              onClick={() => {
                setEditingOpen(true);
                setEditedValues(existingAuthentication);
              }}
            >
              <LinkText>change</LinkText>
            </span>
          </C>
        </>
      )}
    </>
  );
};

// Specifies the types for props:
EditableAuthentication.propTypes = {
  existingAuthentication: PropTypes.object,
  onSave: PropTypes.func,
};

// Specifies the default values for props:
EditableAuthentication.defaultProps = {
  existingAuthentication: {},
  onSave: () => {},
};

export { EditableAuthentication };
