import React, { useEffect, useState } from "react";
import { withRouter, Switch, Redirect } from "react-router-dom";
import { ApmRoute } from "@elastic/apm-rum-react";
import { init as initApm } from "@elastic/apm-rum";

import { observer } from "mobx-react-lite";
import { ErrorBoundary, useErrorHandler } from "react-error-boundary";

import { useStore } from "../store/Store";

import { Sidebar } from "./app/Sidebar";

import { Dashboard } from "./adminConsole/Dashboard";
import { ManageTeams } from "./adminConsole/manageTeams/ManageTeams";
import { NewTeamView } from "./adminConsole/manageTeams/NewTeamView";

import { ManageUsers } from "./adminConsole/manageUsers/ManageUsers";
import { NewUser } from "./adminConsole/manageUsers/NewUser";

import { ImportSetup } from "./adminConsole/fileSetup/import/ImportSetup";
import { ExportSetup } from "./adminConsole/fileSetup/export/ExportSetup";
import { Toaster } from "./app/Toaster";
import { ErrorFallback } from "./adminConsole/components/ErrorFallback";
import { RouteAuthWrapper } from "../components/AuthorizationWrapper";
import { ROLES, ROUTES } from "../utils/Enums";
import { BaseLayout } from "@fundrecs/ui-library/dist/components/BaseLayout";
import { Header } from "./app/Header";

const Fusion = withRouter(
  observer(() => {
    const { meStore, rolesStore, tenantConfigStore, frontendPropertiesStore } = useStore();

    const handleAsyncError = useErrorHandler();
    let [initialised, setInitialised] = useState(false);

    const requestFrontendProperties = async () => {
      await frontendPropertiesStore.requestFrontendProperties();
    };

    useEffect(() => {
      async function fetchData() {
        requestFrontendProperties();
        const roles = await rolesStore.requestRoles();
        rolesStore.setRoles(roles);
        await tenantConfigStore.requestTenantConfig();

        // Render only if requestMe successfull to avoid display errors before redirecting to login
        meStore
          .requestMe()
          .then((success) => {
            setInitialised(success);
          })
          .catch(handleAsyncError);
      }

      if (!initialised) {
        fetchData();
      }
    });

    if (!initialised) {
      return null;
    }

    frontendPropertiesStore.getFrontendPropertyApmEnabled() &&
      initApm({
        // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
        serviceName: process.env.REACT_APP_ELASTIC_APM_RUM_SERVICE_NAME,
        environment: frontendPropertiesStore.getFrontendPropertyApmEnvironment(),
        serverUrl: frontendPropertiesStore.getFrontendPropertyApmServerUrl(),
        // Set service version (required for sourcemap feature)
        serviceVersion: frontendPropertiesStore.getFrontendPropertyApmServiceVersion(),
      });

    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Toaster />
        <BaseLayout
          renderHeader={() => <Header />}
          renderSidebar={() => <Sidebar />}
          renderMain={() => (
            <Switch>
              <ApmRoute exact path={`/`}>
                <Redirect to={ROUTES.DASHBOARD} />
              </ApmRoute>

              <ApmRoute exact path={ROUTES.DASHBOARD}>
                <Dashboard />
              </ApmRoute>

              <ApmRoute exact path={ROUTES.USERS_NEW}>
                <RouteAuthWrapper validActions={rolesStore.getActionsByRoleName([ROLES.TEAM_USER_ADMIN])}>
                  <NewUser />
                </RouteAuthWrapper>
              </ApmRoute>

              <ApmRoute exact path={[ROUTES.USERS, `${ROUTES.USERS}/:id?`]}>
                <RouteAuthWrapper validActions={rolesStore.getActionsByRoleName([ROLES.TEAM_USER_ADMIN])}>
                  <ManageUsers />
                </RouteAuthWrapper>
              </ApmRoute>

              <ApmRoute exact path={ROUTES.TEAMS_NEW}>
                <RouteAuthWrapper validActions={rolesStore.getActionsByRoleName([ROLES.TEAM_USER_ADMIN])}>
                  <NewTeamView />
                </RouteAuthWrapper>
              </ApmRoute>

              <ApmRoute exact path={[ROUTES.TEAMS, `${ROUTES.TEAMS}/:id?`]}>
                <RouteAuthWrapper validActions={rolesStore.getActionsByRoleName([ROLES.TEAM_USER_ADMIN])}>
                  <ManageTeams />
                </RouteAuthWrapper>
              </ApmRoute>

              <ApmRoute exact path={[ROUTES.IMPORT, `${ROUTES.IMPORT}/:type?/:id?`]}>
                <RouteAuthWrapper validActions={rolesStore.getActionsByRoleName([ROLES.CONNECTION_ADMIN])}>
                  <ImportSetup />
                </RouteAuthWrapper>
              </ApmRoute>

              <ApmRoute exact path={[ROUTES.EXPORT, `${ROUTES.EXPORT}/:type?/:id?`]}>
                <RouteAuthWrapper validActions={rolesStore.getActionsByRoleName([ROLES.CONNECTION_ADMIN])}>
                  <ExportSetup />
                </RouteAuthWrapper>
              </ApmRoute>
            </Switch>
          )}
        />
      </ErrorBoundary>
    );
  })
);

export { Fusion };
