import React from "react";
import { useHistory } from "react-router-dom";
import { EmptyTeams } from "@fundrecs/ui-library";
import { EmptyStateLayout } from "../components/Layout";
import { ROUTES } from "../../../utils/Enums";
import { PageTitleArea } from "../components/PageTitleArea";

/**
 * Default page when no teams have yet been added
 */
const NoTeams = ({ ...props }) => {
  const history = useHistory();

  return (
    <EmptyStateLayout
      pageTitleArea={<PageTitleArea title="Manage teams" description="Manage your organisation's teams" />}
      icon={<EmptyTeams className="emptyState-icon" />}
      title="No teams added yet"
      description="Fortunately it’s super easy to add and use teams to assign members to and organise your work in Fusion"
      buttonText="Add a team"
      onClick={() => history.push(ROUTES.TEAMS_NEW)}
    />
  );
};

NoTeams.propTypes = {};

NoTeams.defaultProps = {};

export { NoTeams };
