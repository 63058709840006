import { fusionRequest } from "../fusionRequest";
import { API_ENDPOINTS } from "../config";

const ENDPOINT = API_ENDPOINTS.TEAMS;

const requests = {
  create: (teamRequest) => fusionRequest.post(`${ENDPOINT}/`, teamRequest),
  read: (id) => fusionRequest.get(`${ENDPOINT}/${id}`),
  update: (team) => fusionRequest.put(`${ENDPOINT}/${team.id}`, team),
  delete: (team) => fusionRequest.delete(`${ENDPOINT}/${team.id}`),
  list: () => fusionRequest.get(`${ENDPOINT}/`),
  addUserToTeam: ({ teamId, userId }) => fusionRequest.post(`${ENDPOINT}/${teamId}/users/${userId}`),
  addUsersToTeam: ({ teamId, usersAndRoles }) => fusionRequest.post(`${ENDPOINT}/${teamId}/users/`, usersAndRoles),
  removeUserFromTeam: ({ teamId, userId }) => fusionRequest.delete(`${ENDPOINT}/${teamId}/users/${userId}`),
  verifyNameIsAvailable: (newName) => fusionRequest.get(`${ENDPOINT}/verifyNameIsAvailable/${newName}`),
  addRoleToUser: ({ teamId, userId, roleId }) => fusionRequest.post(`${ENDPOINT}/${teamId}/users/${userId}/roles/${roleId}`),
  removeRoleFromUser: ({ teamId, userId, roleId }) => fusionRequest.delete(`${ENDPOINT}/${teamId}/users/${userId}/roles/${roleId}`),
};

const api = {
  create: (teamRequest) => requests.create(teamRequest),
  list: () => requests.list(),
  verifyNameIsAvailable: (name) => requests.verifyNameIsAvailable(name),
  update: (teamRequest) => requests.update(teamRequest),
  addUserToTeam: (request) => requests.addUserToTeam(request),
  addUsersToTeam: (request) => requests.addUsersToTeam(request),
  delete: (teamRequest) => requests.delete(teamRequest),
  removeUserFromTeam: (request) => requests.removeUserFromTeam(request),
  read: (id) => requests.read(id),
  addRoleToUser: (request) => requests.addRoleToUser(request),
  removeRoleFromUser: (request) => requests.removeRoleFromUser(request),
};

const teams = {
  requests,
  api,
};

export { teams };
