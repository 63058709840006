import { makeAutoObservable } from "mobx";
import { fusionApi } from "../api";
import { uiStore } from "../store/Store";

const { exportConnections } = fusionApi;

class ExportSftpConnectionsStore {
  sftpConnections = [];

  constructor() {
    makeAutoObservable(this);
  }

  setSftpConnections(value) {
    this.sftpConnections = value;
  }

  getSftpConnections() {
    return this.sftpConnections;
  }

  getSftpConnectionsCount() {
    return this.sftpConnections.length;
  }

  getNewestSftpConnection = () => {
    return this.getSftpConnections()
      .slice()
      .sort((a, b) => a.createdOn.localeCompare(b.createdOn))
      .slice(-1)[0];
  };

  getSftpConnectionById(connectionId) {
    return this.getSftpConnections().find((connection) => connection.id == connectionId);
  }

  requestSftpConnections = async () => {
    return exportConnections.api
      .listSftp()
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          return data;
        } else {
          return [];
        }
      })
      .catch((error) => {
        uiStore.addNotification("error", `${error}`);
      });
  };

  replaceSftpConnection = (exportConnection) => {
    let sftpConnections = this.getSftpConnections();
    const index = sftpConnections.findIndex((index) => index.id === exportConnection.id);
    sftpConnections[index] = exportConnection;
    this.setSftpConnections(sftpConnections);
  };

  /**
   * This method adds a newly created connection object to the existing list of connections.
   * It has one param which is a connection object.
   * @param exportConnection
   */
  addNewSftpConnection = (exportConnection) => {
    let sftpConnections = this.getSftpConnections();
    sftpConnections.push(exportConnection);
  };

  /**
   * This method is used to remove an sftp connection that has a connectionUuid which
   * matches the connectionUuid passed as a param. We filter the current array and returning
   * connection objects which don't match.
   *
   * These are then set as the connections.
   *
   * @param connectionUuid
   */
  removeSftpConnection = (connectionUuid) => {
    const filteredConnections = this.sftpConnections.filter((connection) => connection.connectionUuid !== connectionUuid);
    this.setSftpConnections(filteredConnections);
  };

  /**
   * This method sends a request to update a connections details
   *
   * If the request is successful a toast message will appear and a true/false will
   * be returned.
   *
   * We return a true/false so we can continue operations in the app or stall them.
   *
   * Request object contains updated connection details
   * @param  request
   *
   * toasts param are the for the success/error messages to show for the form field being updated.
   * You can only edit/updated one form field at a time so we need to track this and display relevant
   * toast messages.
   * @param  toasts
   *
   * @returns true/false if request succeeded/failed
   */
  update = async (request, toasts) => {
    const { success, error } = toasts;
    return exportConnections.api
      .update(request)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          uiStore.addNotification("success", `${success}`);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `${error}`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        uiStore.addNotification("error", `${error}`);
      });
  };

  /**
   * This method sends a request to create a new connection object.
   * It has one param which is a request body. This request body contains
   * data needed to create a new connection on the backed.
   *
   * If the request is successful we returns the response data.
   * If the request is unsuccessful we return null signifying that request has failed.
   * @param request
   * @returns
   */
  create = async (request) => {
    const { name } = request;
    request.type = "ExportSftpConnection";

    return exportConnections.api
      .create(request)
      .then((response) => {
        const { status } = response;

        if (status === 200) {
          uiStore.addNotification("success", `"${name} connection name " successfully added`);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to add "${name} connection name!" Please try again `);
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        uiStore.addNotification("error", `Unable to add "${name} connection name!" Please try again `);
      });
  };

  /**
   * This method send a request to the delete endpoint for Export Connections
   * to delete a connection that has the uuid param supplied.
   *
   * The enpdoint does not respond with the usual response.data object we
   * would expect so that is why I am setting the returnValue to true/false
   *
   * @param name
   * The name if the export connection you are trying to delete
   *
   * This true/false still works as expected.
   * @param request
   * @returns true/false
   */
  delete = async (request) => {
    const { connectionUuid, name } = request;
    return exportConnections.api
      .delete(connectionUuid)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          uiStore.addNotification("success", `"${name}" successfully deleted!`);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to delete "${name}"! Please try again`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        uiStore.addNotification("error", `Unable to delete "${name}"! Please try again`);
      });
  };
}

export { ExportSftpConnectionsStore };
