import React, { useContext } from "react";
import { R, C, C4, Text, SectionDivider, CreatedBy } from "@fundrecs/ui-library";
import { ImportSetupContext } from "../ImportSetup";
import { EditableText } from "../../../components/editable/EditableText";
import { EditableSelect } from "../../../components/editable/EditableSelect";
import { ManageLayoutSection } from "../../../components/Layout";
import { inputMappingsStore, importSftpConnectionsStore } from "../../../../../store/Store";
import { VALIDATION_MESSAGES } from "../../../util/validation";
import { createToasts } from "../../../util";

const SftpMapping = () => {
  const { selectedSftpMapping, setSelectedSftpMapping } = useContext(ImportSetupContext);
  const { name, type: fileSourceType, fileSource, createdBy, createdOn, connection } = selectedSftpMapping || {};
  const { name: sftpConnectionName, connectionUuid } = connection || {};

  /**
   * This method is called before submit a request to the API to update an object.
   * The object param represents the object to be updated before sending the request.
   * The key param represents the key the backend expects in the object.
   * The value is whatever the user types within the input.
   *
   * We take a copy of the current object and then combine it with
   * the newly updated values.
   *
   * We then return this object.
   * @param object
   * @param key
   * @param value
   */
  const handleChange = (object, key, value) => {
    const updatedObject = { ...object, ...{ [key]: value } };
    return updatedObject;
  };

  const submitSftpMapping = async (key, value) => {
    const inputMapping = handleChange(selectedSftpMapping, key, value);

    const toasts = createToasts(key);

    const { success, data } = await inputMappingsStore.update(inputMapping, toasts);

    if (success) {
      inputMappingsStore.replaceInputMapping(data);

      // Fetch Sftp Connections again if assigned Sftp connection changed
      if (key === "connection" || key === "name") {
        importSftpConnectionsStore.reloadStore();
      }

      setSelectedSftpMapping(data);
    }
  };

  return (
    <ManageLayoutSection title="Overview">
      <R props={"pb-12"}>
        <EditableText
          name="mappingName"
          label="Directory name"
          value={name}
          onSave={(value) => {
            submitSftpMapping("name", value);
          }}
          warningText={VALIDATION_MESSAGES.REQUIRED_FIELD}
        />
      </R>
      <SectionDivider />
      <R props={"pt-12 pb-12"}>
        <EditableText
          name="fileSource"
          label="Directory path"
          value={fileSource}
          onSave={(value) => {
            submitSftpMapping("fileSource", value);
          }}
          warningText={VALIDATION_MESSAGES.REQUIRED_FIELD}
        />
      </R>
      <SectionDivider />
      <R props={"pt-12 pb-12"}>
        <EditableSelect
          name="sftpConnection"
          label="SFTP connection"
          value={{ label: sftpConnectionName, value: connectionUuid }}
          options={importSftpConnectionsStore.getSftpConnections().map(({ name, connectionUuid }) => ({ label: name, value: connectionUuid }))}
          onSave={(value) => {
            submitSftpMapping("connection", { connectionUuid: value });
          }}
        />
      </R>
      <SectionDivider />
      <R props={"pt-12"}>
        <C4>
          <Text size="sm" variant="tertiary" weight="regular">
            Created by
          </Text>
        </C4>
        <C props="pl-0">
          <CreatedBy user={createdBy} date={createdOn} />
        </C>
      </R>
    </ManageLayoutSection>
  );
};

export { SftpMapping };
