import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Card, Section, Text, Input, CO, R, C, C10, Toggle, SubmitAndCancel } from "@fundrecs/ui-library";
import { useStore } from "../../../store/Store";
import { SectionHeaderWithBackButton, AddNewLayout } from "../components/Layout";
import { TEAM_SETTINGS_TYPES, ROUTES } from "../../../utils/Enums";
import { AddMembersAndRoles } from "../components/AddMembersAndRoles";
import { isNullOrEmpty } from "../util/validation";
import { VALIDATION_MESSAGES } from "../util/validation";

const NewTeamView = observer(() => {
  const { usersStore, rolesStore } = useStore();

  useEffect(() => {
    usersStore.requestUsers().then((val) => {
      usersStore.setUsers(val);
    });
    rolesStore.requestRoles().then((val) => {
      rolesStore.setRoles(val);
    });
  });

  return (
    <>
      <SectionHeaderWithBackButton heading="Add new team" />
      <CO props="light-grey-bg container-full-height pt-32 pr-32 pl-32 pb-16">
        <NewTeamForm />
      </CO>
    </>
  );
});

/**
 * Form component to encapsulate inputs for New Team
 */
const NewTeamForm = observer(() => {
  const history = useHistory();
  const { teamsStore, usersStore } = useStore();
  const [isNameUnique, setIsNameUnique] = useState(true);
  const [warningTextMessage, setWarningTextMessage] = useState("");

  /**
   * Update the name for the new team
   * @param {*} value
   */
  const updateName = async (value) => {
    if (isNullOrEmpty(value)) {
      setWarningTextMessage(VALIDATION_MESSAGES.REQUIRED_FIELD);
    } else {
      let nameUnique = true;
      const response = await teamsStore.verifyNameIsAvailable(value);
      nameUnique = response.data;
      if (nameUnique === false) {
        setWarningTextMessage(VALIDATION_MESSAGES.TEAM_NAME_ALREDY_IN_USE);
      } else {
        teamsStore.setTeamName(value);
        setWarningTextMessage();
      }
      setIsNameUnique(nameUnique);
    }
  };

  /**
   * usersAndRoles is an object to map added users to their roles for the Team.
   * eg. {userId-1: [roleId-1, roleId-2, roleId-3, roleId-4], userId-2: [roleId-1, roleId-2] }
   */
  const [usersAndRoles, setUsersAndRoles] = useState({});

  //userDropdownValue The text to be displayed in the user dropdown
  const [userDropdownValue, setUserDropdownValue] = useState("");

  //4 eyes toggles
  const [workFlow4Eyes, setWorkFlow4Eyes] = useState(false);
  const [templates4Eyes, setTemplate4Eyes] = useState(false);

  /**
   * Filter users included in the dropdown: no superAdmins, each name or email must contain the search String from the user input
   */
  let dropdownUsers = [];
  const users = usersStore.getUsers().length > 0 ? usersStore.getUsers() : [];
  for (const user of users) {
    if (!user.superAdmin && (user.name.includes(userDropdownValue) || user.email.includes(userDropdownValue))) {
      dropdownUsers.push(user);
    }
  }

  const goToManageTeams = () => {
    history.push(ROUTES.TEAMS);
  };

  const submitTeam = async () => {
    if (!isNameUnique) {
      return;
    }

    let requestObject = {
      name: teamsStore.getTeamName(),
      settings: [
        { settingFor: TEAM_SETTINGS_TYPES.WORKFLOW_4_EYES, type: "boolean", value: workFlow4Eyes },
        { settingFor: TEAM_SETTINGS_TYPES.TEMPLATE_4_EYES, type: "boolean", value: templates4Eyes },
      ],
      usersAndRoles: usersAndRoles,
    };

    const { success } = await teamsStore.createTeam(requestObject);

    if (success) {
      goToManageTeams();
    }
  };

  const addTeamEnabled = () => {
    const name = teamsStore.getTeamName().length > 0 && teamsStore.getTeamName().match(/^ *$/) == null ? true : false;

    if (!name) {
      return true;
    } else {
      return !isNameUnique;
    }
  };

  return (
    <>
      <AddNewLayout title="Team name" description="Please note the team name you choose must be unique and cannot be reused for any other teams." number={1}>
        <Card spacers={["pt-32", "pr-32", "pb-32", "pl-32"]}>
          <div className="pb-4">
            <Text size="sm" weight="medium" variant="secondary" element="div">
              Team name
            </Text>
          </div>
          <Input
            onChange={(event) => {
              updateName(event.target.value);
            }}
            warning={Boolean(warningTextMessage)}
            warningMessage={warningTextMessage}
          />
        </Card>
      </AddNewLayout>

      <AddNewLayout
        title="Permissions"
        description="Choose what permissions you'd like for your team. We recommend having 4-eyes approval switched on to facilitate delegation of authority and increase transparency."
        number={2}
      >
        <Card spacers={["pt-32", "pr-32", "pb-32", "pl-32"]}>
          <TogglePanel
            name="workflow4Eyes"
            title="4-eyes approval for workflows"
            description="If the team requires 4-eyes approval, members can submit completed workflows for approval by a second individual that is independent to ensure they are reviewed and double checked."
            onChange={() => {
              setWorkFlow4Eyes(!workFlow4Eyes);
            }}
          />
          <div className="section-divider mt-16 mb-16"></div>
          <TogglePanel
            name="template4Eyes"
            title="4-eyes approval for templates"
            description="If the team requires 4-eyes approval, members can submit completed templates for approval by a second individual that is independent to ensure they are reviewed and double checked."
            onChange={() => {
              setTemplate4Eyes(!templates4Eyes);
            }}
          />
        </Card>
      </AddNewLayout>

      <AddNewLayout
        title="Members"
        description="Choose what people you'd like to add as members to your team. You can assign one or more roles to each member."
        number={3}
      >
        <Card spacers={["pt-32", "pr-32", "pb-32", "pl-32"]}>
          <AddMembersAndRoles usersAndRoles={usersAndRoles} setUsersAndRoles={setUsersAndRoles} />
        </Card>

        <R props={"pt-48 text-end"}>
          <C props="pr-0 pl-0">
            <SubmitAndCancel
              disabled={addTeamEnabled()}
              submit={submitTeam}
              submitText="Add team"
              modalHeading="Are you sure you want to cancel adding this team?"
              modalText="Your changes will not be saved and this action cannot be undone"
              redirect={goToManageTeams}
            />
          </C>
        </R>
      </AddNewLayout>
    </>
  );
});

/**
 * This could be moved out if we can find a use for it
 */
const TogglePanel = ({ title, description, onChange, name }) => {
  return (
    <Section top={false} bottom={false}>
      <R>
        <C10 props="align-self-center pl-0">
          <Text variant="primary" size="sm" weight="medium" element="div">
            {title}
          </Text>
        </C10>
        <C props="d-flex flex-row-reverse pr-0">
          <Toggle
            onChange={(value) => {
              onChange(value);
            }}
          />
        </C>
      </R>
      <R>
        <C10 props="align-self-center pl-0">
          <Text variant="tertiary" size="xs" weight="regular" element="div">
            {description}
          </Text>
        </C10>
      </R>
    </Section>
  );
};

export { NewTeamView };
