import { fusionRequest } from "../fusionRequest";
import { API_ENDPOINTS } from "../config";

const ENDPOINT = API_ENDPOINTS.ROLES;

const requests = {
  list: () => fusionRequest.get(`${ENDPOINT}/`),
};

const api = {
  list: () => requests.list(),
};

const roles = {
  requests,
  api,
};

export { roles };
