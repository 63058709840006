import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { WarningConfirmationModal } from "@fundrecs/ui-library";
import { MODALS, CONNECTIONS, ROUTES } from "../../../../utils/Enums";
import { inputMappingsStore, exportMappingsStore } from "../../../../store/Store";

const DeleteSftpInputMappingModal = ({ connectionType, item }) => {
  const history = useHistory();
  const { name, id } = item || {};

  const deleteInputMapping = async () => {
    switch (connectionType) {
      case CONNECTIONS.IMPORT_SFTP_MAPPING:
        doDeleteInputMapping(item);
        break;
      case CONNECTIONS.EXPORT_SFTP_DIRECTORY:
        doDeleteExportMapping(item);
        break;
      default:
        break;
    }
  };

  const doDeleteInputMapping = async (item) => {
    let response = await inputMappingsStore.delete(item);
    const { success } = response;
    if (success) {
      inputMappingsStore.removeInputMapping(id);
      history.replace(`${ROUTES.IMPORT_SFTP_MAPPINGS}`);
    }
  };

  const doDeleteExportMapping = async (item) => {
    let response = await exportMappingsStore.delete(item);
    const { success } = response;
    if (success) {
      exportMappingsStore.removeExportMapping(id);
      history.replace(`${ROUTES.EXPORT_SFTP_DIRECTORY}`);
    }
  };

  return (
    <WarningConfirmationModal
      modalId={MODALS.DELETE_INPUT_MAPPING}
      heading={`Are you sure you want to delete directory ${name} ?`}
      body={""}
      text="This may affect workflows if used as a location for input files and this action cannot be undone."
      cancel="Cancel"
      confirm="Delete Directory"
      onConfirm={() => {
        deleteInputMapping();
      }}
    />
  );
};

// Specifies the types for props:
DeleteSftpInputMappingModal.propTypes = {
  connectionType: PropTypes.string,
  item: PropTypes.object,
};

// Specifies the default values for props:
DeleteSftpInputMappingModal.defaultProps = {
  connectionType: "",
  item: {},
};

export { DeleteSftpInputMappingModal };
