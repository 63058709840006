import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  R,
  C,
  C6,
  C3,
  Dropdown,
  DropdownButton,
  DropdownButtonText,
  ItemBody,
  Input,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  DropdownWarning,
  Text,
  Button,
  IconDownArrow,
  PasswordInput,
  SaveEditable,
} from "@fundrecs/ui-library";
import { importSftpConnectionsStore } from "../../../../store/Store";
import { isNullOrEmpty } from "../../util/validation";
import { DownloadKeyButton } from "./DownloadKeyButton";

const PASSWORD = "Username & password";
const API_KEY = "Username & API key";

const AuthenticationInput = ({ userName, password, privateKeyFile, edit, onChange }) => {
  const [authenticationType, setAuthenticationType] = useState();
  const [keys, setKeys] = useState([]);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const newKeys = await importSftpConnectionsStore.getPublicKeys();
      setKeys(newKeys === null ? [] : newKeys);
    }
    fetchData();

    setAuthenticationType(!privateKeyFile && !password ? "Select" : password ? PASSWORD : API_KEY);
  }, []);

  /**
   * This method calls the API endpoint to generate a new public key.
   * If API call is sucessfull and new key is generated it appends new key to existing keys on keys useState hook.
   * @returns
   */
  const generateKey = async () => {
    const newKey = await importSftpConnectionsStore.generateKey();
    if (newKey !== null) setKeys([newKey, ...keys]);
  };

  /**
   * This method validates if inputs for authentication component are valid.
   * userName: can't be null
   * password: if authenticationType is password, it can't be null
   * privateKeyFile: if authenticationType is keys, it can't be null
   *
   * @returns true/false if there's an error in the authentication inputs
   */
  const validateForm = () => {
    let errors = [];

    if (isNullOrEmpty(userName)) {
      errors.push("username");
    }

    if (authenticationType === PASSWORD) {
      if (isNullOrEmpty(password)) {
        errors.push("password");
      }
    } else if (authenticationType === API_KEY) {
      if (isNullOrEmpty(privateKeyFile)) {
        errors.push("privateKeyFile");
      }
    } else if (authenticationType === "Select") {
      errors.push("authenticationType");
    }

    setErrors(errors);

    return errors.length === 0 ? true : false;
  };

  /**
   * This method checks if an error has occured for an input.
   * It checks the error array for a key attached to the input.
   *
   * @param value
   * @returns true/false if the value exists in the errors or warnings array
   */
  const checkWarning = (value) => {
    return errors.includes(value);
  };

  return (
    <>
      <R props="mb-12">
        <C props={edit === undefined ? "pl-0 pr-0" : "pl-0 pr-8"}>
          <Dropdown>
            <DropdownButton size="sm">
              <DropdownButtonText>
                <ItemBody>{authenticationType}</ItemBody>
                <IconDownArrow className="btn-md-svg" />
              </DropdownButtonText>
            </DropdownButton>
            <DropdownList>
              <DropdownListItem
                onClick={() => {
                  onChange("privateKeyFile", null);
                  setAuthenticationType(PASSWORD);
                }}
              >
                <DropdownItem active={null} index={0}>
                  <ItemBody>{PASSWORD}</ItemBody>
                </DropdownItem>
              </DropdownListItem>
              <DropdownListItem
                onClick={() => {
                  onChange("password", null);
                  setAuthenticationType(API_KEY);
                }}
              >
                <DropdownItem active={null} index={1}>
                  <ItemBody>{API_KEY}</ItemBody>
                </DropdownItem>
              </DropdownListItem>
            </DropdownList>
            <DropdownWarning
              warningMessage="Authentication has to be provided"
              warning={checkWarning("authenticationType") && authenticationType === "Select"}
            />
          </Dropdown>
        </C>
        {edit && (
          <C3 props="pl-0 pr-0">
            <SaveEditable
              onClickSave={() => {
                if (validateForm()) edit(true);
              }}
              onClickCancel={() => {
                edit(false);
              }}
            />
          </C3>
        )}
      </R>
      {authenticationType !== "Select" && (
        <R props="mb-12">
          <Input
            spacers={["pl-0", "pr-0"]}
            name="username"
            value={userName}
            placeholder="Enter username"
            onChange={(event) => {
              onChange("userName", event.target.value);
              edit !== null && validateForm();
            }}
            warningMessage="Username has to be provided"
            warning={checkWarning("username")}
          />
        </R>
      )}
      {authenticationType === PASSWORD && (
        <div className="mb-12">
          <PasswordInput
            name="password"
            value={password}
            placeholder="Enter password"
            onChange={(event) => {
              onChange("password", event.target.value);
              edit !== null && validateForm();
            }}
            warningMessage="Password has to be provided"
            warning={checkWarning("password")}
          />
        </div>
      )}
      {authenticationType === API_KEY && (
        <>
          <R props="mb-12">
            <C props="pl-0 pr-0">
              <Dropdown>
                <DropdownButton size="sm">
                  <DropdownButtonText>
                    <ItemBody>{privateKeyFile ? privateKeyFile : "Select a key"}</ItemBody>
                    <IconDownArrow className={`btn-md-svg`} />
                  </DropdownButtonText>
                </DropdownButton>
                <DropdownList>
                  {keys.map((key, index) => {
                    return (
                      <DropdownListItem
                        onClick={() => {
                          onChange("privateKeyFile", key);
                          edit !== null && validateForm();
                        }}
                        key={index}
                      >
                        <DropdownItem active={null} index={index}>
                          <ItemBody>{key}</ItemBody>
                        </DropdownItem>
                      </DropdownListItem>
                    );
                  })}
                </DropdownList>
                <DropdownWarning warningMessage="API key has to be provided" warning={checkWarning("privateKeyFile") && privateKeyFile === null} />
              </Dropdown>
            </C>
          </R>
          <R>
            <C6 props="pl-0 pr-0">
              <Button
                size="sm"
                onClick={() => {
                  generateKey();
                }}
              >
                <Text size="xs">Generate new key</Text>
              </Button>
              <span className="pr-12"></span>
              {privateKeyFile && <DownloadKeyButton privateKeyFile={privateKeyFile} />}
            </C6>
          </R>
        </>
      )}
    </>
  );
};

// Specifies the types for props:
AuthenticationInput.propTypes = {
  userName: PropTypes.string,
  password: PropTypes.string,
  privateKeyFile: PropTypes.string,
  edit: PropTypes.func,
  onChange: PropTypes.func,
};

// Specifies the default values for props:
AuthenticationInput.defaultProps = {
  userName: "",
  password: null,
  privateKeyFile: null,
  edit: () => {},
  onChange: () => {},
};

export { AuthenticationInput };
