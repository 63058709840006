import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { R, C, Text, SectionDivider, IconRightArrow } from "@fundrecs/ui-library";
import { ExportSetupContext } from "../ExportSetup";
import { ManageLayoutSection } from "../../../components/Layout";
import { ROUTES } from "../../../../../utils/Enums";
import { NoSftpDirectoriesForConnection } from "./NoSftpDirectoriesForConnection";

const SftpDirectories = () => {
  const history = useHistory();

  const { selectedSftpConnection } = useContext(ExportSetupContext);
  const { exportMappings } = selectedSftpConnection || {};

  return (
    <>
      {exportMappings.length === 0 && <NoSftpDirectoriesForConnection />}
      {exportMappings && exportMappings.length > 0 && (
        <ManageLayoutSection
          title={`SFTP directories assigned: (${exportMappings.length})`}
          description="The following SFTP directories belong to this SFTP connection and are used as locations for exporting files to."
        >
          {exportMappings
            .sort((a, b) => a.createdOn.localeCompare(b.createdOn))
            .reverse()
            .map((row, index) => {
              const { id, name, fileSource } = row;

              return (
                <R key={index}>
                  <C>
                    <Text variant="primary" size="sm" weight="bold" element="div">
                      {name}
                    </Text>
                    <Text variant="tertiary" size="xs" weight="bold" element="div">
                      {fileSource}
                    </Text>
                  </C>

                  <C props="text-end">
                    <span
                      className="d-flex justify-content-end hand-pointer light-subtitle1"
                      onClick={() => {
                        history.push(`${ROUTES.EXPORT_SFTP_DIRECTORY}/${id}`);
                      }}
                    >
                      <IconRightArrow className={`btn-lg-svg`} />
                    </span>
                  </C>
                  {index < exportMappings.length - 1 ? <SectionDivider props="pl-0 pr-0 pt-12 pb-12" /> : ""}
                </R>
              );
            })}
        </ManageLayoutSection>
      )}
    </>
  );
};

export { SftpDirectories };
