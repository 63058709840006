import { fusionRequest } from "../fusionRequest";
import { API_ENDPOINTS } from "../config";

const ENDPOINT = API_ENDPOINTS.EXPORT_MAPPINGS;

const requests = {
  create: (mapping) => fusionRequest.post(`${ENDPOINT}`, mapping),
  list: () => fusionRequest.get(`${ENDPOINT}`),
  update: (requestBody) => fusionRequest.put(`${ENDPOINT}/${requestBody.id}`, requestBody),
  delete: (id) => fusionRequest.delete(`${ENDPOINT}/${id}`),
};

const api = {
  create: requests.create,
  list: () => requests.list(),
  update: (requestBody) => requests.update(requestBody),
  delete: requests.delete,
};

const exportMappings = {
  requests,
  api,
};

export { exportMappings };
