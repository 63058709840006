import React, { useReducer, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Text,
  Panel,
  PanelBody,
  PanelFooter,
  PanelHeader,
  Dropdown,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  DropdownButton,
  DropdownButtonText,
  DropdownWarning,
  ItemBody,
  IconDownArrow,
  modalInstance,
  InputLabelWithToolTip,
} from "@fundrecs/ui-library";
import { inputMappingsStore, exportMappingsStore, importSftpConnectionsStore, exportSftpConnectionsStore } from "../../../../store/Store";
import { MODALS, CONNECTIONS, ROUTES } from "../../../../utils/Enums";
import { FormInput } from "../../components/InputComponents/FormComponents";
import { isNullOrEmpty } from "../../util/validation";
import { getCreateFormContent } from "../utils/formContent";

// maps fields to their related error strings returned by the API
const fieldValidations = {
  name: "Input Mapping name",
  fileSource: "file Source",
  connection: "Connection",
};

const formActions = {
  UPDATE: "update",
  RESET: "reset",
  ERROR: "error",
};

const initialFormState = {
  errors: [],
  values: {
    name: "",
    type: "SFTP_FOLDER",
    fileSource: "",
    connection: null,
  },
};

function reducer(state, { type, data }) {
  switch (type) {
    case formActions.UPDATE:
      return {
        ...state,
        values: { ...state.values, [data.fieldKey]: data.value },
        errors: state.errors.filter((err) => err !== fieldValidations[data.fieldKey]),
      };

    case formActions.ERROR:
      return {
        ...state,
        errors: Object.values(fieldValidations).filter((value) => data.includes(value)),
      };

    case formActions.RESET:
      return initialFormState;

    default:
      throw new Error();
  }
}

const CreateSftpInputMappingModal = ({ connectionType }) => {
  const history = useHistory();
  const [formState, dispatch] = useReducer(reducer, initialFormState);
  const [sftpConnections, setSftpConnections] = useState([]);
  const formContent = getCreateFormContent(connectionType);

  useEffect(() => {
    const fetchData = async () => {
      return connectionType === CONNECTIONS.EXPORT_SFTP_DIRECTORY
        ? await exportSftpConnectionsStore.requestSftpConnections()
        : await importSftpConnectionsStore.requestSftpConnections();
    };

    fetchData().then((sftpConnections) => {
      if (connectionType === CONNECTIONS.EXPORT_SFTP_DIRECTORY) {
        exportSftpConnectionsStore.setSftpConnections(sftpConnections);
      } else {
        importSftpConnectionsStore.setSftpConnections(sftpConnections);
      }
      setSftpConnections(sftpConnections);
    });
  }, []);

  /**
   * This method is called everytime a changes a value in an input.
   * The key param represents the key the backend expects in the connection object.
   * The value is whatever the user types within the input.
   *
   * We take a copy of the current connection object and then combine it with
   * the newly updated values.
   *
   * We then set this as the current connection object that we are creating.
   * @param fieldKey
   * @param value
   */
  const handleInputChange = (fieldKey, value) => {
    dispatch({
      type: formActions.UPDATE,
      data: { fieldKey, value },
    });
  };

  const handleCancel = () => {
    dispatch({ type: formActions.RESET });
    modalInstance(MODALS.NEW_INPUT_MAPPING).hide();
  };

  const handleSuccess = () => {
    dispatch({ type: formActions.RESET });
    modalInstance(MODALS.NEW_INPUT_MAPPING).hide();
  };

  const handleError = (error) => {
    dispatch({
      type: formActions.ERROR,
      data: error.toLowerCase(),
    });
  };

  const checkError = (value) => formState.errors.includes(value);

  const createMapping = async () => {
    
    const newMapping = (connectionType === CONNECTIONS.IMPORT_SFTP_MAPPING) ? 
                  await inputMappingsStore.create(formState.values)
                : await exportMappingsStore.create(formState.values);
    
    const { success, data } = newMapping;

    if (success) {
      handleSuccess();

      if (connectionType === CONNECTIONS.IMPORT_SFTP_MAPPING) {
        inputMappingsStore.addNewInputMapping(data);
        history.replace(`${ROUTES.IMPORT_SFTP_MAPPINGS}/${data.id}`);
      } else {
        exportMappingsStore.addNewExportMapping(data);
        history.replace(`${ROUTES.EXPORT_SFTP_DIRECTORY}/${data.id}`);
      }
    } else {
      handleError(data);
    }
  };

  const createMappingEnabled = () => {
    return (
      !isNullOrEmpty(formState.values.name) &&
      formState.errors.length === 0 &&
      !isNullOrEmpty(formState.values.fileSource) &&
      formState.values.connection !== null
    );
  };

  return (
    <>
      <Panel panelId={MODALS.NEW_INPUT_MAPPING}>
        <PanelBody>
          <PanelHeader header={formContent.HEADER_LABEL} description={formContent.HEADER_DESCRIPTION} />

          <div className="pt-12 pb-12">
            <InputLabelWithToolTip label={formContent.NAME_LABEL} tooltipText={formContent.NAME_DESCRIPTION} />
            <div className="pt-8">
              <FormInput
                fieldKey={"name"}
                handleChange={handleInputChange}
                warningMessage="Valid directory name has to be provided"
                warning={checkError(fieldValidations.name)}
                value={formState.values.name}
              />
            </div>
          </div>
          <div className="pt-12 pb-12">
            <InputLabelWithToolTip label={formContent.FILESOURCE_LABEL} tooltipText={formContent.FILESOURCE_LABEL_DESCRIPTION} />
            <div className="pt-8">
              <FormInput
                fieldKey={"fileSource"}
                handleChange={handleInputChange}
                warningMessage="Valid file path has to be provided"
                warning={checkError(fieldValidations.fileSource)}
                value={formState.values.fileSource}
              />
            </div>
          </div>
          <div className="pt-12 pb-12">
            <InputLabelWithToolTip label={formContent.CONNECTION_LABEL} tooltipText={formContent.CONNECTION_DESCRIPTION} />
            <div className="pt-8">
              <Dropdown>
                <DropdownButton size="sm">
                  <DropdownButtonText>
                    <ItemBody>{formState.values.connection ? formState.values.connection.name : "Select"}</ItemBody>
                    <IconDownArrow className={`btn-md-svg`} />
                  </DropdownButtonText>
                </DropdownButton>
                <DropdownList>
                  {sftpConnections &&
                    sftpConnections.map((connection, index) => {
                      return (
                        <DropdownListItem onClick={() => handleInputChange("connection", connection)} key={connection.connectionUuid}>
                          <DropdownItem active={formState.values.connection?.connectionUuid} index={connection.connectionUuid}>
                            <ItemBody>{connection.name}</ItemBody>
                          </DropdownItem>
                        </DropdownListItem>
                      );
                    })}
                </DropdownList>
                <DropdownWarning warningMessage="An SFTP Connection must be selected" warning={checkError(fieldValidations.connection)} />
              </Dropdown>
            </div>
          </div>
        </PanelBody>

        <PanelFooter>
          <Button color="tertiary" onClick={handleCancel}>
            <Text size="sm">Cancel</Text>
          </Button>
          <Button
            onClick={() => {
              createMapping();
            }}
            disabled={!createMappingEnabled()}
          >
            <Text size="sm">{formContent.SUBMIT_BUTTON_LABEL}</Text>
          </Button>
        </PanelFooter>
      </Panel>
    </>
  );
};

export { CreateSftpInputMappingModal };
