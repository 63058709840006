import React, { useState, createContext, useContext, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { useHistory, useParams } from "react-router-dom";
import { Button, C, C2, R, TeamItem, ListItem, Text, IconAdd, Heading, IconDelete, modalInstance, WarningConfirmationModal } from "@fundrecs/ui-library";
import { teamsStore, rolesStore, usersStore } from "../../../store/Store";
import { ROUTES } from "../../../utils/Enums";
import { NoTeams } from "../manageTeams/NoTeams";
import { TeamMembers } from "../manageTeams/TeamMembers";
import { TeamOverview } from "../manageTeams/TeamOverview";
import { ManageLayout, TabsLayout } from "../components/Layout";
import { PageTitleArea } from "../components/PageTitleArea";

const TeamsContext = createContext();

const ManageTeams = observer(() => {
  const history = useHistory();
  const [selectedTeam, setSelectedTeam] = useState(getFirstTeam());
  const _isMounted = useRef(true); // Initial value _isMounted = true

  let { id: teamId } = useParams();

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const teams = await teamsStore.requestTeams();
        teamsStore.setTeams(teams);

        const users = await usersStore.requestUsers();
        usersStore.setUsers(users);

        const roles = await rolesStore.requestRoles();
        rolesStore.setRoles(roles);
      } catch (error) {}
    };

    fetchData().then(() => {
      if (_isMounted.current) {
        if (teamId && teamsStore.getTeamById(teamId)) {
          setSelectedTeam(teamsStore.getTeamById(teamId));
        } else {
          const firstTeam = getFirstTeam();
          if (!selectedTeam && firstTeam) {
            setSelectedTeam(firstTeam);
            history.replace(`${ROUTES.TEAMS}/${firstTeam.id}`);
          }
        }
      }
    });
  }, []);

  const teamsContext = {
    selectedTeam,
    setSelectedTeam,
  };

  const [tab, setTab] = useState(0);

  const AddNewTeam = () => {
    return (
      <Button
        size="md"
        onClick={() => {
          history.push(ROUTES.TEAMS_NEW);
        }}
      >
        <IconAdd className={`btn-md-svg`} />
        <Text size="sm" weight="medium">
          Add New
        </Text>
      </Button>
    );
  };

  return (
    <TeamsContext.Provider value={teamsContext}>
      {selectedTeam ? (
        <>
          <ManageLayout
            listHeader={<ListHeader />}
            panelHeader={<PanelHeader />}
            pageTitleArea={<PageTitleArea title="Manage teams" description="Manage your organisation's teams" props={<AddNewTeam />} />}
            listItems={teamsStore
              .getTeams()
              .slice()
              .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
              .map(function (row, index) {
                return <ListItemTeam key={index} team={row} />;
              })}
            panelTabs={
              <TabsLayout
                tabs={[
                  { text: "Overview", onClick: () => setTab(0) },
                  { text: "Members", onClick: () => setTab(1) },
                ]}
                activeTab={tab}
              />
            }
          >
            {tab === 0 && <TeamOverview />}
            {tab === 1 && <TeamMembers />}
          </ManageLayout>
        </>
      ) : (
        <NoTeams />
      )}
    </TeamsContext.Provider>
  );
});

const getFirstTeam = () => {
  return teamsStore.getTeams().length ? teamsStore.getNewest() : null;
};

const ListItemTeam = observer(({ team }) => {
  const history = useHistory();
  const { selectedTeam, setSelectedTeam } = useContext(TeamsContext);
  const { id, name, users } = team;
  const selected = selectedTeam.id === id ? 0 : 1;

  return (
    <ListItem
      active={selected}
      onClick={() => {
        setSelectedTeam(team);
        history.push(`${ROUTES.TEAMS}/${id}`);
      }}
    >
      <TeamItem avatar={name} body={`${users.length} members`} header={name} />
    </ListItem>
  );
});

const PanelHeader = observer(() => {
  const history = useHistory();
  const { selectedTeam, setSelectedTeam } = useContext(TeamsContext);
  const { name, users } = selectedTeam;

  const deleteTeam = async () => {
    const { success } = await teamsStore.deleteTeam(selectedTeam);
    if (success) {
      //Remove team from store and set SelectedTeam to newest created
      teamsStore.removeTeam(selectedTeam);
      newestTeam();
    }
  };

  const newestTeam = () => {
    //Get the newest team
    const newestTeam = teamsStore.getNewest();

    //Check if there is actually an object returned
    if (newestTeam !== undefined) {
      setSelectedTeam(newestTeam);
      history.push(`${ROUTES.TEAMS}/${newestTeam.id}`);
    } else {
      history.push(ROUTES.TEAMS);
      setSelectedTeam(false);
    }
  };

  return (
    <R props="pl-0 pr-0">
      <C props="pl-0 pr-0">
        <Heading variant="h5">{name}</Heading>
      </C>
      <C props={"d-flex justify-content-end pr-32 pl-0"}>
        <Button
          size="md"
          color="tertiary"
          disabled={false}
          onClick={() => {
            modalInstance("deleteTeamModal").show();
          }}
        >
          <IconDelete className={`btn-md-svg`} />
        </Button>

        <WarningConfirmationModal
          modalId="deleteTeamModal"
          heading="Are you sure you want to delete this team?"
          body={
            <>
              <ListItem>
                <TeamItem avatar={name} body={`${users.length} members`} header={name} />
              </ListItem>
            </>
          }
          text="This action will affect all team members and their activity and cannot be undone."
          cancel="Cancel"
          confirm="Delete team"
          onConfirm={deleteTeam}
        />
      </C>
    </R>
  );
});

const ListHeader = () => {
  return (
    <R>
      <C props={"pl-0 pb-20"}>
        <Text size="sm" variant="primary" weight="bold">
          All teams (A-Z)
        </Text>
      </C>
    </R>
  );
};

export { ManageTeams, TeamsContext };
