import { makeAutoObservable } from "mobx";
import { fusionApi } from "../api";

class FrontendPropertiesStore {
  apmEnabled = false;
  elasticApmEnvironment = "";
  elasticApmServerUrl = "";
  elasticApmServiceVersion = "";

  constructor() {
    makeAutoObservable(this);
  }

  setFrontendProperties = (data) => {
    const { apmEnabled = false, elasticApmServerUrls = "", elasticApmEnvironment = "", elasticApmServiceVersion } = data;

    this.apmEnabled = apmEnabled;
    this.elasticApmEnvironment = elasticApmEnvironment;
    this.elasticApmServerUrl = elasticApmServerUrls;
    this.elasticApmServiceVersion = elasticApmServiceVersion;
  };

  getFrontendPropertyApmEnabled = () => {
    return this.apmEnabled;
  };

  getFrontendPropertyApmEnvironment = () => {
    return this.elasticApmEnvironment;
  };

  getFrontendPropertyApmServerUrl = () => {
    return this.elasticApmServerUrl;
  };

  getFrontendPropertyApmServiceVersion = () => {
    return this.elasticApmServiceVersion;
  };

  requestFrontendProperties = async () => {
    const { data } = await fusionApi.frontendProperties.getFrontendProperties();
    this.setFrontendProperties(data);
  };
}

export { FrontendPropertiesStore };
