import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { CO, Card, Radio, Checkbox, Text, Note, SubmitAndCancel, R, C } from "@fundrecs/ui-library";
import { usersStore, useStore } from "../../../store/Store";
import { ROLES, ROUTES } from "../../../utils/Enums";
import { AddNewLayout, SectionHeaderWithBackButton } from "../components/Layout";
import TextInput from "../components/InputComponents/TextInput";
import { AddTeamsAndRoles } from "../components/InputComponents/AddTeamsAndRoles";
import { AuthWrapper } from "../../AuthorizationWrapper";
import { isNullOrEmpty, isEmailValid, VALIDATION_MESSAGES } from "../util/validation";

//These are delcared outside the component function to prevent them being reset for every re-render - this can cause bugs if they are accessed before state has updated
let adminTeamId = null;
let superAdminRoleId = null;
let connectionAdminRoleId = null;
let teamAndUserAdminRoleId = null;
let reportingAdminRoleId = null;

/**
 * This is the root component for the page.
 */
const NewUser = observer(() => {
  const history = useHistory();
  const { teamsStore, rolesStore } = useStore();

  const [teamAndUserActions, setTeamAndUserActions] = useState([]);
  const [superAdminActions, setSuperAdminActions] = useState([]);
  const [warningMessageName, setWarningMessageName] = useState("");
  const [warningMessageEmail, setWarningMessageEmail] = useState("");

  useEffect(() => {
    async function fetchData() {
      teamsStore.requestTeams().then((val) => {
        teamsStore.setTeams(val);
        if (!adminTeamId) {
          adminTeamId = teamsStore.getAdminTeam().id;
        }
      });
      const roles = await rolesStore.requestRoles();
      await rolesStore.setRoles(roles);
      if (!superAdminRoleId) {
        superAdminRoleId = rolesStore.findRoleByName(ROLES.SUPER_ADMIN).id;
      }
      if (!connectionAdminRoleId) {
        connectionAdminRoleId = rolesStore.findRoleByName(ROLES.CONNECTION_ADMIN).id;
      }
      if (!teamAndUserAdminRoleId) {
        teamAndUserAdminRoleId = rolesStore.findRoleByName(ROLES.TEAM_USER_ADMIN).id;
      }
      if (!reportingAdminRoleId) {
        reportingAdminRoleId = rolesStore.findRoleByName(ROLES.REPORTING_ADMIN).id;
      }
      if (!teamAndUserActions.length) {
        setTeamAndUserActions(rolesStore.getActionsByRoleName([ROLES.TEAM_USER_ADMIN]));
      }
      if (!superAdminActions.length) {
        setSuperAdminActions(rolesStore.getActionsByRoleName([ROLES.SUPER_ADMIN]));
      }
    }
    fetchData();
  }, []);

  /**
   * teamsAndRoles is an object to map added teams to the assigned user roles for the Team.
   * eg. {teamId-1: [roleId-1, roleId-2, roleId-3, roleId-4], teamId-2: [roleId-1, roleId-2] }
   */
  const [teamsAndRoles, setTeamsAndRoles] = useState({});
  const [superAdminSelected, setSuperAdminSelected] = useState(false);
  const [customAdminSelected, setCustomAdminSelected] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const uncheckCheckboxes = () => {
    const checkboxes = document.getElementsByClassName("new-user-checkbox");
    for (let i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i]["checked"] = false;
    }
  };

  /**
   *
   */
  const selectNoAdmin = () => {
    delete teamsAndRoles[adminTeamId];
    setTeamsAndRoles(teamsAndRoles);
    setCustomAdminSelected(false);
    setSuperAdminSelected(false);
    uncheckCheckboxes();
  };

  /**
   * Set Super Admin role to the admin team
   */
  const selectSuperAdmin = () => {
    teamsAndRoles[adminTeamId] = [superAdminRoleId];
    setTeamsAndRoles(teamsAndRoles);
    setCustomAdminSelected(false);
    setSuperAdminSelected(true);
    uncheckCheckboxes();
  };

  /**
   * Set the boolean to display the Connection / Team & User Admin checkboxes
   */
  const selectCustomAdmin = () => {
    teamsAndRoles[adminTeamId] = [];
    setTeamsAndRoles(teamsAndRoles);
    setCustomAdminSelected(true);
    setSuperAdminSelected(false);
  };

  const toggleAdminPermission = (boolean, roleId) => {
    if (teamsAndRoles[adminTeamId] === undefined) {
      teamsAndRoles[adminTeamId] = [];
    }
    if (boolean) {
      teamsAndRoles[adminTeamId].push(roleId);
    } else {
      teamsAndRoles[adminTeamId] = teamsAndRoles[adminTeamId].filter((id) => id !== roleId);
    }
    setTeamsAndRoles({ ...teamsAndRoles });
  };

  const addUserEnabled = () => {
    //Check the name and email exist.
    //If an admin button is selected, check the admin team and at lease one admin role is assigned

    return (
      userName &&
      userEmail &&
      !Boolean(warningMessageEmail) &&
      !Boolean(warningMessageEmail) &&
      (customAdminSelected || superAdminSelected
        ? teamsAndRoles[adminTeamId] &&
          Array.isArray(teamsAndRoles[adminTeamId]) &&
          teamsAndRoles[adminTeamId].some((role) => [superAdminRoleId, teamAndUserAdminRoleId, connectionAdminRoleId, reportingAdminRoleId].includes(role))
        : true)
    );
  };

  const submitNewUser = async () => {
    if (!addUserEnabled()) {
      return;
    }
    let requestObject = {
      name: userName.trim(),
      email: userEmail.trim(),
      settings: [],
      teamsAndRoles: teamsAndRoles,
    };

    const { success } = await usersStore.create(requestObject);
    if (success) {
      goToManageUsers();
    }
  };

  const goToManageUsers = () => {
    history.push(ROUTES.USERS);
  };

  /**
   * This is the data that is mapped to the sections.
   * The actions array will need to be populatd with data the Authorization container can interact with.
   */
  const sections = [
    {
      title: "Personal details",
      description: "This person's email address is used as their unique identifier and therefore cannot be changed once added.",
      actions: teamAndUserActions,
      components: (
        <>
          <TextInput
            label="Full name"
            name="userName"
            onChange={(value) => {
              setWarningMessageName(isNullOrEmpty(value) ? VALIDATION_MESSAGES.REQUIRED_FIELD : "");
            }}
            update={(value) => {
              setUserName(value);
            }}
            warningText={warningMessageName}
            classes="mb-20"
          />
          <TextInput
            label="Email address"
            name="userEmailAddress"
            onChange={(value) => {
              setWarningMessageEmail(isEmailValid(value) ? "" : VALIDATION_MESSAGES.EMAIL_INVALID);
            }}
            update={(value) => {
              setUserEmail(value);
            }}
            warningText={warningMessageEmail}
          />
        </>
      ),
      hidden: false,
    },
    {
      title: "Admin access",
      description:
        "Admin roles allow people to take action in the admin console. Super admins have all admin permissions for all functionality within Fusion, while custom admins only have the permissions you choose.",
      actions: superAdminActions,
      components: (
        <>
          <Text variant="secondary" size="sm" weight="regular" element="div">
            Choose what role you would like to assign for this person:
          </Text>
          <RadioInput
            title="No administration access"
            description="This person will be a standard user who cannot access the admin console but will be assigned one or more roles within teams in your organisation."
            onClick={() => {
              selectNoAdmin();
            }}
            showNote={false}
            noteText=""
            checked={true}
          />
          <RadioInput
            title="Super admin"
            description="This person has full authority for all functionality within Fusion. No further roles are necessary. You should assign at least two super admins in case you need to reset another super admin's account."
            onClick={() => {
              selectSuperAdmin();
            }}
            showNote={superAdminSelected}
            noteText="Super admin users have access to all teams and their content in Fusion by default"
          />
          <RadioInput
            title="Custom admin"
            description="This person has full authority over any areas they have been granted access to within the admin console. You can assign one or more of these roles."
            onClick={() => {
              selectCustomAdmin();
            }}
            showNote={customAdminSelected}
            noteText="Custom admin users can be added to teams below if needed but this is not a requirement"
          >
            <div className="pl-32 mt-24" hidden={!customAdminSelected}>
              <CheckboxInput
                title="Connection admin"
                description="Can create, view, edit and delete import and export connections."
                onClick={(event) => {
                  toggleAdminPermission(event.target.checked, connectionAdminRoleId);
                }}
              />
              <CheckboxInput
                title="Team & user admin"
                description="Can create, view, edit and delete teams and users, and assign roles to members."
                onClick={(event) => {
                  toggleAdminPermission(event.target.checked, teamAndUserAdminRoleId);
                }}
              />
              <CheckboxInput
                title="Reporting admin"
                description="Can create, view, edit and delete reporting dashboards and charts."
                onClick={(event) => {
                  toggleAdminPermission(event.target.checked, reportingAdminRoleId);
                }}
              />
            </div>
          </RadioInput>
        </>
      ),
      hidden: false,
    },
    {
      title: "Teams",
      description: "You can assign one or more teams to this person, and assign one or more roles to this person for each team also.",
      actions: teamAndUserActions,
      components: (
        <AddTeamsAndRoles
          teamsAndRoles={teamsAndRoles}
          setTeamsAndRoles={setTeamsAndRoles}
          numberAdded={superAdminSelected || customAdminSelected ? Object.keys(teamsAndRoles).length - 1 : Object.keys(teamsAndRoles).length}
        />
      ),
      hidden: superAdminSelected,
    },
  ];

  return (
    <>
      <SectionHeaderWithBackButton heading="Add new user" />
      <CO props="light-grey-bg container-full-height pt-32 pr-32 pl-32 pb-16">
        <RenderLayout sections={sections} />
        <R props={"pt-16 text-end"}>
          <C>
            <SubmitAndCancel
              disabled={!addUserEnabled()}
              submit={submitNewUser}
              submitText="Add user"
              modalHeading="Are you sure you want to cancel adding this user?"
              modalText="Your changes will not be saved and this action cannot be undone"
              redirect={goToManageUsers}
            />
          </C>
        </R>
      </CO>
    </>
  );
});

/***
 * This is a reusable section with props and renders children with the card
 */
const Section = ({ title, description, number, children, hidden }) => {
  return (
    <AddNewLayout title={title} description={description} number={number} hidden={hidden}>
      <Card spacers={["pt-32", "pr-32", "pb-32", "pl-32"]}>{children}</Card>
    </AddNewLayout>
  );
};

/**
 * This components controls the render of the sections within the page.
 * @returns sections user is authorized to view
 */
const RenderLayout = ({ sections }) => {
  return sections.map(({ title, description, actions, components, hidden }, index) => {
    return (
      <AuthWrapper allRequired={actions}>
        <Section title={title} description={description} number={(index += 1)} hidden={hidden}>
          {components}
        </Section>
      </AuthWrapper>
    );
  });
};

const RadioInput = ({ title, description, onClick, showNote, noteText, checked, children }) => {
  return (
    <div className="d-flex mt-24">
      <Radio onClick={onClick} checked={checked} />
      <div className="addNewLayout-description ml-8">
        <Text variant="secondary" size="sm" weight="medium" element="div">
          {title}
        </Text>
        <Text variant="tertiary" size="xs" weight="regular" element="div">
          {description}
        </Text>
        {children}
        <div hidden={!showNote}>
          <Note>{noteText}</Note>
        </div>
      </div>
    </div>
  );
};

const CheckboxInput = ({ title, description, onClick }) => {
  return (
    <div className="d-flex mt-8">
      <Checkbox onClick={onClick} spacers={["new-user-checkbox"]} />
      <div className="addNewLayout-description ml-8">
        <Text variant="secondary" size="sm" weight="medium" element="div">
          {title}
        </Text>
        <Text variant="tertiary" size="xs" weight="regular" element="div">
          {description}
        </Text>
      </div>
    </div>
  );
};

export { NewUser };
