import { users } from "./entities/users";
import { teams } from "./entities/teams";
import { roles } from "./entities/Roles";
import { exportConnections } from "./entities/ExportConnections";
import { importConnections } from "./entities/ImportConnections";
import { inputMappings } from "./entities/InputMappings";
import { exportMappings } from "./entities/ExportMappings";
import { tenantConfig } from "./entities/tenantConfig";
import { frontendProperties } from "./entities/FrontendProperties";

const fusionApi = {
  users,
  teams,
  roles,
  exportConnections,
  importConnections,
  inputMappings,
  exportMappings,
  tenantConfig,
  frontendProperties,
};

export { fusionApi };
