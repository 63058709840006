import { makeAutoObservable } from "mobx";
import { fusionApi } from "../api";
import { uiStore } from "./Store";

const { exportMappings } = fusionApi;

class ExportMappingsStore {
  exportMappings = [];

  constructor() {
    makeAutoObservable(this);
  }

  setExportMappings(value) {
    this.exportMappings = value;
  }

  getExportMappings() {
    return this.exportMappings;
  }

  getSftpMappings() {
    return this.exportMappings.filter((mapping) => mapping.type === "SFTP_FOLDER");
  }

  getEmailMappings() {
    return this.exportMappings.filter((mapping) => mapping.type === "EMAIL");
  }

  getExportMappingsCount() {
    return this.exportMappings.length;
  }

  getExportMappingById(exportMappingId) {
    return this.getExportMappings().find((mapping) => mapping.id == exportMappingId);
  }

  getNewestExportMapping = () => {
    return this.getExportMappings()
      .slice()
      .sort((a, b) => a.createdOn.localeCompare(b.createdOn))
      .slice(-1)[0];
  };

  getNewestSftpMapping = () => {
    return this.getSftpMappings()
      .slice()
      .sort((a, b) => a.createdOn.localeCompare(b.createdOn))
      .slice(-1)[0];
  };

  getNewestEmailMapping = () => {
    return this.getEmailMappings()
      .slice()
      .sort((a, b) => a.createdOn.localeCompare(b.createdOn))
      .slice(-1)[0];
  };

  requestExportMappings = async () => {
    const response = await exportMappings.requests.list();
    const { data } = response;
    return data;
  };

  replaceExportMapping = (exportConnection) => {
    let exportMappings = this.getExportMappings();
    const index = exportMappings.findIndex((index) => index.id === exportConnection.id);
    exportMappings[index] = exportConnection;
    this.setExportMappings(exportMappings);
  };

  /**
   * This method sends a request to the update mapping api. The request object is an existing
   * mapping object with updated fields.
   *
   * @param  toasts
   * The toasts param is what we will show if the request to update succeeds or fails.
   *
   * If the request is successful a toast message will appear and a true/false will
   * be returned.
   *
   * We return a true/false so we can continue operations in the app or stall them.
   * @returns true/false if request succeeded/failed
   */
  update = async (request, toasts) => {
    const { success, error } = toasts;
    return exportMappings.api
      .update(request)
      .then((response) => {
        const { status } = response;

        if (status === 200) {
          uiStore.addNotification("success", `${success}`);
          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `${error}`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        uiStore.addNotification("error", `${error}`);
      });
  };

  /**
   * This method is used to remove an import mapping that matches the id passed as param.
   * We filter the current array and returning mapping objects which don't match.
   *
   * These are then set as the mappings
   *
   * @param mappingId
   */
  removeExportMapping = (mappingId) => {
    const filteredMappings = this.exportMappings.filter((mapping) => mapping.id !== mappingId);
    this.setExportMappings(filteredMappings);
  };

  /**
   * This method sends a request to the delete mapping api. The param required is the mapping "id" which
   * we get from the request object.
   *
   * @param  request
   * The "name" of the mapping object is used for the toast messages.
   *
   * If the request is successful a toast message will appear and a true/false will
   * be returned.
   *
   * We return a true/false so we can continue operations in the app or stall them.
   * @returns true/false if request succeeded/failed
   */
  delete = async (request) => {
    const { id, name } = request;
    return exportMappings.api
      .delete(id)
      .then((response) => {
        const { status, errors } = response;
        if (status === 200) {
          uiStore.addNotification("success", `Mapping "${name}" successfully deleted!`);
          return { ...response, ...{ success: true } };
        } else {
          //TODO: Use errors in a meaningful way.
          uiStore.addNotification("error", `Unable to delete mapping "${name}"! Please try again`);
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        uiStore.addNotification("error", `Unable to delete mapping "${name}"! Please try again`);
      });
  };

  /**
   * This method adds a newly created input mapping object to the existing list.
   * It has one param which is a input mapping object.
   * @param exportMapping
   */
  addNewExportMapping = (exportMapping) => {
    let exportMappings = this.getExportMappings();
    exportMappings.push(exportMapping);
  };

  /**
   * This method sends a request to the create a new import mapping
   *
   * If the request is successful a toast message will appear and a true/false will
   * be returned.
   *
   * We return a true/false so we can continue operations in the app or stall them.
   *
   * The request param contains a new input mapping object
   * @param  request
   *
   * @returns true/false if request succeeded/failed
   */
  create = async (request) => {
    const { name } = request;
    return exportMappings.api
      .create(request)
      .then((response) => {
        const { status } = response;

        if (status === 201) {
          uiStore.addNotification("success", `Mapping ${name} successfully added`);

          return { ...response, ...{ success: true } };
        } else {
          uiStore.addNotification("error", `Unable to add mapping "${name} !" Please try again `);
          return { ...response, ...{ success: false } };
        }
      })
      .catch((error) => {
        uiStore.addNotification("error", `Unable to add mapping "${name} !" Please try again `);
      });
  };
}

export { ExportMappingsStore as ExportMappingsStore };
