import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { WarningConfirmationModal } from "@fundrecs/ui-library";
import { MODALS, CONNECTIONS, ROUTES } from "../../../../utils/Enums";
import { importSftpConnectionsStore, exportSftpConnectionsStore } from "../../../../store/Store";

const DeleteSftpConnectionModal = ({ connectionType, item }) => {
  const history = useHistory();
  const { name, connectionUuid } = item || {};

  const deleteConnection = async () => {
    const deleteConnection =
      connectionType === CONNECTIONS.IMPORT_SFTP_CONNECTION ? await importSftpConnectionsStore.delete(item) : await exportSftpConnectionsStore.delete(item);

    const { success } = deleteConnection;

    if (success) {
      // Update store and change url to select newest item
      if (connectionType === CONNECTIONS.IMPORT_SFTP_CONNECTION) {
        importSftpConnectionsStore.removeSftpConnection(connectionUuid);
        history.replace(`${ROUTES.IMPORT_SFTP}`);
      } else {
        exportSftpConnectionsStore.removeSftpConnection(connectionUuid);
        history.replace(`${ROUTES.EXPORT_SFTP}`);
      }
    }
  };

  return (
    <WarningConfirmationModal
      modalId={MODALS.DELETE_SFTP_CONNECTION}
      heading={`Are you sure you want to delete connection ${name} ?`}
      body={""}
      text="This may affect workflows if this is an active SFTP connection and this action cannot be undone."
      cancel="Cancel"
      confirm="Delete connection"
      onConfirm={() => deleteConnection()}
    />
  );
};

// Specifies the types for props:
DeleteSftpConnectionModal.propTypes = {
  connectionType: PropTypes.string,
  item: PropTypes.object,
};

// Specifies the default values for props:
DeleteSftpConnectionModal.defaultProps = {
  connectionType: "",
  item: {},
};

export { DeleteSftpConnectionModal };
