import React, { useState, useContext } from "react";
import { observer } from "mobx-react-lite";
import { Panel, PanelBody, PanelHeader, PanelFooter, Button, Text, ListItem, TeamItem, modalInstance } from "@fundrecs/ui-library";
import { usersStore, useStore } from "../../../store/Store";
import { AddMembersAndRoles } from "../components/AddMembersAndRoles";
import { TeamsContext } from "./ManageTeams";
import { MODALS } from "../../../utils/Enums";

const AddUserPanel = observer(({ teamAvatar, numberOfMembers, teamName, teamId }) => {
  const { selectedTeam, setSelectedTeam } = useContext(TeamsContext);

  const [usersAndRoles, setUsersAndRoles] = useState({});
  const { teamsStore } = useStore();

  const addUsersToTeam = async (teamId, usersAndRoles) => {
    if (Object.keys(usersAndRoles).length > 0) {
      const { success } = await teamsStore.addUsersToTeam({ teamId: teamId, usersAndRoles: usersAndRoles }).catch((error) => console.log(error));
      if (success) {
        const users = await usersStore.requestUsers();
        usersStore.setUsers(users);

        const team = await teamsStore.requestTeam(selectedTeam.id);
        teamsStore.replaceTeam(team);
        setSelectedTeam(team);
      }
    }
  };

  return (
    <>
      <Panel panelId={MODALS.ADD_USER}>
        <PanelBody>
          <PanelHeader
            description="Choose what people you'd like to add as members to this team. You can assign one or more roles to each member"
            header="Add members to team"
          />
          <div className="pt-12 pb-12">
            <ListItem>
              <TeamItem avatar={teamName} body={`${numberOfMembers} members`} header={teamName} />
            </ListItem>
          </div>

          <div className="pt-32">
            <AddMembersAndRoles usersAndRoles={usersAndRoles} setUsersAndRoles={setUsersAndRoles} />
          </div>
        </PanelBody>
        <PanelFooter>
          <Button
            color="tertiary"
            onClick={(event) => {
              modalInstance(MODALS.ADD_USER).toggle();
            }}
          >
            <Text size="sm">Cancel</Text>
          </Button>
          <Button
            onClick={(event) => {
              addUsersToTeam(teamId, usersAndRoles);
              modalInstance(MODALS.ADD_USER).toggle();
            }}
            disabled={!Object.entries(usersAndRoles)?.length > 0}
          >
            <Text size="sm">Add Members</Text>
          </Button>
        </PanelFooter>
      </Panel>
    </>
  );
});

export { AddUserPanel };
