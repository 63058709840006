import { fusionRequest } from "../fusionRequest";
import { API_ENDPOINTS } from "../config";

const EXPORT_CONNECTIONS_ENDPOINT = API_ENDPOINTS.CONNECTIONS.EXPORT;
const ALL_CONNECTIONS_ENDPOINT = API_ENDPOINTS.CONNECTIONS.ALL;

const requests = {
  create: (requestBody) => fusionRequest.post(`${EXPORT_CONNECTIONS_ENDPOINT}/sftp/`, requestBody),
  createEmail: (requestBody) => fusionRequest.post(`${EXPORT_CONNECTIONS_ENDPOINT}/email/`, requestBody),
  update: (requestBody) => fusionRequest.put(`${ALL_CONNECTIONS_ENDPOINT}/`, requestBody),
  delete: (uuid) => fusionRequest.delete(`${ALL_CONNECTIONS_ENDPOINT}/${uuid}`),
  list: () => fusionRequest.get(`${EXPORT_CONNECTIONS_ENDPOINT}/`),
  listByMethod: (method) => fusionRequest.get(`${EXPORT_CONNECTIONS_ENDPOINT}/${method}/`),
  countAll: () => fusionRequest.get(`${ALL_CONNECTIONS_ENDPOINT}/count`),
  countByMethod: (method) => fusionRequest.get(`${EXPORT_CONNECTIONS_ENDPOINT}/${method}/count`),
};

const api = {
  countAll: () => requests.countAll(),
  countSftp: () => requests.countByMethod("sftp"),
  countEmail: () => requests.countByMethod("email"),
  listSftp: () => requests.listByMethod("sftp"),
  listEmail: () => requests.listByMethod("email"),
  update: (requestBody) => requests.update(requestBody),
  create: (requestBody) => requests.create(requestBody),
  createEmail: (requestBody) => requests.createEmail(requestBody),
  delete: (uuid) => requests.delete(uuid),
};

const exportConnections = {
  requests,
  api,
};

export { exportConnections };
