import React, { useState } from "react";
import { useStore } from "../../../store/Store";
import { ROLES, ROLE_DESCRIPTIONS } from "../../../utils/Enums";
import {
  R,
  C,
  DropdownList,
  DropdownListItem,
  DropdownItem,
  IconDownArrow,
  IconInformation,
  Checkbox,
  Text,
  Dropdown,
  DropdownButton,
  DropdownButtonText,
  ItemBody,
  ToolTip,
  SaveEditable,
} from "@fundrecs/ui-library";
import { checkIfRoleLookupApprover, disableRole } from "./AddMembersAndRoles";

const EditRoles = ({ userRoles, setEditingRolesUserId, onSave }) => {
  /**
   * Used to generate a list of the selected roles from an array of role ids
   * @param {*} selectedRoles Array of Role IDs
   * @returns
   */
  const generateDisplayText = (selectedRoles) => {
    let text = "";
    if (selectedRoles.length) {
      selectedRoles.forEach((roleId) => {
        text += `${rolesStore.findRole(rolesStore.getRoles(), roleId)}, `;
      });
      //Remove final comma and space from the string
      text = text.slice(0, text.length - 2);
    }
    return text;
  };

  const { rolesStore } = useStore();
  const currentUserRoles = userRoles.map((role) => role.id);
  const [selectedRoleIds, setSelectedRoleIds] = useState(currentUserRoles);
  const [displayText, setDisplayText] = useState(generateDisplayText(currentUserRoles));

  /**
   * Called when a role checkbox is clicked, if selected, add to selectedRoleIds, otherwise remove
   * @param {} roleId
   */
  const toggleRole = (event, roleId) => {
    event.stopPropagation();
    if (selectedRoleIds.includes(roleId)) {
      selectedRoleIds.splice(selectedRoleIds.indexOf(roleId), 1);
    } else {
      selectedRoleIds.push(roleId);
      //Check if role is Lookup Approver and add addional role
      checkIfRoleLookupApprover(roleId, rolesStore, selectedRoleIds);
    }
    setDisplayText(generateDisplayText(selectedRoleIds));
    setSelectedRoleIds(selectedRoleIds);
  };

  return (
    <>
      <R>
        <C props="d-flex pr-0 pl-0">
          <Dropdown spacers={["mr-12"]}>
            <DropdownButton warning={false} disabled={false} size={"md"}>
              <DropdownButtonText>
                <ItemBody>
                  <>{displayText}</>
                </ItemBody>
                <IconDownArrow className={`btn-${"md"}-svg`} />
              </DropdownButtonText>
            </DropdownButton>

            <DropdownList>
              <DefaultRoleForDropdown />

              {rolesStore.getAssignableNonAdminRoles().map((role, index) => {
                return (
                  <DropdownListItem key={Math.random()} onClick={() => {}}>
                    <DropdownItem active={false} index={index}>
                      <Checkbox
                        spacers={["mr-12", "mt-0"]}
                        onClick={(event) => {
                          toggleRole(event, role.id);
                        }}
                        checked={selectedRoleIds.some((selectedId) => selectedId === role.id)}
                        disabled={disableRole(role.name, role.id, rolesStore, selectedRoleIds)}
                      />
                      <ItemBody>{role.name}</ItemBody>
                      <ToolTip text={ROLE_DESCRIPTIONS[role.name]}>
                        <IconInformation className="light-text-muted icon-size" />
                      </ToolTip>
                    </DropdownItem>
                  </DropdownListItem>
                );
              })}
            </DropdownList>
          </Dropdown>
          <SaveEditable
            onClickSave={() => {
              setEditingRolesUserId();
              onSave(selectedRoleIds);
            }}
            onClickCancel={() => {
              setEditingRolesUserId();
            }}
          />
        </C>
      </R>
    </>
  );
};

const DefaultRoleForDropdown = () => {
  return (
    <>
      <DropdownListItem className="pl-16 pr-16">
        <Text variant="primary" size="sm" weight="medium" element="div">
          Default role
        </Text>
        <Text variant="tertiary" size="xs" weight="regular" element="div">
          All members are assigned as viewers by default, so you don't have to do anything here!
        </Text>
      </DropdownListItem>
      <DropdownListItem className="pb-32">
        <DropdownItem active={-1}>
          <Checkbox checked={true} spacers={["mr-12", "mt-0"]} disabled={true} />
          <ItemBody>{ROLES.VIEWER}</ItemBody>
          <ToolTip text={ROLE_DESCRIPTIONS.VIEWER}>
            <IconInformation className="light-text-muted icon-size" />
          </ToolTip>
        </DropdownItem>
      </DropdownListItem>
      <DropdownListItem className="pl-16 pr-16">
        <Text variant="primary" size="sm" weight="medium" element="div">
          Additional roles
        </Text>
        <Text variant="tertiary" size="xs" weight="regular" element="div">
          You can assign any or all of the following roles to this member
        </Text>
      </DropdownListItem>
    </>
  );
};

export default EditRoles;
