const VALIDATION_MESSAGES = Object.freeze({
  REQUIRED_FIELD: "Cannot be empty or blank",
  TEAM_NAME_ALREDY_IN_USE: "This team name is already in use",
  EMAIL_INVALID: "Please enter your mail address in format yourname@company.com",
});

/**
 * isNullOrEmpty
 * Returns if a string or object is null or empty
 *
 * Note: This validation uses Regular Expression / RegEx
 * For more explanation about the pattern see https://regexr.com/
 *
 * @param {string} value - value to validate if is valid email address
 *
 * @returns {boolean} is value null or empty
 */
const isNullOrEmpty = (value) => {
  return value === null || value === undefined || value.match(/^ *$/) !== null ? true : false;
};

/**
 * isEmailValid
 * Returns if a string is a valid email address
 *
 * Note: This validation uses Regular Expression / RegEx
 * For more explanation about the pattern see https://regexr.com/
 *
 * @param {string} value - value to validate if is valid email address
 *
 * @returns {boolean} is email valid
 */
const isEmailValid = (value) => {
  if (isNullOrEmpty(value)) return false;
  return value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/) !== null ? true : false;
};

export { VALIDATION_MESSAGES, isNullOrEmpty, isEmailValid };
