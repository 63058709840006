import React, { useReducer } from "react";
import { useHistory } from "react-router-dom";
import { Note, Button, Text, Panel, PanelBody, PanelFooter, PanelHeader, modalInstance, InputLabelWithToolTip } from "@fundrecs/ui-library";
import { FormInput } from "../../components/InputComponents/FormComponents";
import { exportEmailConnectionsStore, importEmailConnectionsStore } from "../../../../store/Store";
import { isNullOrEmpty, isEmailValid, VALIDATION_MESSAGES } from "../../util/validation";
import { CONNECTIONS, MODALS, ROUTES } from "../../../../utils/Enums";
import { getCreateFormContent } from "../utils/formContent";

// maps fields to their related error strings returned by the API
const fieldValidations = {
  name: "connection",
  emailAddress: "Email Address",
};

const formActions = {
  UPDATE: "update",
  RESET: "reset",
  ERROR: "error",
};

const initialFormState = {
  errors: [],
  values: {
    type: "",
    name: "",
    emailAddress: "",
  },
};

const reducer = (state, { type, data }) => {
  switch (type) {
    case formActions.UPDATE:
      return {
        ...state,
        values: { ...state.values, [data.fieldKey]: data.value },
        errors: state.errors.filter((err) => err !== fieldValidations[data.fieldKey]),
      };

    case formActions.ERROR:
      return {
        ...state,
        errors: Object.values(fieldValidations).filter((value) => data.includes(value)),
      };

    case formActions.RESET:
      return initialFormState;

    default:
      throw new Error();
  }
};

const CreateEmailModal = ({ connectionType }) => {
  const history = useHistory();
  const [formState, dispatch] = useReducer(reducer, initialFormState);
  const formContent = getCreateFormContent(connectionType);

  /**
   * This method is called everytime a changes a value in an input.
   * The key param represents the key the backend expects in the connection object.
   * The value is whatever the user types within the input.
   *
   * We take a copy of the current connection object and then combine it with
   * the newly updated values.
   *
   * We then set this as the current connection object that we are creating.
   * @param fieldKey
   * @param value
   */
  const handleInputChange = (fieldKey, value) => {
    dispatch({
      type: formActions.UPDATE,
      data: { fieldKey, value },
    });
  };

  const handleCancel = () => {
    dispatch({ type: formActions.RESET });
    modalInstance(MODALS.NEW_EMAIL).hide();
  };

  const handleSuccess = () => {
    dispatch({ type: formActions.RESET });
    modalInstance(MODALS.NEW_EMAIL).hide();
  };

  const handleError = (error) => {
    dispatch({
      type: formActions.ERROR,
      data: error.toLowerCase(),
    });
  };

  const checkError = (value) => formState.errors.includes(value);

  const createEmail = async () => {
    const emailObject = formState.values;
    // Set type based on connectionType
    if (connectionType === CONNECTIONS.IMPORT_EMAIL_MAPPING) {
      emailObject.type = "ImportEmailConnection";
    } else {
      emailObject.type = "ExportEmailConnection";
    }

    const newEmail =
      connectionType === CONNECTIONS.IMPORT_EMAIL_MAPPING
        ? await importEmailConnectionsStore.create(emailObject)
        : await exportEmailConnectionsStore.create(emailObject);

    const { success, data } = newEmail;

    if (success) {
      handleSuccess();

      // Update store and change url to select created item
      if (connectionType === CONNECTIONS.IMPORT_EMAIL_MAPPING) {
        importEmailConnectionsStore.addNewEmailConnection(data);
        history.replace(`${ROUTES.IMPORT_EMAIL_MAPPINGS}/${data.id}`);
      } else {
        exportEmailConnectionsStore.addNewEmailConnection(data);
        history.replace(`${ROUTES.EXPORT_EMAIL}/${data.id}`);
      }
    } else {
      handleError(data);
    }
  };

  const createEmailEnabled = () => {
    if (connectionType === CONNECTIONS.EXPORT_EMAIL_CONNECTION) {
      return !isNullOrEmpty(formState.values.name) && !isNullOrEmpty(formState.values.emailAddress) && isEmailValid(formState.values.emailAddress);
    } else {
      return !isNullOrEmpty(formState.values.name);
    }
  };

  return (
    <>
      <Panel panelId={MODALS.NEW_EMAIL}>
        <PanelBody>
          <PanelHeader header={formContent.HEADER_LABEL} description={formContent.HEADER_DESCRIPTION} />
          <div className="pt-12 pb-12">
            <InputLabelWithToolTip label={formContent.NAME_LABEL} tooltipText={formContent.NAME_DESCRIPTION} />
            <div className="pt-8">
              <FormInput
                fieldKey={"name"}
                handleChange={handleInputChange}
                formField={"connection"}
                warningMessage="Valid connection name has to be provided"
                warning={checkError(fieldValidations.connection)}
                value={formState.values.name}
              />
            </div>
          </div>
          <div className="pt-12 pb-32">
            {connectionType === CONNECTIONS.EXPORT_EMAIL_CONNECTION && (
              <>
                <InputLabelWithToolTip label={formContent.EMAIL_LABEL} tooltipText={formContent.EMAIL_DESCRIPTION} />
                <div className="pt-8">
                  <FormInput
                    fieldKey={"emailAddress"}
                    handleChange={handleInputChange}
                    formField={"email"}
                    type="email"
                    warningMessage={VALIDATION_MESSAGES.EMAIL_INVALID}
                    warning={
                      checkError(fieldValidations.email) || (!isEmailValid(formState.values.emailAddress) && !isNullOrEmpty(formState.values.emailAddress))
                    }
                    value={formState.values.emailAddress}
                  />
                </div>
              </>
            )}
            {connectionType === CONNECTIONS.IMPORT_EMAIL_MAPPING && <Note>Fusion will automatically generate an email address for you</Note>}
          </div>
        </PanelBody>

        <PanelFooter>
          <Button color="tertiary" onClick={handleCancel}>
            <Text size="sm">Cancel</Text>
          </Button>
          <Button
            disabled={!createEmailEnabled()}
            onClick={(event) => {
              createEmail();
            }}
          >
            <Text size="sm">Add email address</Text>
          </Button>
        </PanelFooter>
      </Panel>
    </>
  );
};

export { CreateEmailModal };
