import React, { createContext, useContext } from "react";
import { MeStore } from "./MeStore";
import { RolesStore } from "./RolesStore";
import { UsersStore } from "./UsersStore";
import { TeamsStore } from "./TeamsStore";
import { ImportSftpConnectionsStore } from "./ImportSftpConnectionsStore";
import { ImportEmailConnectionsStore } from "./ImportEmailConnectionsStore";
import { InputMappingsStore } from "./InputMappingsStore";
import { ExportMappingsStore } from "./ExportMappingStore";
import { ExportSftpConnectionsStore } from "./ExportSftpConnectionsStore";
import { ExportEmailConnectionsStore } from "./ExportEmailConnectionsStore";
import { TenantConfigStore } from "./TenantConfigStore";
import { FrontendPropertiesStore } from "./FrontendPropertiesStore";
import { UIStore } from "./UIStore";

const StoreContext = createContext();

const useStore = () => {
  const context = useContext(StoreContext);

  if (context === undefined) {
    throw new Error("useStore must be used within StoresProvider");
  }

  return context;
};

//Create Me Store
const meStore = new MeStore();

//Create Roles Store
const rolesStore = new RolesStore();

//Create Teams Store
const teamsStore = new TeamsStore();

//Create Users Store
const usersStore = new UsersStore();

//Create Import Connections Store
const importSftpConnectionsStore = new ImportSftpConnectionsStore();

//Create Import Connections Store
const importEmailConnectionsStore = new ImportEmailConnectionsStore();

//Create Input Mappings Store
const inputMappingsStore = new InputMappingsStore();

//Create Input Mappings Store
const exportMappingsStore = new ExportMappingsStore();

//Create Export SFTP Connections Store
const exportSftpConnectionsStore = new ExportSftpConnectionsStore();

//Create Export Email Connections Store
const exportEmailConnectionsStore = new ExportEmailConnectionsStore();

const uiStore = new UIStore();

const tenantConfigStore = new TenantConfigStore();

const frontendPropertiesStore = new FrontendPropertiesStore();

const storeContext = {
  meStore,
  rolesStore,
  teamsStore,
  usersStore,
  importSftpConnectionsStore,
  importEmailConnectionsStore,
  inputMappingsStore,
  exportMappingsStore,
  exportSftpConnectionsStore,
  exportEmailConnectionsStore,
  uiStore,
  tenantConfigStore,
  frontendPropertiesStore,
};

const StoreProvider = ({ children }) => <StoreContext.Provider value={storeContext}>{children}</StoreContext.Provider>;

export {
  StoreProvider,
  useStore,
  meStore,
  rolesStore,
  teamsStore,
  usersStore,
  importSftpConnectionsStore,
  importEmailConnectionsStore,
  inputMappingsStore,
  exportMappingsStore,
  exportSftpConnectionsStore,
  exportEmailConnectionsStore,
  uiStore,
  tenantConfigStore,
  frontendPropertiesStore,
};
