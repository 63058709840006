import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Dropdown,
  DropdownListItem,
  DropdownItem,
  DropdownButton,
  DropdownButtonText,
  DropdownList,
  ItemBody,
  LinkText,
  C4,
  C6,
  Text,
  IconDownArrow,
  C,
  SaveEditable,
} from "@fundrecs/ui-library";

const EditableSelect = observer(({ value: originalValue, label, options, onSave, description = "" }) => {
  const [editing, setEditing] = useState(false);

  const [value, setValue] = useState(originalValue.value);
  const selectedOption = options.find((option) => option.value === value) || { label: "Please select an option" };

  return (
    <>
      <C4>
        <Text variant="tertiary" size="sm" weight="regular" element="div">
          {label}
        </Text>
      </C4>

      {editing ? (
        <>
          <C6>
            <Dropdown>
              <DropdownButton size="sm">
                <DropdownButtonText>
                  <ItemBody>
                    <span>{selectedOption.label}</span>
                  </ItemBody>
                  <IconDownArrow className="btn-sm-svg" />
                </DropdownButtonText>
              </DropdownButton>
              <DropdownList>
                {options.map(({ value, label }, index) => (
                  <DropdownListItem
                    key={index}
                    onClick={() => {
                      setValue(value);
                    }}
                  >
                    <DropdownItem index={0}>
                      <ItemBody>{label}</ItemBody>
                    </DropdownItem>
                  </DropdownListItem>
                ))}
              </DropdownList>
            </Dropdown>
          </C6>
          <C props="pt-4 pb-4">
            <SaveEditable
              onClickSave={() => {
                if (onSave) {
                  onSave(value);
                }
                setEditing(false);
              }}
              onClickCancel={() => {
                setEditing(false);
                setValue(originalValue.value);
              }}
            />
          </C>
        </>
      ) : (
        <>
          <C6>
            <Text variant="primary" size="sm" weight="medium" element="div">
              {originalValue.label}
            </Text>
            {description ? (
              <>
                <div className="pb-4"></div>
                <Text variant="tertiary" size="xs" weight="regular" element="div">
                  {description}
                </Text>
              </>
            ) : (
              ""
            )}
          </C6>
          <C>
            <span
              className="d-flex justify-content-end"
              onClick={() => {
                setEditing(true);
              }}
            >
              <LinkText>change</LinkText>
            </span>
          </C>
        </>
      )}
    </>
  );
});

export { EditableSelect };
