import { makeAutoObservable, toJS } from "mobx";
import { fusionApi } from "../api";
import { uiStore } from "../store/Store";

const { users } = fusionApi;

class MeStore {
  me = "";
  adminRoles = [];
  adminActions = [];
  roles = [];
  actions = [];

  constructor() {
    makeAutoObservable(this);
  }

  setMe = (data) => {
    this.me = data;
    if (!this.me?.adminRoleActions) {
      this.me.adminRoleActions = { roles: [], actions: [] };
    }
    this.adminRoles = data.adminRolesActions?.roles ?? [];
    this.adminActions = data.adminRolesActions?.actions ?? [];
    this.roles = data.teamsRolesActions?.roles ?? [];
    this.actions = data.teamsRolesActions?.actions ?? [];
  };

  getAllActions() {
    return toJS(this.adminActions.concat(this.actions));
  }

  getAllRoles() {
    return toJS(this.adminRoles.concat(this.roles));
  }

  isSuperAdmin() {
    return this.me.superAdmin;
  }

  ping = () => {
    console.log("You have pinged the user store");
  };

  getMe = () => {
    return toJS(this.me);
  };

  getName = () => {
    const { name } = this.me;
    return name;
  };

  getEmail = () => {
    const { email } = this.me;
    return email;
  };

  getId = () => {
    const { id } = this.me;
    return id;
  };

  requestMe = async () => {
    const { data, status } = await users.api.me();
    if (status === 200) {
      this.setMe(data);

      return true;
    }
    return false;
  };

  /**
   * This method sends a request to update a teams details
   *
   * If the request is successful a toast message will appear and a true/false will
   * be returned.
   *
   * We return a true/false so we can continue operations in the app or stall them.
   *
   * Request object contains updated team details
   * @param  request
   *
   * toasts param are the for the success/error messages to show for the form field being updated.
   * You can only edit/updated one form field at a time so we need to track this and display relevant
   * toast messages.
   * @param  toasts
   *
   * @returns true/false if request succeeded/failed
   */
  update = async (request, toasts) => {
    const { success, error } = toasts;
    const response = await fusionApi.users.api.updateMe(request).catch((error) => {
      uiStore.addNotification("error", `${error}`);
      return { data: "", response: false };
    });

    if (response.status && response.status === 200) {
      uiStore.addNotification("success", `${success}`);
      return { ...response, ...{ success: true } };
    } else {
      uiStore.addNotification("error", `${error}`);
      return { ...response, ...{ success: false } };
    }
  };
}

export { MeStore };
