import React, { useReducer, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Text, Panel, PanelBody, PanelFooter, PanelHeader, modalInstance, InputLabelWithToolTip } from "@fundrecs/ui-library";
import { FormInput } from "../../components/InputComponents/FormComponents";
import { AuthenticationInput } from "../AuthenticationInput/AuthenticationInput";
import { importSftpConnectionsStore, exportSftpConnectionsStore } from "../../../../store/Store";
import { CONNECTIONS, MODALS, ROUTES } from "../../../../utils/Enums";
import { isNullOrEmpty } from "../../util/validation";
import { getCreateFormContent } from "../utils/formContent";

// maps fields to their related error strings returned by the API
const fieldValidations = {
  name: "connection",
  sourceFolder: "Source Folder",
  endpoint: "endpoint",
  port: "port",
};

const formActions = {
  UPDATE: "update",
  RESET: "reset",
  ERROR: "error",
};

const initialFormState = {
  errors: [],
  values: {
    type: null,
    name: "",
    sourceFolder: "",
    endpoint: "",
    port: "",
    privateKeyFile: null,
    userName: null,
    password: null,
  },
};

const reducer = (state, { type, data }) => {
  switch (type) {
    case formActions.UPDATE:
      return {
        ...state,
        values: { ...state.values, [data.fieldKey]: data.value },
        errors: state.errors.filter((err) => err !== fieldValidations[data.fieldKey]),
      };

    case formActions.ERROR:
      return {
        ...state,
        errors: Object.values(fieldValidations).filter((value) => data.includes(value)),
      };

    case formActions.RESET:
      return initialFormState;

    default:
      throw new Error();
  }
};

const CreateSftpConnectionModal = ({ connectionType }) => {
  const history = useHistory();
  const [resetAuthenticationForm, setResetAuthenticationForm] = useState(false);
  const [formState, dispatch] = useReducer(reducer, initialFormState);
  const formContent = getCreateFormContent(connectionType);

  /**
   * This method is called everytime a changes a value in an input.
   * The key param represents the key the backend expects in the connection object.
   * The value is whatever the user types within the input.
   *
   * We take a copy of the current connection object and then combine it with
   * the newly updated values.
   *
   * We then set this as the current connection object that we are creating.
   * @param fieldKey
   * @param value
   */
  const handleInputChange = (fieldKey, value) => {
    dispatch({
      type: formActions.UPDATE,
      data: { fieldKey, value },
    });
  };

  const handleCancel = () => {
    dispatch({ type: formActions.RESET });
    setResetAuthenticationForm(true);
    modalInstance(MODALS.NEW_SFTP_CONNECTION).hide();
  };

  const handleSuccess = () => {
    dispatch({ type: formActions.RESET });
    modalInstance(MODALS.NEW_SFTP_CONNECTION).hide();
  };

  const handleError = (error) => {
    dispatch({
      type: formActions.ERROR,
      data: error.toLowerCase(),
    });
  };

  const checkError = (value) => formState.errors.includes(value);

  const createConnection = async () => {
    const newConnection =
      connectionType === CONNECTIONS.IMPORT_SFTP_CONNECTION
        ? await importSftpConnectionsStore.create(formState.values)
        : await exportSftpConnectionsStore.create(formState.values);

    const { success, data } = newConnection;
    if (success) {
      handleSuccess();

      // Force authentication component to re-render
      setResetAuthenticationForm(data.id);

      // Update store and change url to select created item
      if (connectionType === CONNECTIONS.IMPORT_SFTP_CONNECTION) {
        importSftpConnectionsStore.addNewSftpConnection(data);
        history.replace(`${ROUTES.IMPORT_SFTP}/${data.id}`);
      } else {
        exportSftpConnectionsStore.addNewSftpConnection(data);
        history.replace(`${ROUTES.EXPORT_SFTP}/${data.id}`);
      }
    } else {
      handleError(data);
    }
  };

  const createSftpEnabled = () => {
    return (
      !isNullOrEmpty(formState.values.name) &&
      !isNullOrEmpty(formState.values.sourceFolder) &&
      !isNullOrEmpty(formState.values.endpoint) &&
      !isNullOrEmpty(formState.values.port) &&
      !isNullOrEmpty(formState.values.userName) &&
      (!isNullOrEmpty(formState.values.password) || !isNullOrEmpty(formState.values.privateKeyFile))
    );
  };

  return (
    <>
      <Panel panelId={MODALS.NEW_SFTP_CONNECTION}>
        <PanelBody>
          <PanelHeader header={formContent.HEADER_LABEL} description={formContent.HEADER_DESCRIPTION} />

          <div className="pt-12 pb-12">
            <InputLabelWithToolTip label={formContent.NAME_LABEL} tooltipText={formContent.NAME_DESCRIPTION} />
            <div className="pt-8">
              <FormInput
                fieldKey={"name"}
                handleChange={handleInputChange}
                formField={"connection"}
                warningMessage="Valid connection name has to be provided"
                warning={checkError(fieldValidations.name)}
                value={formState.values.name}
              />
            </div>
          </div>
          <div className="pt-12 pb-12">
            <InputLabelWithToolTip label={formContent.SOURCE_FOLDER_LABEL} tooltipText={formContent.SOURCE_FOLDER_DESCRIPTION} />
            <div className="pt-8">
              <FormInput
                fieldKey={"sourceFolder"}
                handleChange={handleInputChange}
                formField={"Source Folder"}
                warningMessage="Valid Source Folder has to be provided"
                warning={checkError(fieldValidations.sourceFolder)}
                value={formState.values.sourceFolder}
              />
            </div>
          </div>

          <div className="pt-12 pb-12">
            <InputLabelWithToolTip label={formContent.ENDPOINT_LABEL} tooltipText={formContent.ENDPOINT_DESCRIPTION} />
            <div className="pt-8">
              <FormInput
                fieldKey={"endpoint"}
                handleChange={handleInputChange}
                formField={"endpoint"}
                warningMessage="Valid endpoint has to be provided"
                warning={checkError(fieldValidations.endpoint)}
                value={formState.values.endpoint}
              />
            </div>
          </div>
          <div className="pt-12 pb-12">
            <InputLabelWithToolTip label={formContent.PORT_LABEL} tooltipText={formContent.PORT_DESCRIPTION} />
            <div className="pt-8">
              <FormInput
                fieldKey="port"
                handleChange={handleInputChange}
                formField={"port"}
                type="number"
                warningMessage="Valid port number has to be provided"
                warning={checkError(fieldValidations.port)}
                value={formState.values.port}
              />
            </div>
          </div>
          <div className="pt-12 pb-12">
            <InputLabelWithToolTip label={formContent.AUTHENTICATION_LABEL} tooltipText={formContent.AUTHENTICATION_DESCRIPTION} />
            <div className="pt-8">
              <AuthenticationInput
                key={resetAuthenticationForm}
                userName={formState.values.userName}
                password={formState.values.password}
                privateKeyFile={formState.values.privateKeyFile}
                onChange={(key, value) => {
                  handleInputChange(key, value);
                }}
                edit={null}
              />
            </div>
          </div>
        </PanelBody>

        <PanelFooter>
          <Button color="tertiary" onClick={handleCancel}>
            <Text size="sm">Cancel</Text>
          </Button>

          <Button
            onClick={() => {
              createConnection();
            }}
            disabled={!createSftpEnabled()}
          >
            <Text size="sm">Add SFTP connection</Text>
          </Button>
        </PanelFooter>
      </Panel>
    </>
  );
};

export { CreateSftpConnectionModal };
