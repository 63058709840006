export const API_BASE_URL = "/api/v2/";

// in production this should be much smaller
export const API_REQUEST_TIMEOUT_MS = 150000;

export const API_ENDPOINTS = {
  ACTIONS: "actions",
  CONNECTIONS: {
    ALL: "connections",
    EXPORT: "connections/export",
    IMPORT: "connections/import",
    KEYS: "connections/keys",
  },
  IMPORT_MAPPING_RULES: "importMappingRules",
  INPUT_MAPPINGS: "import-mappings",
  EXPORT_MAPPINGS: "export-mappings",
  ROLES: "roles",
  TEAMS: "teams",
  TEMPLATES: "templates",
  USERS: "users",
  TENANT_CONFIG: {
    GET_TENANT_CONFIG: "tenant",
  },
  FRONTEND_PROPERTIES: {
    GET_FRONTEND_PROPERTIES: "frontend-properties",
  },
};
