import React, { useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { R, C, ListItems, List, Text } from "@fundrecs/ui-library";
import { importEmailConnectionsStore } from "../../../../../store/Store";
import { ImportSetupContext } from "../ImportSetup";
import { ROUTES } from "../../../../../utils/Enums";
import { ListItemsHeader, ListItemsItem } from "../../../components/Layout";
import { EmailMappingHeader } from "./EmailMappingHeader";
import { EmailMapping } from "./EmailMapping";
import { NoEmailMappings } from "./NoEmailMappings";

const EmailMappingsList = () => {
  let { id } = useParams();
  const history = useHistory();
  const { selectedEmailConnection, setSelectedEmailConnection, setSelectedTabIndex } = useContext(ImportSetupContext);

  useEffect(() => {
    setSelectedTabIndex(2);

    const fetchData = async () => {
      return await importEmailConnectionsStore.requestEmailConnections();
    };

    fetchData().then((emailconnections) => {
      importEmailConnectionsStore.setEmailConnections(emailconnections);

      if (!selectedEmailConnection) {
        selectDefaultItem(id);
      }
    });
  }, []);

  useEffect(() => {
    selectDefaultItem(id);
  }, [id]);

  const selectDefaultItem = (id) => {
    const itemById = importEmailConnectionsStore.getEmailConnectionById(id);
    if (id && itemById) {
      setSelectedEmailConnection(itemById);
    } else {
      const newestItem = importEmailConnectionsStore.getNewestEmailConnection();
      if (newestItem) {
        history.replace(`${ROUTES.IMPORT_EMAIL_MAPPINGS}/${newestItem.id}`);
      } else {
        setSelectedEmailConnection();
      }
    }
  };

  const handleSelectConnection = (connection) => {
    setSelectedEmailConnection(connection);
    history.push(`${ROUTES.IMPORT_EMAIL_MAPPINGS}/${connection.id}`);
  };

  return (
    <>
      {selectedEmailConnection ? (
        <R>
          <List>
            <ListItemsHeader text="All email addresses (newest-oldest)" />
            <R>
              <ListItems>
                {importEmailConnectionsStore
                  .getEmailConnections()
                  .slice()
                  .sort((a, b) => a.createdOn.localeCompare(b.createdOn))
                  .reverse()
                  .map((row, index) => {
                    return (
                      <ListItemsItem
                        name={row.name}
                        description={
                          <Text element="div" size="xs" variant="tertiary">
                            {row.emailAddress}
                          </Text>
                        }
                        isActive={row.id.toString() === id ? 0 : 1}
                        onClick={() => handleSelectConnection(row)}
                        key={index}
                      />
                    );
                  })}
              </ListItems>
            </R>
          </List>
          <C props="section-border-left pr-0 pl-0">
            <R props="pl-32 pt-24">{<EmailMappingHeader />}</R>
            <R props="pl-32 section-border-bottom">
              <C props="pl-0 pr-0">
                <C props="pb-24" />
              </C>
            </R>
            <div className="light-grey-bg section-border-left " style={{ height: "100%" }}>
              <R props={"pl-32 pr-32 pb-32"}>
                <EmailMapping />
              </R>
            </div>
          </C>
        </R>
      ) : (
        <NoEmailMappings />
      )}
    </>
  );
};
export { EmailMappingsList };
