import React, { useState, useEffect } from "react";

import { useLocation, useHistory, Link } from "react-router-dom";
import Intercom from "react-intercom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../store/Store";
import { AuthWrapper } from "../AuthorizationWrapper";
import { AUTHORITIES, ROLES, ROUTES, ROUTES_NAMES } from "../../utils/Enums";
import {
  SideNavBar,
  SideNavHeading,
  SideNavColumn,
  ToolTip,
  OneProductDashboardAdminIcon,
  OneProductSideNavButton,
  OneProductTeamsAdminIcon,
  OneProductUsersAdminIcon,
  OneProductSourcesAdminIcon,
  OneProductDestinationsAdminIcon,
  OneProductLaunchAdminIcon,
  OneProductHelpAdminIcon,
  OneProductReportingDataIcon,
  HyperLink,
} from "@fundrecs/ui-library";
import styles from "./Sidebar.module.scss";

const Sidebar = observer(() => {
  const {
    uiStore: { sideMenuOpen },
    rolesStore,
    tenantConfigStore,
    meStore,
  } = useStore();

  const history = useHistory();
  const location = useLocation();
  const { pathname } = { ...location };

  const [active, setActive] = useState("");

  const fusionDataUrl = tenantConfigStore.getFusionDataUrl();
  const fusionReportingUrl = tenantConfigStore.getFusionReportingUrl();

  const userInfo = {
    email: meStore.getEmail(),
    name: meStore.getName(),
  };

  useEffect(() => {
    // Get first portion of url as app appends id in some cases with history.replace
    const initialPath = `/${pathname.split("/")[1]}`;

    //Update the active sidebar menu button to match the url pathname
    const routeKey = Object.keys(ROUTES).find((key) => ROUTES[key] === initialPath) || "DASHBOARD";
    setActive(ROUTES_NAMES[routeKey]);
  }, [pathname]);

  const renderSupport = () => {
    let localENV = window.location.hostname === "fundrecs-sso.fusion-admin.localhost" || window.location.hostname.indexOf("fundrecsdev.com") > -1;

    if (!localENV) {
      return <Intercom appID="2a73body" {...userInfo} />;
    }
  };

  const launchIntercom = () => {
    let localENV = window.location.hostname === "fundrecs-sso.fusion-admin.localhost" || window.location.hostname.indexOf("fundrecsdev.com") > -1;

    if (localENV) {
      let emailTo = "mailto:2a73body@incoming.intercom.io";
      window.open("mailto:" + emailTo);
    } else {
      window.Intercom("show", {
        hideDefaultLauncher: true,
      });
    }
  };

  return (
    <>
      <SideNavBar visible={sideMenuOpen} styles={{ height: "100vh" }}>
        <AuthWrapper allRequired={rolesStore.getActions([AUTHORITIES.DASHBOARD_VIEW])}>
          <SideNavColumn>
            <Link className={styles.sideBarLink} to={ROUTES.DASHBOARD}>
              <OneProductSideNavButton
                ButtonIcon={
                  sideMenuOpen ? (
                    <OneProductDashboardAdminIcon />
                  ) : (
                    <ToolTip text="Dashboard" direction="right" size="medium">
                      <OneProductDashboardAdminIcon />
                    </ToolTip>
                  )
                }
                minimised={!sideMenuOpen}
                active={active}
                buttonName={ROUTES_NAMES.DASHBOARD}
                onClick={() => {
                  setActive(ROUTES_NAMES.DASHBOARD);
                }}
                disabled={false}
              />
            </Link>
            <HyperLink href={fusionReportingUrl} target="_blank">
              <OneProductSideNavButton
                minimised={!sideMenuOpen}
                active={active}
                buttonName={ROUTES_NAMES.REPORTING}
                ButtonIcon={
                  sideMenuOpen ? (
                    <OneProductReportingDataIcon />
                  ) : (
                    <ToolTip text={ROUTES_NAMES.REPORTING} direction="right" size="medium">
                      <OneProductReportingDataIcon />
                    </ToolTip>
                  )
                }
                onClick={() => {}}
              />
            </HyperLink>
          </SideNavColumn>
        </AuthWrapper>

        <AuthWrapper allRequired={rolesStore.getActionsByRoleName([ROLES.TEAM_USER_ADMIN])}>
          {sideMenuOpen && <SideNavHeading HeadingText="Teams &amp; users" />}
          <SideNavColumn>
            <Link className={styles.sideBarLink} to={ROUTES.TEAMS}>
              <OneProductSideNavButton
                ButtonIcon={
                  sideMenuOpen ? (
                    <OneProductTeamsAdminIcon />
                  ) : (
                    <ToolTip text="Teams" direction="right" size="medium">
                      <OneProductTeamsAdminIcon />
                    </ToolTip>
                  )
                }
                minimised={!sideMenuOpen}
                active={active}
                buttonName={ROUTES_NAMES.TEAMS}
                onClick={() => {
                  setActive(ROUTES_NAMES.TEAMS);
                }}
              />
            </Link>
            <Link className={styles.sideBarLink} to={ROUTES.USERS}>
              <OneProductSideNavButton
                ButtonIcon={
                  sideMenuOpen ? (
                    <OneProductUsersAdminIcon />
                  ) : (
                    <ToolTip text="Users" direction="right" size="medium">
                      <OneProductUsersAdminIcon />
                    </ToolTip>
                  )
                }
                minimised={!sideMenuOpen}
                active={active}
                buttonName={ROUTES_NAMES.USERS}
                onClick={() => {
                  setActive(ROUTES_NAMES.USERS);
                }}
              />
            </Link>
          </SideNavColumn>
        </AuthWrapper>

        <AuthWrapper allRequired={rolesStore.getActionsByRoleName([ROLES.CONNECTION_ADMIN])}>
          {sideMenuOpen && <SideNavHeading HeadingText="Data connections" />}
          <SideNavColumn>
            <Link className={styles.sideBarLink} to={ROUTES.IMPORT}>
              <OneProductSideNavButton
                ButtonIcon={
                  sideMenuOpen ? (
                    <OneProductSourcesAdminIcon />
                  ) : (
                    <ToolTip text="Sources" direction="right" size="medium">
                      <OneProductSourcesAdminIcon />
                    </ToolTip>
                  )
                }
                minimised={!sideMenuOpen}
                active={active}
                buttonName={ROUTES_NAMES.IMPORT}
                onClick={() => {
                  setActive(ROUTES_NAMES.IMPORT);
                }}
              />
            </Link>
            <Link className={styles.sideBarLink} to={ROUTES.EXPORT}>
              <OneProductSideNavButton
                ButtonIcon={
                  sideMenuOpen ? (
                    <OneProductDestinationsAdminIcon />
                  ) : (
                    <ToolTip text="Destinations" direction="right" size="medium">
                      <OneProductDestinationsAdminIcon />
                    </ToolTip>
                  )
                }
                minimised={!sideMenuOpen}
                active={active}
                buttonName={ROUTES_NAMES.EXPORT}
                onClick={() => {
                  setActive(ROUTES_NAMES.EXPORT);
                }}
              />
            </Link>
          </SideNavColumn>
        </AuthWrapper>
        <div className="mt-32"></div>
        <div className={[`${styles.sideBarFooter}`, `${sideMenuOpen && styles.sideMenuOpen}`].join(" ")}>
          <SideNavColumn>
            <OneProductSideNavButton
              ButtonIcon={
                sideMenuOpen ? (
                  <OneProductHelpAdminIcon />
                ) : (
                  <ToolTip text="Help" direction="right">
                    <OneProductHelpAdminIcon />
                  </ToolTip>
                )
              }
              minimised={!sideMenuOpen}
              active={active}
              buttonName={ROUTES_NAMES.HELP}
              onClick={() => {
                setActive(ROUTES_NAMES.HELP);
                launchIntercom();
              }}
              disabled={false}
            />
            <HyperLink href={fusionDataUrl} target="_blank">
              <OneProductSideNavButton
                ButtonIcon={
                  sideMenuOpen ? (
                    <OneProductLaunchAdminIcon />
                  ) : (
                    <ToolTip text="Launch fusion" direction="right" size="medium">
                      <OneProductLaunchAdminIcon />
                    </ToolTip>
                  )
                }
                minimised={!sideMenuOpen}
                active={active}
                buttonName={ROUTES_NAMES.FUSION}
                onClick={() => {
                  setActive(ROUTES_NAMES.FUSION);
                }}
              />
            </HyperLink>
          </SideNavColumn>
        </div>
        {renderSupport()}
      </SideNavBar>
    </>
  );
});

export { Sidebar };
