import { fusionRequest } from "../fusionRequest";
import { API_ENDPOINTS } from "../config";

const ENDPOINT = API_ENDPOINTS.USERS;

const requests = {
  create: (user) => fusionRequest.post(`${ENDPOINT}/`, user),
  read: (id) => fusionRequest.get(`${ENDPOINT}/${id}`),
  update: (user) => fusionRequest.put(`${ENDPOINT}/${user.id}`, user),
  updateMe: (user) => fusionRequest.put(`${ENDPOINT}/updateMe/`, user),
  delete: (user) => fusionRequest.delete(`${ENDPOINT}/${user.id}`),
  list: () => fusionRequest.get(`${ENDPOINT}/`),
  me: () => fusionRequest.get(`${ENDPOINT}/me`),
  findWhereNameOrEmailIncludes: (toInclude) => fusionRequest.get(`${ENDPOINT}/findWhereNameOrEmailIncludes/${toInclude}`),
  resetRolesInATeam: ({ userId, teamId, newRoles }) => fusionRequest.put(`${ENDPOINT}/${userId}/teams/${teamId}/roles`, newRoles),
  report: () => fusionRequest.get(`${ENDPOINT}/report`, null, { responseType: "blob" }),
  unblock: (user) => fusionRequest.post(`${ENDPOINT}/${user.id}/unblock`, user),
  resetMFA: (user) => fusionRequest.post(`${ENDPOINT}/${user.id}/resetMFA`, user),
};

const api = {
  create: (user) => requests.create(user),
  read: (id) => requests.read(id),
  update: (user) => requests.update(user),
  updateMe: (user) => requests.updateMe(user),
  delete: (user) => requests.delete(user),
  list: () => requests.list(),
  me: () => requests.me(),
  findWhereNameOrEmailIncludes: (toInclude) => requests.findWhereNameOrEmailIncludes(toInclude),
  resetRolesInATeam: ({ userId, teamId, newRoles }) => requests.resetRolesInATeam({ userId, teamId, newRoles }),
  report: () => requests.report(),
  unblock: (user) => requests.unblock(user),
  resetMFA: (user) => requests.resetMFA(user),
};

const users = {
  requests,
  api,
};

export { users };
