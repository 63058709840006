/**
 * Checks if the currently logged in user has authority in the currently selected Team for a given Action.
 * @param {*} action
 * @returns
 */
const userHasAuthority = (loggedInUser, userActions, action) => {
  //Return false if no logged in user
  if (!loggedInUser) {
    return false;
  }
  //Return true if this is a super admin user
  if (loggedInUser.superAdmin) {
    return true;
  }

  //Check if this action object is valid for the logged in user
  for (let i = 0; i < userActions.length; i++) {
    if (userActions[i]["id"] === action["id"]) {
      return true;
    }
  }
  return false;
};

/**
 * Return true if logged in user has all authorities in the array of actions
 */
const checkAllRequired = (loggedInUser, userActions, array) => {
  for (let i = 0; i < array.length; i++) {
    if (!userHasAuthority(loggedInUser, userActions, array[i])) {
      return false;
    }
  }
  return array.length > 0;
};

/**
 * Return true if logged in user has at least one authority in the array of actions
 */
const checkOneRequired = (loggedInUser, userActions, array) => {
  for (let i = 0; i < array.length; i++) {
    if (userHasAuthority(loggedInUser, userActions, array[i])) {
      return true;
    }
  }
  return array.length > 0;
};

/**
 * Return true if logged in user has no authorities in the array of actions
 */
const checkProhibited = (loggedInUser, userActions, array) => {
  if (!array) {
    return true;
  }
  for (let i = 0; i < array.length; i++) {
    if (userHasAuthority(loggedInUser, userActions, array[i])) {
      return false;
    }
  }
  return true;
};

export { checkAllRequired, checkOneRequired, checkProhibited };
