import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useHistory, useParams } from "react-router-dom";
import { R, C, ListItems, List } from "@fundrecs/ui-library";
import { importSftpConnectionsStore } from "../../../../../store/Store";
import { ImportSetupContext } from "../ImportSetup";
import { ROUTES } from "../../../../../utils/Enums";
import { ListItemsHeader, ListItemsItem, TabsLayout } from "../../../components/Layout";
import { ConnectionStatus } from "../../../components/ConnectionStatus";
import { SftpConnectionHeader } from "./SftpConnectionHeader";
import { NoSftpConnections } from "./NoSftpConnections";
import { SftpConnection } from "./SftpConnection";
import { SftpMappings } from "./SftpMappings";

const SftpConnectionsList = observer(() => {
  let { id } = useParams();
  const history = useHistory();
  const { selectedSftpConnection, setSelectedSftpConnection, setSelectedTabIndex } = useContext(ImportSetupContext);
  const [sftpTab, setSftpTab] = useState(0);

  useEffect(() => {
    setSelectedTabIndex(0);

    const fetchData = async () => {
      return await importSftpConnectionsStore.requestSftpConnections();
    };

    fetchData().then((sftpConnections) => {
      importSftpConnectionsStore.setSftpConnections(sftpConnections);

      if (!selectedSftpConnection) {
        selectDefaultItem(id);
      }
    });
  }, []);

  useEffect(() => {
    selectDefaultItem(id);
  }, [id]);

  const selectDefaultItem = (id) => {
    const itemById = importSftpConnectionsStore.getSftpConnectionById(id);
    if (id && itemById) {
      setSelectedSftpConnection(itemById);
    } else {
      const newestItem = importSftpConnectionsStore.getNewestSftpConnection();
      if (newestItem) {
        history.replace(`${ROUTES.IMPORT_SFTP}/${newestItem.id}`);
      } else {
        setSelectedSftpConnection();
      }
    }
  };

  const handleSelectConnection = (connection) => {
    setSelectedSftpConnection(connection);
    history.push(`${ROUTES.IMPORT_SFTP}/${connection.id}`);
  };

  return (
    <>
      {selectedSftpConnection ? (
        <R>
          <List>
            <ListItemsHeader text="All connections (newest-oldest)" />
            <R>
              <ListItems>
                {importSftpConnectionsStore
                  .getSftpConnections()
                  .slice()
                  .sort((a, b) => a.createdOn.localeCompare(b.createdOn))
                  .reverse()
                  .map(function (row, index) {
                    return (
                      <ListItemsItem
                        name={row.name}
                        description={
                          <span className="d-flex">
                            <ConnectionStatus status={row.active} />
                          </span>
                        }
                        isActive={row.id.toString() === id ? 0 : 1}
                        onClick={() => handleSelectConnection(row)}
                        key={index}
                      />
                    );
                  })}
              </ListItems>
            </R>
          </List>
          <C props="section-border-left pr-0 pl-0">
            <R props="pl-32 pt-24">{<SftpConnectionHeader />}</R>
            <R props="pl-32 section-border-bottom">
              <C props="pl-0 pr-0">
                <TabsLayout
                  tabs={[
                    {
                      text: "Overview",
                      onClick: () => {
                        setSftpTab(0);
                      },
                    },
                    {
                      text: "Directories",
                      onClick: () => {
                        setSftpTab(1);
                      },
                    },
                  ]}
                  activeTab={sftpTab}
                />
              </C>
            </R>
            <div className="light-grey-bg section-border-left " style={{ height: "100%" }}>
              <R props={"pl-32 pr-32 pb-32"}>{sftpTab === 0 ? <SftpConnection setSftpTab={setSftpTab} /> : <SftpMappings setSftpTab={setSftpTab} />}</R>
            </div>
          </C>
        </R>
      ) : (
        <NoSftpConnections />
      )}
    </>
  );
});

export { SftpConnectionsList };
