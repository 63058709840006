import React, { useState, useContext } from "react";
import { observer } from "mobx-react-lite";
import {
  ListItem,
  IconAdd,
  EmptyUsers,
  SectionDivider,
  Text,
  R,
  C,
  C6,
  C5,
  Button,
  Badge,
  UserItem,
  modalInstance,
  VerticalMenuDropdown,
  VerticalMenuListItem,
  WarningConfirmationModal,
} from "@fundrecs/ui-library";
import { teamsStore, rolesStore, usersStore, meStore } from "../../../store/Store";
import EditRoles from "../components/EditRoles";
import { AddUserPanel } from "../manageTeams/AddUserPanel";
import { TeamsContext } from "../manageTeams/ManageTeams";
import { ManageLayoutSection, EmptyStateLayout } from "../components/Layout";
import { MODALS } from "../../../utils/Enums";

const TeamMembers = observer(() => {
  const { selectedTeam } = useContext(TeamsContext);
  const { name, users, avatar, id } = selectedTeam;

  const teamMembers = (
    <ManageLayoutSection
      title={
        <R props="pl-4 pr-4">
          <C props={"pl-0"}>
            <Text size="sm" variant="secondary" weight="bold">
              All members (A-Z)
            </Text>
          </C>
          <C props={"d-flex justify-content-end pr-0"}>
            <Button size="sm" onClick={() => modalInstance(MODALS.ADD_USER).show()}>
              <IconAdd className={`btn-sm-svg`} />
            </Button>
          </C>
        </R>
      }
      spacers={["pt-20", "pb-0"]}
    >
      <AddUserPanel teamAvatar={avatar} teamName={name} teamId={id} numberOfMembers={users.length} />
      <MembersDetails />
    </ManageLayoutSection>
  );

  const noMembers = (
    <>
      <EmptyStateLayout
        icon={<EmptyUsers className="emptyState-icon" />}
        title="No members added yet"
        description="You can assign multiple members to this team and also assign multiple roles to each member to manage who can edit team content in Fusion."
        buttonText="Add a member"
        onClick={() => modalInstance(MODALS.ADD_USER).show()}
      />
      <AddUserPanel teamAvatar={avatar} teamName={name} teamId={id} numberOfMembers={users.length} />
    </>
  );

  return users.length > 0 ? teamMembers : noMembers;
});

const MembersDetails = observer(() => {
  const { selectedTeam, setSelectedTeam } = useContext(TeamsContext);
  const [userToDelete, setUserToDelete] = useState();
  const [editingRolesUserId, setEditingRolesUserId] = useState();
  const { id: teamId, name: teamName } = selectedTeam;

  const getTeamAfterRemoveUser = async () => {
    const team = await teamsStore.requestTeam(teamId);
    teamsStore.replaceTeam(team);
    setSelectedTeam(team);
  };

  const removeUserFromTeam = async (user) => {
    const { name: userName } = user;
    const { success } = await teamsStore.removeUserFromTeam(teamName, userName, { teamId: teamId, userId: user.id });
    if (success) {
      getTeamAfterRemoveUser(teamId);

      // Refresh userStore to show updated teams list in user overview
      const users = await usersStore.requestUsers();
      usersStore.setUsers(users);
    }
  };

  const editUserRoles = async ({ userId, newRoles }) => {
    const { success } = await usersStore.editUserRoles({ userId: userId, teamId: teamId, newRoles: newRoles });
    if (success) {
      getTeamAfterEditUserRoles(userId);
    }
  };

  const getTeamAfterEditUserRoles = async (userId) => {
    const team = selectedTeam;
    const me = meStore.getMe();
    usersStore.requestUser(userId).then((updatedUser) => {
      usersStore.replaceUser(updatedUser);
      if (updatedUser.id === me.id) {
        meStore.setMe({ defaultTeam: me.defaultTeam, ...updatedUser });
      }
      teamsStore.replaceTeam(team);
      setSelectedTeam(team);
    });
  };

  const MemberRoles = ({ userRoles }) => {
    const roles = rolesStore.getRoles();
    return userRoles.map((teamRole, index) => <Badge key={index} text={roles.find((role) => role.id === teamRole.id).name.toLowerCase()} />);
  };

  const members = teamsStore.getTeamMembers(selectedTeam);

  return members
    .slice()
    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
    .map((user, index) => (
      <div key={index}>
        <R props={"pl-32 pr-32 pt-12 pb-12"}>
          <C6 props="pl-0 pr-0 mt-0 mb-0">
            <div className="d-flex pr-24 pl-0">
              <ListItem key={index}>
                <UserItem header={user.name} body={user.email} />
              </ListItem>
            </div>
          </C6>
          <C5 props="mt-12 mb-0 pl-0 pr-0">
            {editingRolesUserId && editingRolesUserId === user.id ? (
              <EditRoles
                userRoles={user.roles}
                setEditingRolesUserId={setEditingRolesUserId}
                onSave={(value) => {
                  editUserRoles({ userId: user.id, newRoles: value });
                }}
              />
            ) : (
              <MemberRoles userRoles={user.roles} />
            )}
          </C5>
          <C props="text-end mt-0 mb-0 pl-0 pr-0 pt-12">
            {editingRolesUserId !== user.id && (
              <VerticalMenuDropdown>
                <VerticalMenuListItem
                  text="Change assigned roles"
                  onClick={() => {
                    setEditingRolesUserId(user.id);
                  }}
                />
                <VerticalMenuListItem
                  text="Remove from team"
                  onClick={() => {
                    setUserToDelete(user);
                    modalInstance("deleteUserModal").show();
                  }}
                />
              </VerticalMenuDropdown>
            )}

            <WarningConfirmationModal
              modalId="deleteUserModal"
              heading={`Are you sure you want to remove this member from ${selectedTeam.name} team?`}
              body={
                userToDelete && (
                  <ListItem>
                    <UserItem header={userToDelete.name} body={userToDelete.email} />
                  </ListItem>
                )
              }
              text="They will no longer have access to this team's activity and this action cannot be undone."
              cancel="Cancel"
              confirm="Remove member"
              onConfirm={() => {
                removeUserFromTeam(userToDelete);
              }}
            />
          </C>
        </R>
        {index < members.length - 1 ? <SectionDivider props="pl-16 pr-16" /> : <span className="row mb-24" />}
      </div>
    ));
});

export { TeamMembers };
