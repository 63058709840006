const DATA_TYPES = Object.freeze({
  BOOLEAN: "boolean",
  STRING: "string",
  INTEGER: "integer",
});

/**
 * This is a map of all Authority Strings in Fusion. This should be a mirror of
 * the authorities contained in the Action table in the main Fusion db
 */
const AUTHORITIES = Object.freeze({
  TEAM_CREATE: "TEAM_CREATE",
  TEAM_VIEW: "TEAM_VIEW",
  TEAM_EDIT: "TEAM_EDIT",
  TEAM_DELETE: "TEAM_DELETE",
  USER_CREATE: "USER_CREATE",
  USER_VIEW: "USER_VIEW",
  USER_EDIT: "USER_EDIT",
  USER_DELETE: "USER_DELETE",
  USER_CHANGE_ROLES: "USER_CHANGE_ROLES",
  WORKFLOW_CREATE: "WORKFLOW_CREATE",
  WORKFLOW_VIEW: "WORKFLOW_VIEW",
  WORKFLOW_EDIT: "WORKFLOW_EDIT",
  WORKFLOW_DELETE: "WORKFLOW_DELETE",
  WORKFLOW_SUBMIT: "WORKFLOW_SUBMIT",
  WORKFLOW_REJECT: "WORKFLOW_REJECT",
  WORKFLOW_APPROVE: "WORKFLOW_APPROVE",
  TEMPLATE_CREATE: "TEMPLATE_CREATE",
  TEMPLATE_VIEW: "TEMPLATE_VIEW",
  TEMPLATE_EDIT: "TEMPLATE_EDIT",
  TEMPLATE_DELETE: "TEMPLATE_DELETE",
  TEMPLATE_SUBMIT: "TEMPLATE_SUBMIT",
  TEMPLATE_REJECT: "TEMPLATE_REJECT",
  TEMPLATE_APPROVE: "TEMPLATE_APPROVE",
  TEMPLATE_CHANGE_TYPE: "TEMPLATE_CHANGE_TYPE",
  TYPE_CREATE: "TYPE_CREATE",
  TYPE_VIEW: "TYPE_VIEW",
  TYPE_EDIT: "TYPE_EDIT",
  TYPE_DELETE: "TYPE_DELETE",
  TYPE_ASSIGN: "TYPE_ASSIGN",
  CONNECTION_CREATE: "CONNECTION_CREATE",
  CONNECTION_VIEW: "CONNECTION_VIEW",
  CONNECTION_EDIT: "CONNECTION_EDIT",
  CONNECTION_DELETE: "CONNECTION_DELETE",
  INPUT_MAPPING_CREATE: "INPUT_MAPPING_CREATE",
  INPUT_MAPPING_VIEW: "INPUT_MAPPING_VIEW",
  INPUT_MAPPING_EDIT: "INPUT_MAPPING_EDIT",
  INPUT_MAPPING_DELETE: "INPUT_MAPPING_DELETE",
  OUTPUT_MAPPING_CREATE: "OUTPUT_MAPPING_CREATE",
  OUTPUT_MAPPING_VIEW: "OUTPUT_MAPPING_VIEW",
  OUTPUT_MAPPING_EDIT: "OUTPUT_MAPPING_EDIT",
  OUTPUT_MAPPING_DELETE: "OUTPUT_MAPPING_DELETE",
  DASHBOARD_VIEW: "DASHBOARD_VIEW",
  USER_EXPORT: "USER_EXPORT",
  USER_GRANT_ADMIN: "USER_GRANT_ADMIN",
  USER_RETRACT_ADMIN: "USER_RETRACT_ADMIN",
  USER_DELETE_ADMIN: "USER_DELETE_ADMIN",
  LOOKUP_CREATE: "LOOKUP_CREATE",
  LOOKUP_VIEW: "LOOKUP_VIEW",
  LOOKUP_DELETE: "LOOKUP_DELETE",
  LOOKUP_APPROVE: "LOOKUP_APPROVE",
  LOOKUP_REJECT: "LOOKUP_REJECT",
  LOOKUP_EDIT: "LOOKUP_EDIT",
  USER_UNBLOCK: "USER_UNBLOCK",
  USER_RESET_MFA: "USER_RESET_MFA",
});

const TEAM_SETTINGS_TYPES = Object.freeze({
  WORKFLOW_4_EYES: "4-eyes approval for workflows",
  TEMPLATE_4_EYES: "4-eyes approval for templates",
});

const ROLES = Object.freeze({
  VIEWER: "Viewer",
  TEMPLATE_DOER: "Template Doer",
  TEMPLATE_APPROVER: "Template Approver",
  WORKFLOW_DOER: "Workflow Doer",
  WORKFLOW_APPROVER: "Workflow Approver",
  SUPER_ADMIN: "Super Admin",
  CONNECTION_ADMIN: "Connection Admin",
  TEAM_USER_ADMIN: "Team & User Admin",
  REPORTING_ADMIN: "Reporting Admin",
  LOOKUP_DOER: "Lookup Doer",
  LOOKUP_APPROVER: "Lookup Approver",
});

const ROLE_DESCRIPTIONS = Object.freeze({
  Viewer: "Has read only permissions and can view all content within Fusion",
  "Template Doer": "Same permissions as Viewer in addition to adding, editing, deleting and submitting templates",
  "Template Approver": "Same permissions as Viewer in addition to adding, editing, deleting, submitting and approving/ rejecting templates",
  "Workflow Doer": "Same permissions as Viewer in addition to adding, editing, deleting and submitting workflows",
  "Workflow Approver": "Same permissions as Viewer in addition to adding, editing, deleting, submitting and approving/ rejecting workflows",
  "Super Admin": "This person has full authority for all functionality within Fusion. Therefore, no assignment of teams or additional roles are necessary.",
  "Connection Admin":
    "This person has full authority over file import setup and file export setup areas in the admin console. Connection admins can create, view, edit and delete import and export connections.",
  "Team & User Admin":
    "This person has full authority over manage teams and manage users areas in the admin console. Team & user admins can create, view, edit and delete teams and users, and assign roles to members.",
  "Lookup Doer": "Same permissions as Viewer in addition to adding, editing, deleting and submitting lookups",
  "Lookup Approver": "Same permissions as Viewer in addition to submitting and approving/ rejecting lookups",
  "Reporting Admin": "This person has access to create dashboard and chart visualizations in the reporting portal.",
});

const CONNECTIONS = Object.freeze({
  IMPORT_SFTP_CONNECTION: "import sftp connection",
  IMPORT_SFTP_MAPPING: "import sftp mapping",
  IMPORT_EMAIL_MAPPING: "import email mapping",
  EXPORT_SFTP_CONNECTION: "export sftp connection",
  EXPORT_SFTP_DIRECTORY: "export sftp directory",
  EXPORT_EMAIL_CONNECTION: "export email connection",
});

const INPUT_MAPPINGS_TYPES = Object.freeze({
  SFTP_FOLDER: { name: "SFTP_FOLDER", description: "SFTP folder" },
  EMAIL: { name: "EMAIL", description: "Email address" },
});

const ROUTES = Object.freeze({
  DASHBOARD: "/dashboard",
  USERS: "/users",
  USERS_NEW: "/users/new",
  TEAMS: "/teams",
  TEAMS_NEW: "/teams/new",

  IMPORT: "/import",
  IMPORT_SFTP: "/import/sftp",
  IMPORT_SFTP_NEW: "/import/sftp/new",
  IMPORT_SFTP_MAPPINGS: "/import/sftpmapping",
  IMPORT_SFTP_MAPPINGS_NEW: "/import/sftpmapping/new",
  IMPORT_EMAIL_MAPPINGS: "/import/email",
  IMPORT_EMAIL_MAPPINGS_NEW: "/import/email/new",

  EXPORT: "/export",
  EXPORT_SFTP: "/export/sftp",
  EXPORT_SFTP_NEW: "/export/sftp/new",
  EXPORT_SFTP_DIRECTORY: "/export/sftpdirectory",
  EXPORT_SFTP_DIRECTORY_NEW: "/export/sftpdirectory/new",
  EXPORT_EMAIL: "/export/email",
  EXPORT_EMAIL_NEW: "/export/email/new",

  FUSION: "/fusion",
});

const ROUTES_NAMES = Object.freeze({
  DASHBOARD: "Dashboard",
  REPORTING: "Reporting",
  USERS: "Users",
  TEAMS: "Teams",
  IMPORT: "Sources",
  EXPORT: "Destinations",
  HELP: "Help",
  FUSION: "Launch fusion",
  SUPPORT: "Support",
});

const MODALS = Object.freeze({
  NEW_SFTP_CONNECTION: "newSftpConnectionModal",
  NEW_INPUT_MAPPING: "newInputMappingModal",
  NEW_EMAIL: "newEmailModal",
  DELETE_SFTP_CONNECTION: "deleteSftpConnectionModal",
  PREVENT_DELETE_SFTP_CONNECTION: "preventDeleteSftpConnectionModal",
  DELETE_INPUT_MAPPING: "deleteInputMappingModal",
  DELETE_EMAIL: "deleteEmailModal",

  ADD_USER: "addUserPanel",
});

export { DATA_TYPES, AUTHORITIES, TEAM_SETTINGS_TYPES, ROLES, ROLE_DESCRIPTIONS, CONNECTIONS, INPUT_MAPPINGS_TYPES, ROUTES, ROUTES_NAMES, MODALS };
