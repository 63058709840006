import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { WarningConfirmationModal } from "@fundrecs/ui-library";
import { MODALS, CONNECTIONS, ROUTES } from "../../../../utils/Enums";
import { importEmailConnectionsStore, exportEmailConnectionsStore } from "../../../../store/Store";

const DeleteEmailModal = ({ connectionType, item }) => {
  const history = useHistory();
  const { name, id, connectionUuid } = item || {};

  const deleteEmail = async () => {
    const deleteEmail =
      connectionType === CONNECTIONS.IMPORT_EMAIL_MAPPING ? await importEmailConnectionsStore.delete(item) : await exportEmailConnectionsStore.delete(item);

    const { success } = deleteEmail;

    if (success) {
      // Update store and change url to select newest item
      if (connectionType === CONNECTIONS.IMPORT_EMAIL_MAPPING) {
        importEmailConnectionsStore.removeEmailConnection(connectionUuid);
        history.replace(`${ROUTES.IMPORT_EMAIL_MAPPINGS}`);
      } else {
        exportEmailConnectionsStore.removeEmailConnection(connectionUuid);
        history.replace(`${ROUTES.EXPORT_EMAIL}`);
      }
    }
  };

  return (
    <WarningConfirmationModal
      modalId={MODALS.DELETE_EMAIL}
      heading={`Are you sure you want to delete email address ${name} ?`}
      body={""}
      text="This may affect workflows if used as a destination for output files and this action cannot be undone."
      cancel="Cancel"
      confirm="Delete email address"
      onConfirm={() => deleteEmail()}
    />
  );
};

// Specifies the types for props:
DeleteEmailModal.propTypes = {
  connectionType: PropTypes.string,
  item: PropTypes.object,
};

// Specifies the default values for props:
DeleteEmailModal.defaultProps = {
  connectionType: "",
  item: {},
};

export { DeleteEmailModal };
