import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { C, C6, C4, Input, LinkText, Text, SaveEditable } from "@fundrecs/ui-library";
import { isNullOrEmpty } from "../../util/validation";

const EditableText = ({ name, label, value, onSave, onChange, warningText, requiredField, customValidation }) => {
  const [editing, setEditing] = useState(false);
  const [fieldValue, setFieldValue] = useState(value);
  const [warningBoolean, setWarningBoolean] = useState(false);
  const [warningMessage, setWarningMessage] = useState(warningText);

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  /**
   * By default all Editable Text fields are required and cannot be null or empty
   * Custom validation is optional and will only be used if value pass isNullOrEmpty validation first
   *
   * This validation is async as some custom validations can depend on waiting API response values
   */
  const validateField = async (value) => {
    let showWarning = false;

    // If value is null or empty, show warning message
    if (requiredField) {
      showWarning = isNullOrEmpty(value.trimStart());
      if (showWarning) {
        setWarningMessage(warningText);
      }
      setWarningBoolean(showWarning);
    }

    // Only run custom validation if value is not null or empty
    if (customValidation && !showWarning) {
      const validation = await customValidation(value);
      const { showWarning } = validation;

      if (showWarning) {
        const { message } = validation;
        setWarningMessage(message);
        setWarningBoolean(true);
      } else {
        setWarningBoolean(false);
      }
    }
  };

  return (
    <>
      <C4>
        <Text variant="tertiary" size="sm" weight="regular" element="div">
          {label}
        </Text>
      </C4>

      {editing ? (
        <>
          <C6>
            <Input
              name={name}
              value={fieldValue}
              onChange={(event) => {
                validateField(event.target.value.trimStart());

                setFieldValue(event.target.value.trimStart());

                if (onChange) {
                  onChange(event.target.value.trimStart());
                }
              }}
              warning={warningBoolean}
              warningMessage={warningMessage}
            />
          </C6>
          <C props="pt-4 pb-4">
            <SaveEditable
              onClickSave={() => {
                if (!warningBoolean) {
                  onSave(fieldValue);
                  setEditing(false);
                }
              }}
              onClickCancel={() => {
                setFieldValue(value);
                setEditing(false);
              }}
            />
          </C>
        </>
      ) : (
        <>
          <C6>
            <Text variant="primary" size="sm" weight="medium" element="div">
              {value}
            </Text>
          </C6>
          <C>
            <span
              className="d-flex justify-content-end"
              onClick={() => {
                setEditing(true);
                setFieldValue(value);
                setWarningBoolean(false);
              }}
            >
              <LinkText>change</LinkText>
            </span>
          </C>
        </>
      )}
    </>
  );
};

// Specifies the types for props:
EditableText.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onSave: PropTypes.func,
  onChange: PropTypes.func,
  warningText: PropTypes.string,
  requiredField: PropTypes.bool,
  customValidation: PropTypes.func,
};

// Specifies the default values for props:
EditableText.defaultProps = {
  name: "",
  label: "",
  value: "",
  onSave: () => {},
  onChange: () => {},
  warningText: "",
  requiredField: true,
  customValidation: null,
};

export { EditableText };
