import { EmptyEmail, modalInstance } from "@fundrecs/ui-library";
import { EmptyStateLayout } from "../../../components/Layout";
import { MODALS } from "../../../../../utils/Enums";

/**
 * Default page when no email mappings have yet been added
 */
const NoEmailMappings = () => {
  return (
    <EmptyStateLayout
      icon={<EmptyEmail className="emptyState-icon" />}
      title="No email addresses added yet"
      description="You can add and use email addresses as destinations for your teams workflow custom output files in Fusion"
      buttonText="Add an email address"
      onClick={() => {
        modalInstance(MODALS.NEW_EMAIL).toggle();
      }}
    />
  );
};

NoEmailMappings.propTypes = {};
NoEmailMappings.defaultProps = {};

export { NoEmailMappings };
